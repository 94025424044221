import React from "react";

const Worksheets: React.FC = ({}) => {
  return (
    <div className="user-details__worksheets">
      <div className="user-details__lessons--title--row">
        <div className="user-details__worksheets--title">Worksheets (495)</div>

        <a href="https://nofusstutors.com/reading-worksheets" target="_blank">
          <div className="user-details__lessons--title">
            <i className="fa fa-external-link" aria-hidden="true"></i>
          </div>
        </a>
      </div>

      <div className="user-details__worksheets--worksheet-container">
        <a href="https://nofusstutors.com/games-worksheets" target="_blank">
          <div className="user-details__worksheets--worksheet--card">
            <div className="user-details__worksheets--worksheet--title">
              Snakes and Ladders (Blends)
            </div>
            <div className="user-details__worksheets--worksheet--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/SL-Blends.png"
                alt="Book Icon"
                className="user-details__worksheets--worksheet--img"
              />
            </div>
          </div>
        </a>
        <a href="https://nofusstutors.com/games-worksheets" target="_blank">
          <div className="user-details__worksheets--worksheet--card">
            <div className="user-details__worksheets--worksheet--title">
              Roll and Read (cVc vs cVce)
            </div>
            <div className="user-details__worksheets--worksheet--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/RandR-cVce.png"
                alt="Book Icon"
                className="user-details__worksheets--worksheet--img"
              />
            </div>
          </div>
        </a>
        <a
          href="https://nofusstutors.com/creative-writing-worksheets"
          target="_blank"
        >
          <div className="user-details__worksheets--worksheet--card">
            <div className="user-details__worksheets--worksheet--title">
              Continue the Story
            </div>
            <div className="user-details__worksheets--worksheet--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/continue-dog.png"
                alt="Book Icon"
                className="user-details__worksheets--worksheet--img"
              />
            </div>
          </div>
        </a>

        <a
          href="https://nofusstutors.com/multiplication-worksheets"
          target="_blank"
        >
          <div className="user-details__worksheets--worksheet--card">
            <div className="user-details__worksheets--worksheet--title">
              Multiplication Chart
            </div>
            <div className="user-details__worksheets--worksheet--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/multiplication-chart.png"
                alt="Book Icon"
                className="user-details__worksheets--worksheet--img"
              />
            </div>
          </div>
        </a>
        <a
          href="https://nofusstutors.com/multiplication-worksheets"
          target="_blank"
        >
          <div className="user-details__worksheets--worksheet--card">
            <div className="user-details__worksheets--worksheet--title">
              Multiplication Game
            </div>
            <div className="user-details__worksheets--worksheet--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/multiply-game.png"
                alt="Book Icon"
                className="user-details__worksheets--worksheet--img"
              />
            </div>
          </div>
        </a>

        <a href="https://nofusstutors.com/games-worksheets" target="_blank">
          <div className="user-details__worksheets--worksheet--card">
            <div className="user-details__worksheets--worksheet--title">
              Ending Blends
            </div>
            <div className="user-details__worksheets--worksheet--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/blend-end.png"
                alt="Book Icon"
                className="user-details__worksheets--worksheet--img"
              />
            </div>
          </div>
        </a>
        <a href="https://nofusstutors.com/reading-worksheets" target="_blank">
          <div className="user-details__worksheets--worksheet--card">
            <div className="user-details__worksheets--worksheet--title">
              See More
            </div>
            <div className="user-details__worksheets--worksheet--see-more">
              <i className="fa fa-external-link" aria-hidden="true"></i>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Worksheets;
