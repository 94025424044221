import React, {
  ForwardedRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useStories } from "../../Hooks/useStories";
import { useUserData } from "../../Hooks/useUserData";
import { TrackedWords } from "../../Models";
import {
  WordPhonics,
  Words,
  Question,
  Phonics,
  Story,
} from "../../Models/Stories";
import { ModalProps } from "../../Pages/StoryComponent";
import { generateID } from "../../Utils/GenerateID";
import { contractions } from "../../Utils/WordHelpers";
// import { flipPageBack, flipPageForward } from "../../Utils/PageFlip";

export type StoryPageTextProps = {
  title?: boolean;
  image: string;
  pageNumber: number;
  currentPage?: number;
  lines: string[];
  fontSize?: string;
  fontColor?: string;
  lineHeight?: string;
  top?: string;
  right?: string;
  marginBottom?: string;
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  word_margin?: string;
  word_tb_padding?: string;
  word_rl_padding?: string;
  word_container_width?: string;
  onClick: (
    text: string,
    definitions: string[],
    phonics: WordPhonics[],
    pageNumber: number,
    images?: string[],
    audio?: string
  ) => void;
  resetHighlight?: boolean;
  modalProperties?: ModalProps;
  trackedWords?: TrackedWords[];
  clickPhonic?: (
    word: string,
    definitions: string[],
    phonics: WordPhonics[],
    phonicNames: string,
    position: number
  ) => void;
  removeWord?: (word: string) => void;
  // setShowHeader?: (value: React.SetStateAction<boolean>) => void;
  isMobile?: boolean;
  setPage?: (value: React.SetStateAction<number>) => void;
  page?: number;
  storyDetails?: Story;
  setShowQuestions?: (value: React.SetStateAction<boolean>) => void;
  showQuestions?: boolean;
  mobileFriendly?: boolean;
  wordContainerRef?: React.RefObject<HTMLDivElement>;
  overrideFontSize?: number;
  scrollDistance?: number;
  overrideWordHighlight?: {
    overrideWordToHighlight: string;
    overrideWordToHighlightLineIndex: number;
    overrideWordToHighlightIndex: number;
  };
  setOverrideWordHighlight?: (
    value: React.SetStateAction<{
      overrideWordToHighlight: string;
      overrideWordToHighlightLineIndex: number;
      overrideWordToHighlightIndex: number;
    }>
  ) => void;
  lineDetails?: { line: string; lineIndex: number };
  setVoiceWordProperties?: (
    value: React.SetStateAction<{ wordIndex: number; pageNumber: number }>
  ) => void;
  assignment?: string | undefined;
};

const StoryPageText: React.FC<StoryPageTextProps> = ({
  title,
  image,
  pageNumber,
  currentPage,
  lines,
  onClick,
  fontSize,
  fontColor,
  lineHeight,
  top,
  right,
  marginBottom,
  textAlign,
  word_margin,
  word_tb_padding,
  word_rl_padding,
  word_container_width,
  resetHighlight,
  modalProperties,
  trackedWords,
  clickPhonic,
  removeWord,
  // setShowHeader,
  isMobile,
  setPage,
  page,
  storyDetails,
  setShowQuestions,
  showQuestions,
  mobileFriendly,
  wordContainerRef,
  overrideFontSize,
  scrollDistance,
  overrideWordHighlight,
  lineDetails,
  setVoiceWordProperties,
  setOverrideWordHighlight,
  assignment,
}) => {
  const { storyData } = useStories();
  const { userData } = useUserData();

  const [wordToHighlight, setWordToHighlight] = useState("");
  const [wordToHighlightIndex, setWordToHighlightIndex] = useState(-1);
  const [wordToHighlightLineIndex, setWordToHighlightLineIndex] = useState(-1);

  const [extensionsTried, setExtensionsTried] = useState({
    jpg: false,
    png: false,
    jpeg: false,
  });
  const [correctExtension, setCorrectExtension] = useState("");
  const [clickLocation, setClickLocation] = useState(0);

  const [trackingTop, setTrackingTop] = useState(0);
  const [slideAnimation, setSlideAnimation] = useState("hide");
  const [showTracking, setShowTracking] = useState(false);

  useLayoutEffect(() => {
    if (resetHighlight || currentPage !== pageNumber) {
      setWordToHighlight("");
      setWordToHighlightIndex(-1);
      setWordToHighlightLineIndex(-1);
    }
  }, [resetHighlight, currentPage, pageNumber]);

  useLayoutEffect(() => {
    if (pageNumber === currentPage) {
      if (
        overrideWordHighlight &&
        overrideWordHighlight.overrideWordToHighlight !== ""
      ) {
        setWordToHighlight(overrideWordHighlight.overrideWordToHighlight);
        setWordToHighlightIndex(
          overrideWordHighlight.overrideWordToHighlightIndex
        );
        setWordToHighlightLineIndex(
          overrideWordHighlight.overrideWordToHighlightLineIndex
        );
      }
    }
  }, [overrideWordHighlight, currentPage]);

  useLayoutEffect(() => {
    if (document.getElementsByClassName("story__clicked-word")[0]) {
      document
        .getElementsByClassName("story__clicked-word")[0]
        .scrollIntoView({ block: "center" });
    }
  }, [wordToHighlight]);

  useLayoutEffect(() => {
    if (currentPage === pageNumber) {
      if (document.getElementsByClassName("story__line--highlight")[0]) {
        document
          .getElementsByClassName("story__line--highlight")[0]
          .scrollIntoView({ block: "center" });
      }
    }
  }, [lineDetails, currentPage]);

  const MAPPED_WORDS = useMemo(
    () =>
      lines.map((line, lineIndex) => {
        return (
          <React.Fragment key={generateID()}>
            <div
              style={{
                marginBottom: `${
                  marginBottom
                    ? marginBottom.toString().includes("px")
                      ? marginBottom
                      : `${marginBottom}px`
                    : undefined
                }`,
              }}
              className={
                line.trim() === ""
                  ? "story__line--empty"
                  : lineDetails?.line !== "" &&
                    lineDetails?.lineIndex === lineIndex &&
                    (page === pageNumber || currentPage === pageNumber)
                  ? "story__line story__line--highlight"
                  : "story__line"
              }
            >
              {line.split(" ").map((word, wordIndex) => {
                return (
                  <React.Fragment key={generateID()}>
                    <span
                      style={{
                        fontSize: `${overrideFontSize}rem`,
                        color: isMobile ? "black" : `${fontColor}`,
                        margin: `${word_margin}`,
                        padding: `${word_tb_padding} ${word_rl_padding}`,
                      }}
                      className={
                        wordToHighlight === word &&
                        wordToHighlightIndex === wordIndex &&
                        wordToHighlightLineIndex === lineIndex &&
                        (page === pageNumber || currentPage === pageNumber) &&
                        !resetHighlight
                          ? "story__clicked-word story__clickable-word"
                          : "story__clickable-word"
                      }
                      onClick={(e) => {
                        e.stopPropagation();

                        // setShowHeader && setShowHeader(false);
                        setPage && setPage(pageNumber);
                        setShowTracking(false);
                        if (
                          wordToHighlight === word &&
                          wordToHighlightIndex === wordIndex &&
                          wordToHighlightLineIndex === lineIndex
                        ) {
                          setWordToHighlight("");
                          setWordToHighlightIndex(-1);
                          setWordToHighlightLineIndex(-1);
                          onClick("", [""], [], 0);
                        } else {
                          if (setOverrideWordHighlight) {
                            setOverrideWordHighlight({
                              overrideWordToHighlight: "",
                              overrideWordToHighlightIndex: 0,
                              overrideWordToHighlightLineIndex: 0,
                            });
                          }
                          setWordToHighlight(word);
                          setWordToHighlightIndex(wordIndex);
                          setWordToHighlightLineIndex(lineIndex);

                          const parentPosition =
                            e.currentTarget.parentElement!.parentElement!.getBoundingClientRect()
                              .y;

                          const yPosition =
                            e.currentTarget.getBoundingClientRect().y -
                            parentPosition -
                            e.currentTarget.parentElement!.parentElement!
                              .clientHeight /
                              2;

                          setClickLocation(
                            yPosition +
                              (top
                                ? Number(
                                    top
                                      .toString()
                                      .split("px")
                                      .join("")
                                      .split("rem")
                                      .join("")
                                  )
                                : 17) +
                              (fontSize
                                ? Number(
                                    fontSize
                                      .toString()
                                      .split("rem")
                                      .join("")
                                      .split("px")
                                      .join("")
                                  ) * 20
                                : 60)
                          );
                          setTrackingTop &&
                            setTrackingTop(
                              183 +
                                yPosition +
                                (top
                                  ? Number(
                                      top
                                        .toString()
                                        .split("px")
                                        .join("")
                                        .split("rem")
                                        .join("")
                                    )
                                  : 17) +
                                (fontSize
                                  ? Number(
                                      fontSize
                                        .toString()
                                        .split("rem")
                                        .join("")
                                        .split("px")
                                        .join("")
                                    ) * 20
                                  : 60)
                            );

                          if (setVoiceWordProperties) {
                            setVoiceWordProperties({
                              wordIndex:
                                lineIndex === 0
                                  ? wordIndex
                                  : lines
                                      .map((mappedLine, mappedLineIndex) =>
                                        mappedLineIndex < lineIndex
                                          ? mappedLine.split(" ").length
                                          : 0
                                      )
                                      .reduce((prev, curr) => prev + curr) +
                                    wordIndex,
                              pageNumber,
                            });
                          }

                          let tempWord = word
                            .replace(
                              /\[|\]|\"|\”|”|\“|“|\:|\;|\(|\)|\.|\,|\?|\!/gi,
                              ""
                            )
                            .replace(/\’|’/gi, "'");
                          if (!contractions.includes(tempWord.toLowerCase())) {
                            tempWord = tempWord.replaceAll(/\'s|'s|\'|'/g, "");
                          }
                          const wordContent = storyData.words.find((item) => {
                            return (
                              item.text.localeCompare(tempWord, "en", {
                                sensitivity: "base",
                              }) === 0
                            );
                          });
                          if (wordContent) {
                            onClick(
                              wordContent.text,
                              wordContent.definitions,
                              wordContent.phonics,
                              pageNumber,
                              wordContent.images,
                              wordContent.audio
                            );
                          } else {
                            onClick(tempWord, [""], [], pageNumber);
                          }
                        }
                      }}
                    >
                      {word}
                    </span>
                    <span> </span>
                  </React.Fragment>
                );
              })}
            </div>
          </React.Fragment>
        );
      }),
    [
      wordToHighlight,
      wordToHighlightIndex,
      wordToHighlightLineIndex,
      lines,
      overrideFontSize,
      storyData,
      lineDetails,
    ]
  );

  return (
    <div
      className="story__page-container"
      style={assignment ? { marginTop: "200px" } : undefined}
    >
      {/* {!title && !isMobile && page !== undefined && setPage !== undefined && (
        <div
          className="story__prev-page"
          onClick={(e) => {
            e.stopPropagation();

            if (page === storyDetails?.pages.length && showQuestions) {
              setShowQuestions && setShowQuestions(false);
            } else {
              flipPageBack(page, setPage);
            }
            onClick("", [""], [], 0);
          }}
        ></div>
      )} */}
      {title ? (
        <div className="story__cover">
          <img
            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${image}`}
            alt="Story Cover"
            onError={(e: any) => {
              if (!extensionsTried.jpg || correctExtension === "jpg") {
                e.target.src = `${
                  process.env.REACT_APP_AWS_BUCKET_URL
                }/${image.replace("webp", "jpg")}`;
                setExtensionsTried((prev: any) => ({ ...prev, jpg: true }));
                setCorrectExtension("jpg");
              } else if (!extensionsTried.png || correctExtension === "png") {
                e.target.src = `${process.env.REACT_APP_AWS_BUCKET_URL}/${image
                  .replace("webp", "png")
                  .replace("jpg", "png")}`;
                setExtensionsTried((prev: any) => ({ ...prev, png: true }));
                setCorrectExtension("png");
              } else if (!extensionsTried.jpeg || correctExtension === "jpeg") {
                e.target.src = `${
                  process.env.REACT_APP_AWS_BUCKET_URL
                }/${image.replace("webp", "jpeg")}`;
                setExtensionsTried((prev: any) => ({ ...prev, jpeg: true }));
                setCorrectExtension("jpeg");
              }
            }}
          />
          {(!scrollDistance || scrollDistance < 100) && (
            <div className="story__cover--scroll-down--arrow-container">
              <div className="story__cover--scroll-down--text">Scroll down</div>
              <p className="story__cover--scroll-down">
                <a className="animate">More</a>
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="story__page">
          <div
            ref={wordContainerRef}
            style={{
              lineHeight,
              textAlign,
              width: `${word_container_width}`,
            }}
            className="story__word-container"
          >
            <span>{MAPPED_WORDS}</span>
          </div>
          {isMobile &&
            modalProperties &&
            modalProperties.show &&
            modalProperties.pageNumber === pageNumber &&
            !overrideWordHighlight?.overrideWordToHighlight && (
              <>
                <div
                  className="story__modal--modalize"
                  style={{ top: `${clickLocation}px` }}
                >
                  <span
                    className="story__modal--close"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowTracking(false);

                      onClick("", [""], [], 0);
                    }}
                  >
                    <i className="fas fa-times story__modal--close-icon"></i>
                  </span>

                  {modalProperties.phonics.length === 0 && (
                    <div>
                      <span className="story__modal--word">
                        Word not available yet
                      </span>
                    </div>
                  )}

                  {modalProperties.phonics.length !== 0 && (
                    <div className="story__modal--word-sound-wrapper">
                      <div className="story__modal--phonics">
                        {modalProperties.phonics.map((phonic, phonicIndex) => {
                          let currentPhonics: Phonics[] = [];
                          for (const phoneme of phonic.phonemes) {
                            const foundPhonic = storyData.phonics.find(
                              (fullPhonic) => fullPhonic.phoneme === phoneme
                            );
                            if (foundPhonic) {
                              currentPhonics.push(foundPhonic);
                            }
                          }
                          const indexOfWord = trackedWords!.findIndex(
                            (val) => val.word === modalProperties.word
                          );

                          return (
                            <React.Fragment key={generateID()}>
                              <div className="story__modal--phonic-part-container">
                                <span
                                  className={`${
                                    trackedWords![
                                      indexOfWord
                                    ].positions.includes(phonic.position)
                                      ? phonic.position === 1
                                        ? "story__phonic-part--first "
                                        : phonic.position ===
                                          modalProperties.phonics.length
                                        ? "story__phonic-part--last "
                                        : phonic.position === 2
                                        ? "story__phonic-part--second "
                                        : phonic.position ===
                                          modalProperties.phonics.length - 1
                                        ? "story__phonic-part--penultimate "
                                        : "story__phonic-part--middle "
                                      : ""
                                  }${
                                    modalProperties.phonics.length > 5
                                      ? "story__modal--phonic-part-small"
                                      : "story__modal--phonic-part"
                                  }`}
                                >
                                  {`${phonic.part}`}
                                </span>
                                <span
                                  className={`${
                                    trackedWords![
                                      indexOfWord
                                    ].positions.includes(phonic.position)
                                      ? phonic.position === 1
                                        ? "story__phonic-click--first "
                                        : phonic.position ===
                                          modalProperties.phonics.length
                                        ? "story__phonic-click--last "
                                        : phonic.position === 2
                                        ? "story__phonic-click--second "
                                        : phonic.position ===
                                          modalProperties.phonics.length - 1
                                        ? "story__phonic-click--penultimate "
                                        : "story__phonic-click--middle "
                                      : ""
                                  }${
                                    modalProperties.phonics.length > 5
                                      ? "story__modal--phonic-click-small"
                                      : "story__modal--phonic-click"
                                  }`}
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    clickPhonic &&
                                      clickPhonic(
                                        modalProperties.word,
                                        modalProperties.definitions,
                                        modalProperties.phonics,
                                        phonic.phonemes.length > 1 &&
                                          phonic.multiple
                                          ? currentPhonics
                                              .map(
                                                (fullPhonic) => fullPhonic.name
                                              )
                                              .join(" AND ")
                                          : !phonic.multiple
                                          ? currentPhonics
                                              .map(
                                                (fullPhonic) => fullPhonic.name
                                              )
                                              .join(" OR ")
                                          : currentPhonics.map(
                                              (fullPhonic) => fullPhonic.name
                                            )[0],
                                        phonic.position
                                      );
                                  }}
                                ></span>

                                {currentPhonics.map(
                                  (fullPhonic, fullPhonicIndex) => {
                                    return (
                                      <React.Fragment key={generateID()}>
                                        <div
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            // if (width > 1024) {
                                            let audioToPlay: any =
                                              document.getElementById(
                                                `phonic-audio-${fullPhonic.audio}`
                                              );
                                            audioToPlay.play();
                                            // }
                                          }}
                                        >
                                          <audio
                                            id={`phonic-audio-${fullPhonic.audio}`}
                                          >
                                            <source
                                              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${fullPhonic.audio}`}
                                            ></source>
                                          </audio>
                                          <i className="fas fa-volume-up story__modal--phonic-sound-icon"></i>
                                        </div>
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                      {modalProperties.show && (
                        <React.Fragment key={generateID()}>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();

                              let audioToPlay: any = document.getElementById(
                                `word-audio-${modalProperties.word}`
                              );
                              audioToPlay.play();
                            }}
                          >
                            <audio id={`word-audio-${modalProperties.word}`}>
                              <source
                                src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${modalProperties.audio}`}
                              ></source>
                            </audio>
                            <i className="fas fa-volume-up story__modal--sound-icon"></i>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  )}
                  <div className="story__tracking-section--chev-remove">
                    <div
                      className="story__tracking-section--chevron"
                      onClick={(e) => {
                        e.stopPropagation();

                        if (showTracking) {
                          setSlideAnimation && setSlideAnimation("hide");
                          setTimeout(() => {
                            setShowTracking && setShowTracking(!showTracking);
                          }, 1000);
                        } else {
                          setSlideAnimation && setSlideAnimation("show");

                          setShowTracking && setShowTracking(!showTracking);
                        }
                      }}
                    >
                      <div className="story__tracking-section--text-added">
                        {`${trackedWords!.length} word${
                          trackedWords!.length > 1 || trackedWords!.length === 0
                            ? "s"
                            : ""
                        }`}{" "}
                        tracked
                        <i className="fas fa-bolt story__modal--bolt-icon"></i>
                      </div>
                    </div>
                    {/* <div className="story__tracking-section--remove-word-mobile">
                      {modalProperties.show && (
                        <button
                          className="story__tracking-section--remove-word-button"
                          onClick={(e) => {
                            e.stopPropagation();

                            onClick("", [""], [], 0);
                            removeWord && removeWord(modalProperties.word);
                          }}
                        >
                          <div className="story__tracking-section--text-remove">
                            Remove
                          </div>
                        </button>
                      )}
                    </div> */}
                  </div>
                </div>
                {showTracking && (
                  <>
                    <div
                      style={{ top: `${trackingTop}px` }}
                      className={`story__tracking-container--modalize--${slideAnimation}${
                        userData._id !== "" ? "" : " blur"
                      }`}
                    >
                      {trackedWords !== undefined &&
                        trackedWords.map((wordProperties) => {
                          return (
                            <React.Fragment key={generateID()}>
                              <div className="story__tracking-section">
                                {wordProperties.phonics.map((phonic, index) => {
                                  let currentPhonics: Phonics[] = [];
                                  for (const phoneme of phonic.phonemes) {
                                    const foundPhonic = storyData.phonics.find(
                                      (fullPhonic) =>
                                        fullPhonic.phoneme === phoneme
                                    );
                                    if (foundPhonic) {
                                      currentPhonics.push(foundPhonic);
                                    }
                                  }

                                  return (
                                    <React.Fragment key={generateID()}>
                                      <div className="story__tracking-section--phonic-part-container">
                                        <span
                                          className={`${
                                            trackedWords[
                                              trackedWords.findIndex(
                                                (val) =>
                                                  val.word ===
                                                  wordProperties.word
                                              )
                                            ].positions.includes(
                                              phonic.position
                                            )
                                              ? phonic.position === 1
                                                ? "story__phonic-part--first "
                                                : phonic.position ===
                                                  wordProperties.phonics.length
                                                ? "story__phonic-part--last "
                                                : phonic.position === 2
                                                ? "story__phonic-part--second "
                                                : phonic.position ===
                                                  wordProperties.phonics
                                                    .length -
                                                    1
                                                ? "story__phonic-part--penultimate "
                                                : "story__phonic-part--middle "
                                              : ""
                                          }${
                                            wordProperties.phonics.length > 5
                                              ? "story__tracking-section--phonic-part-small"
                                              : "story__tracking-section--phonic-part"
                                          }`}
                                        >
                                          {`${phonic.part}`}
                                        </span>
                                        <span
                                          className={`${
                                            trackedWords[
                                              trackedWords.findIndex(
                                                (val) =>
                                                  val.word ===
                                                  wordProperties.word
                                              )
                                            ].positions.includes(
                                              phonic.position
                                            )
                                              ? phonic.position === 1
                                                ? "story__phonic-click--first "
                                                : phonic.position ===
                                                  wordProperties.phonics.length
                                                ? "story__phonic-click--last "
                                                : phonic.position === 2
                                                ? "story__phonic-click--second "
                                                : phonic.position ===
                                                  wordProperties.phonics
                                                    .length -
                                                    1
                                                ? "story__phonic-click--penultimate "
                                                : "story__phonic-click--middle "
                                              : ""
                                          }${
                                            wordProperties.phonics.length > 5
                                              ? "story__tracking-section--phonic-click-small"
                                              : "story__tracking-section--phonic-click"
                                          }`}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            clickPhonic !== undefined &&
                                              clickPhonic(
                                                wordProperties.word,
                                                wordProperties.definitions,
                                                wordProperties.phonics,
                                                phonic.phonemes.length > 1 &&
                                                  phonic.multiple
                                                  ? currentPhonics
                                                      .map(
                                                        (fullPhonic) =>
                                                          fullPhonic.name
                                                      )
                                                      .join(" AND ")
                                                  : !phonic.multiple
                                                  ? currentPhonics
                                                      .map(
                                                        (fullPhonic) =>
                                                          fullPhonic.name
                                                      )
                                                      .join(" OR ")
                                                  : currentPhonics.map(
                                                      (fullPhonic) =>
                                                        fullPhonic.name
                                                    )[0],
                                                phonic.position
                                              );
                                          }}
                                        ></span>
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                                <button
                                  className="story__tracking-section--remove-word"
                                  onClick={() => {
                                    removeWord !== undefined &&
                                      removeWord(wordProperties.word);
                                  }}
                                >
                                  <i className="fas fa-minus story__tracking-section--minus-icon"></i>
                                </button>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </>
                )}
              </>
            )}
          {
            <img
              id="story-image"
              className="story__page--img"
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${
                mobileFriendly ? `${image.split(".").join("Mobile.")}` : image
              }`}
              alt="Story Page"
              onClick={() => {
                setWordToHighlight("");
                setWordToHighlightIndex(-1);
                setWordToHighlightLineIndex(-1);
                onClick("", [""], [], 0);
              }}
              onError={(e: any) => {
                if (!extensionsTried.jpg || correctExtension === "jpg") {
                  e.target.src = `${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${image.replace("webp", "jpg")}`;
                  setExtensionsTried((prev: any) => ({ ...prev, jpg: true }));
                  setCorrectExtension("jpg");
                } else if (!extensionsTried.png || correctExtension === "png") {
                  e.target.src = `${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${image.replace("webp", "png")}`;
                  setExtensionsTried((prev: any) => ({ ...prev, png: true }));
                  setCorrectExtension("png");
                } else if (
                  !extensionsTried.jpeg ||
                  correctExtension === "jpeg"
                ) {
                  e.target.src = `${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${image.replace("webp", "jpeg")}`;
                  setExtensionsTried((prev: any) => ({ ...prev, jpeg: true }));
                  setCorrectExtension("jpeg");
                }
              }}
            />
          }
        </div>
      )}
      {/* {!showQuestions &&
        !isMobile &&
        page !== undefined &&
        setPage !== undefined && (
          <div
            className="story__next-page"
            onClick={(e) => {
              e.stopPropagation();

              flipPageForward(page, setPage, setShowQuestions, storyDetails);

              onClick("", [""], [], 0);
            }}
          ></div>
        )} */}
    </div>
  );
};

export default StoryPageText;
