import React, {
  ForwardedRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useStories } from "../../Hooks/useStories";
import { useUserData } from "../../Hooks/useUserData";
import { TrackedWords } from "../../Models";
import {
  WordPhonics,
  Words,
  Question,
  Phonics,
  Story,
} from "../../Models/Stories";
import { ModalProps } from "../../Pages/StoryComponent";
import { generateID } from "../../Utils/GenerateID";
// import { flipPageBack, flipPageForward } from "../../Utils/PageFlip";

export type StoryPageTextProps = {
  image: string;
  pageNumber: number;
  mobileFriendly?: boolean;
  lines: string[];

  onClick: (
    text: string,
    definitions: string[],
    phonics: WordPhonics[],
    pageNumber: number,
    images?: string[],
    audio?: string
  ) => void;
};

const StoryPreview: React.FC<StoryPageTextProps> = ({
  image,
  pageNumber,
  mobileFriendly,
  lines,
  onClick,
}) => {
  const { storyData } = useStories();
  const { userData } = useUserData();

  const [extensionsTried, setExtensionsTried] = useState({
    jpg: false,
    png: false,
    jpeg: false,
  });
  const [correctExtension, setCorrectExtension] = useState("");

  const MAPPED_WORDS = useMemo(
    () =>
      lines.map((line, lineIndex) => {
        return (
          <React.Fragment key={generateID()}>
            <div
              className={
                line.trim() === "" ? "story__line--empty" : "story__line"
              }
            >
              {line.split(" ").map((word, wordIndex) => {
                return (
                  <React.Fragment key={generateID()}>
                    <span
                      className={"story__clickable-word"}
                      style={{ fontSize: "1.4rem" }}
                    >
                      {word}
                    </span>
                    <span> </span>
                  </React.Fragment>
                );
              })}
            </div>
          </React.Fragment>
        );
      }),
    [lines, storyData]
  );

  return (
    <div className="story__page-container" style={{ pointerEvents: "none" }}>
      {
        <div className="story__page">
          <div className="story__word-container">
            <span>{MAPPED_WORDS}</span>
          </div>
          {
            <img
              id="story-image"
              className="story__page--img"
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${
                mobileFriendly ? `${image.split(".").join("Mobile.")}` : image
              }`}
              alt="Story Page"
              onError={(e: any) => {
                if (!extensionsTried.jpg || correctExtension === "jpg") {
                  e.target.src = `${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${image.replace("webp", "jpg")}`;
                  setExtensionsTried((prev: any) => ({ ...prev, jpg: true }));
                  setCorrectExtension("jpg");
                } else if (!extensionsTried.png || correctExtension === "png") {
                  e.target.src = `${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${image.replace("webp", "png")}`;
                  setExtensionsTried((prev: any) => ({ ...prev, png: true }));
                  setCorrectExtension("png");
                } else if (
                  !extensionsTried.jpeg ||
                  correctExtension === "jpeg"
                ) {
                  e.target.src = `${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${image.replace("webp", "jpeg")}`;
                  setExtensionsTried((prev: any) => ({ ...prev, jpeg: true }));
                  setCorrectExtension("jpeg");
                }
              }}
            />
          }
        </div>
      }
    </div>
  );
};

export default StoryPreview;
