export const ConvertGradeToNumberOrString = (grade: number | string) =>
  typeof grade === "number"
    ? grade === -1
      ? "Pre-K"
      : grade === 0
      ? "KG"
      : `Gr ${grade}`
    : grade === "Pre-K"
    ? -1
    : grade === "KG"
    ? 0
    : Number(grade.split(" ")[1]);
