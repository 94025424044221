import React from "react";
import { Story, StudentData, Users } from "../Models";

export const AssignmentDetector = (
  userData: Users,
  storyId?: string,
  setAssignment?: (value: React.SetStateAction<string | undefined>) => void
) => {
  if (storyId && userData.account_type === "student") {
    const classrooms = (userData.user_data as StudentData).classrooms;
    if (classrooms.length > 0) {
      const relevantClassroom = classrooms.find((classroom) =>
        classroom.active_assignments
          .find((assignment) => assignment.student === userData._id)
          ?.assignments.find((assignment) => assignment.story === storyId)
      );

      if (relevantClassroom) {
        setAssignment && setAssignment(relevantClassroom._id);
        return relevantClassroom._id;
      } else {
        return undefined;
      }
    }
  } else {
    return undefined;
  }
};
