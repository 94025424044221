import { useContext } from "react";
import { Display, DisplayContext } from "../Context/DisplayContext";
import { UserContext } from "../Context/UserContext";
import { ParentData, StudentData, TrackedWords, Users } from "../Models";
import { GetBaseURL } from "../Utils";

export const useUserData = () => {
  const { userData, setUserData } = useContext(UserContext);
  const { displayData, setDisplayData } = useContext(DisplayContext);

  const setUser = (id: string) => {
    localStorage.setItem("id", id);
    setUserData((prev: Users) => ({
      ...prev,
      shouldUpdate: true,
      updateID: id,
    }));
  };

  const updateSubscriptionData = async (story_title: string) => {
    const today = new Date(Date.now());

    if (
      userData.subscription_data.freemium_tallies.some(
        (tally) => tally.story_title === story_title
      )
    ) {
      if (
        today.setHours(0, 0, 0, 0).valueOf() -
          new Date(
            userData.subscription_data.freemium_tallies[
              userData.subscription_data.freemium_tallies.length - 1
            ].access_date
          )
            .setHours(0, 0, 0, 0)
            .valueOf() >
        0
      ) {
        //delete and add new access date
        const request = await fetch(
          `${GetBaseURL()}/api/users/subscriptionData?key=${
            process.env.REACT_APP_PERSONAL_AUTH_KEY
          }`,
          {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              id: userData._id,
              parent_id:
                userData.account_type === "parent"
                  ? userData._id
                  : (userData.user_data as StudentData).linked_accounts.length >
                    0
                  ? (userData.user_data as StudentData).linked_accounts[0]._id
                  : "standalone",
              story_title,
              access_date: today,
              update: true,
            }),
          }
        );
        const response = await request.json();
        setUserData((prev: Users) => ({
          ...prev,
          shouldUpdate: true,
          updateID: response.id,
        }));
        return response.message;
      } else {
        console.log("Already exists, same day");
      }
    } else {
      //normal add
      const request = await fetch(
        `${GetBaseURL()}/api/users/subscriptionData?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: userData._id,
            parent_id:
              userData.account_type === "parent"
                ? userData._id
                : (userData.user_data as StudentData).linked_accounts.length > 0
                ? (userData.user_data as StudentData).linked_accounts[0]._id
                : "standalone",
            story_title,
            access_date: today,
          }),
        }
      );
      const response = await request.json();
      setUserData((prev: Users) => ({
        ...prev,
        shouldUpdate: true,
        updateID: response.id,
      }));
      return response.message;
    }
  };

  const updateUserWithSubscription = async (
    planType: string | null,
    defaultPaymentMethod: string | null
  ) => {
    const request = await fetch(
      `${GetBaseURL()}/api/payments/update-subscription?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          priceId:
            planType === "annual"
              ? process.env.REACT_APP_STRIPE_ANNUAL_PRICE
              : process.env.REACT_APP_STRIPE_MONTHLY_PRICE,
          customerId: userData.subscription_data.stripe_customer_id,
          userId:
            userData.account_type === "parent"
              ? userData._id
              : (userData.user_data as StudentData).linked_accounts[0]._id,
          planType,
          defaultPaymentMethod,
        }),
      }
    );
    const response = await request.json();

    if (request.status === 200) {
      console.log(response);
      setUserData((prev: Users) => ({
        ...prev,
        shouldUpdate: true,
        updateID: userData._id,
      }));
      if (planType !== "card") {
        const trackPurchaseRequest = await fetch(
          `${GetBaseURL()}/api/users/trackPurchase?key=${
            process.env.REACT_APP_PERSONAL_AUTH_KEY
          }`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: userData.user_data.email,
              price: planType === "monthly" ? 9.99 : 83.88,
              type: planType,
            }),
          }
        );
      }
    } else {
      console.log(response.message);
    }
  };

  const updateTrackedWords = async (tracked_words: TrackedWords[]) => {
    const request = await fetch(
      `${GetBaseURL()}/api/users/trackedWords?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: userData._id, tracked_words }),
      }
    );
    const response = await request.json();
    setUserData((prev: Users) => ({
      ...prev,
      shouldUpdate: true,
      updateID: response.user._id,
    }));
    return response.message;
  };

  const addWordAsRecentlyLearned = async (word: string, remove?: boolean) => {
    await fetch(
      `${GetBaseURL()}/api/users/recentlyLearned?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: userData._id, word, remove }),
      }
    );
  };

  const addStoryAsRead = async (story_id?: string, score?: string) => {
    const request = await fetch(
      `${GetBaseURL()}/api/users/addStory?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: userData._id, story_id, score }),
      }
    );
    const response = await request.json();
    if (request.status === 200) {
      setUserData((prev: Users) => ({
        ...prev,
        shouldUpdate: true,
        updateID: response.user._id,
      }));
    } else {
      console.log(response.message);
    }
  };

  // const addNewScore = async (
  //   classroom_id: string,
  //   score: string,
  //   story_id?: string
  // ) => {
  //   const request = await fetch(
  //     `${GetBaseURL()}/api/classrooms/addNewScore?key=${
  //       process.env.REACT_APP_PERSONAL_AUTH_KEY
  //     }`,
  //     {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         classroom_id,
  //         student_id: userData._id,
  //         story_id,
  //         score,
  //       }),
  //     }
  //   );
  //   const response = await request.json();
  //   if (request.status === 200) {
  //     setUserData((prev: Users) => ({
  //       ...prev,
  //       shouldUpdate: true,
  //       updateID: response.user_id,
  //     }));
  //   } else {
  //     console.log(response.message);
  //   }
  // };

  const submitAssignment = async (
    classroom_id: string,
    audio_src: string,
    accuracy: number,
    missedWords: string[],
    score: string,
    transcript: string,
    story_id?: string
  ) => {
    const request = await fetch(
      `${GetBaseURL()}/api/classrooms/submitAssignment?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          classroom_id,
          student_id: userData._id,
          story_id,
          data: { audio_src, accuracy, missedWords, score, transcript },
        }),
      }
    );
    const response = await request.json();
    if (request.status === 200) {
      setUserData((prev: Users) => ({
        ...prev,
        shouldUpdate: true,
        updateID: response.user_id,
      }));
    } else {
      console.log(response.message);
    }
  };

  const updateReadingLevel = async (
    child_id: string,
    reading_level: string
  ) => {
    const request = await fetch(
      `${GetBaseURL()}/api/users/updateReadingLevel?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          child_id,
          user_id: userData._id,
          reading_level,
        }),
      }
    );
    const response = await request.json();
    if (request.status === 200) {
      setDisplayData((prev: Display) => ({
        ...prev,
        childData: {
          ...prev.childData,
          reading_level,
        },
      }));
      setUserData((prev: Users) => ({
        ...prev,
        shouldUpdate: true,
        updateID: response.user_id,
      }));
    } else {
      console.log(response.message);
    }
  };

  const updateGrade = async (child_id: string, grade: number | string) => {
    const request = await fetch(
      `${GetBaseURL()}/api/users/updateGrade?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          child_id,
          user_id: userData._id,
          grade,
        }),
      }
    );
    const response = await request.json();
    if (request.status === 200) {
      setDisplayData((prev: Display) => ({
        ...prev,
        childData: {
          ...prev.childData,
          grade,
        },
      }));
      setUserData((prev: Users) => ({
        ...prev,
        shouldUpdate: true,
        updateID: response.user_id,
      }));
    } else {
      console.log(response.message);
    }
  };

  const updateLastActive = (date: Date) => {
    fetch(
      `${GetBaseURL()}/api/users/updateLastActive?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: userData._id, date }),
      }
    );
  };

  const logout = () => {
    localStorage.removeItem("id");
    setUserData({
      _id: "",
      account_type: "student",
      user_data: {
        name: "",
        grade: "",
        email: "",
        assessment_scores: [],
        linked_accounts: [],
        tracked_words: [],
        assigned_stories: [],
      },
      subscription_data: {
        plan_type: "free",
        freemium_tallies: {
          words_tracked: 0,
          stories_read: 0,
        },
      },
      shouldUpdate: false,
      displayTutorial: true,
    });
  };

  const stopDisplayingTutorial = async () => {
    const request = await fetch(
      `${GetBaseURL()}/api/users/tutorial?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: userData._id }),
      }
    );
    const response = await request.json();
    setUserData((prev: Users) => ({
      ...prev,
      displayTutorial: false,
    }));
  };

  return {
    userData,
    setUserData,
    setUser,
    updateTrackedWords,
    updateSubscriptionData,
    updateUserWithSubscription,
    addStoryAsRead,
    // addNewScore,
    submitAssignment,
    addWordAsRecentlyLearned,
    updateReadingLevel,
    updateGrade,
    updateLastActive,
    logout,
    stopDisplayingTutorial,
  };
};
