import React, { useEffect, useState } from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { useUserData } from "../Hooks/useUserData";
import { ReferralData, Users } from "../Models";
import { encrypt, generateID, GetBaseURL } from "../Utils";

interface MatchParams {
  userID: string;
}

interface ComponentProps extends RouteComponentProps<MatchParams> {}

const ReferralsPage: React.FC<ComponentProps> = ({ match }) => {
  const { userData } = useUserData();
  const [referralData, setReferralData] = useState<string | null>(null);
  const [refererData, setRefererData] = useState<ReferralData | null>(null);
  const [isAnimationActive, setIsAnimationActive] = useState(false);
  const [error, setError] = useState({
    type: "error",
    isActive: false,
    message: "",
  });

  const fetchReferralData = async (id: string, referer?: boolean) => {
    const request = await fetch(
      `${GetBaseURL()}/api/referrals/get?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          referral_id: id,
        }),
      }
    );
    const response = await request.json();
    if (request.status === 200) {
      if (referer) {
        setRefererData(response.referral);
      } else {
        setReferralData(response.referer);
      }
    } else {
      setError({ type: "error", isActive: true, message: response.message });
    }
  };

  useEffect(() => {
    // if (userData.subscription_data.referral_id) {
    //   fetchReferralData(userData.subscription_data.referral_id);
    // }
    if (userData.subscription_data.referer_id) {
      fetchReferralData(userData.subscription_data.referer_id, true);
    }
  }, [
    // userData.subscription_data.referral_id,
    userData.subscription_data.referer_id,
  ]);

  const animate = () => {
    setIsAnimationActive(true);
    setTimeout(() => {
      setIsAnimationActive(false);
    }, 700);
  };

  return localStorage.getItem("id") === match.params.userID ||
    (userData._id &&
      userData._id !== "" &&
      userData.account_type === "parent" &&
      userData._id === match.params.userID) ? (
    <section className="referrals">
      <div className="referrals__banner">
        <Link to="/">
          <i className="fa fa-arrow-left referrals__back-arrow"></i>
        </Link>
        <div className="referrals__text-dark">Share &amp; Earn</div>
        <div className="referrals__text-white">
          Share with friends, if they sign up for a paid account, you earn $10
          Cash!
        </div>
      </div>
      <div className="referrals__content-wrap">
        <div className="referrals__link-container">
          <div className="referrals__prompt">Your Custom Referral Link:</div>
          <div
            className="referrals__copy-container"
            onClick={() => {
              animate();
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_SITE_URL}/ref/${userData._id}`
              );
            }}
          >
            <div
              className={
                isAnimationActive
                  ? "referrals__input-clone--highlight referrals__input-clone"
                  : "referrals__input-clone"
              }
            >
              <span
                className={
                  isAnimationActive
                    ? "referrals__link--highlight referrals__link"
                    : "referrals__link"
                }
              >{`${process.env.REACT_APP_SITE_URL}/ref/${userData._id}`}</span>
            </div>
            <span
              className={
                isAnimationActive
                  ? "referrals__copy referrals__copy--animate"
                  : "referrals__copy"
              }
            >
              {isAnimationActive ? "Copied!" : "Copy"}
              <i className="far fa-copy"></i>
            </span>
          </div>
        </div>
        <div className="referrals__earned--wrap">
          <div className="referrals__earned--container">
            <div className="referrals__earned--heading">Cash Earned</div>
            <div className="referrals__earned--row">
              <div className="referrals__earned--text">Total </div>
              <div className="referrals__earned--amount">
                ${" "}
                {refererData
                  ? refererData.referrals.filter(
                      (ref) => ref.subscription_data.plan_type === "paid"
                    ).length * 10
                  : 0}
              </div>
            </div>
          </div>
          <div className="referrals__earned--payout">
            <span className="referrals__earned--blue-text">
              * Cash is paid out every $50 earned.
            </span>{" "}
            <br></br>
            <span className="referrals__earned--small-text">
              (Every 5 people that sign up for a paid account)
            </span>{" "}
            <br></br>
            <br></br>
            <br></br>{" "}
            <span className="referrals__earned--med-text">
              Cash will be sent directly to the email below:
            </span>
            <div className="referrals__row">
              {/* <div className="referrals__input-clone referrals__input-email"> */}
              <span className="referrals__earned--blue-text">
                {userData.user_data.email}
              </span>
              {/* </div> */}
              {/* <div className="referrals__change-email-wrapper">
                <div className="referrals__change-email">Change</div>
              </div> */}
            </div>
            {/* <div className="referrals__email-desc">
              * Changing this email does NOT change your login email.
            </div> */}
          </div>
        </div>
      </div>

      {error.isActive && <div>{error.message}</div>}
      {refererData ? (
        // refererData.referrals.length === 0 ? (
        //   <div className="referrals__none">
        //     No one has signed up with your referral link yet.
        //   </div>
        // ) : (
        <div className="referrals__referral-data">
          <div className="referrals__referral-data--title">Your Referrals</div>
          <div className="referrals__referral-data--row referrals__referral-data--row-title">
            <span className="referrals__referral-data--first-item">
              Free Accounts: {refererData.referrals.length}
            </span>
            <span className="referrals__referral-data--second-item">
              Paid Accounts:{" "}
              {
                refererData.referrals.filter(
                  (ref) => ref.subscription_data.plan_type === "paid"
                ).length
              }
            </span>
          </div>

          {refererData.referrals.map((referral) => (
            <React.Fragment key={generateID()}>
              <div className="referrals__referral-data--row">
                <span className="referrals__referral-data--first-item">
                  {referral.user_data.email}
                </span>
                <span className="referrals__referral-data--second-item">
                  {referral.subscription_data.plan_type === "paid" ? (
                    <div className="referrals__referral-data--subscribed">
                      Subscribed{" "}
                      <i className="fa fa-check referrals__referral-data--check" />
                    </div>
                  ) : (
                    <div>Not Subscribed</div>
                  )}
                </span>
              </div>
            </React.Fragment>
          ))}
        </div>
      ) : (
        // )
        // !userData.subscription_data.referer_id ? (
        //   <div className="referrals__none">
        //     No one has signed up with your referral link yet.
        //   </div>
        // ) :

        <div className="referrals__referral-data">
          <div className="referrals__referral-data--title">Your Referrals</div>
          <div className="referrals__referral-data--row referrals__referral-data--row-title">
            <span className="referrals__referral-data--first-item">
              Total Users: 0
            </span>
            <span className="referrals__referral-data--second-item">
              Total Subscribed: 0
            </span>
          </div>
        </div>
      )}
      {/* {referralData ? (
        <div className="referrals__referer">
          You were referred by {referralData}
        </div>
      ) : !userData.subscription_data.referral_id ? (
        <div className="referrals__none">
          You have not been referred by anyone.
        </div>
      ) : (
        <div className="referrals__fetching">Fetching Referer...</div>
      )} */}
    </section>
  ) : (
    <Redirect to="/" />
  );
};

export default ReferralsPage;
