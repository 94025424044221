import React, { useEffect, useLayoutEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useReactMediaRecorder } from "react-media-recorder";
import { useUserData } from "../../Hooks/useUserData";
import { GetBaseURL } from "../../Utils";
import { Story } from "../../Models";
import { SetAssignmentType } from "../../Pages/StoryComponent";

type AssignmentHeaderProps = {
  storyDetails: Story;
  // assignment: string;
  setAssignmentData: (
    value: React.SetStateAction<SetAssignmentType | null>
  ) => void;
};

const AssignmentHeader: React.FC<AssignmentHeaderProps> = ({
  storyDetails,
  // assignment,
  setAssignmentData,
}) => {
  // const { submitAssignment } = useUserData();
  const [missedWords, setMissedWords] = useState<string[]>([]);
  const [accuracy, setAccuracy] = useState<number | null>(null);
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    clearBlobUrl,
    pauseRecording,
    resumeRecording,
  } = useReactMediaRecorder({ audio: true });
  const {
    transcript,
    listening,
    resetTranscript,
    // browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useLayoutEffect(() => {
    if (mediaBlobUrl) {
      const textArray = storyDetails.pages
        .map((storyPage) => storyPage.lines.join(" "))
        .join(" ")
        .toLowerCase()
        .replace(/\[|\]|\"|\”|”|\“|“|\:|\;|\(|\)|\.|\,|\?|\!/gi, "")
        .split(" ")
        .filter((word) => word !== "");

      const arrayOfTruth = textArray.map((word) => transcript.includes(word));

      setAssignmentData({
        missedWords: textArray
          .map((word) => (transcript.includes(word) ? "" : word))
          .filter((word) => word !== ""),
        accuracy:
          arrayOfTruth.filter((bool) => bool).length / arrayOfTruth.length,
        mediaBlobUrl: mediaBlobUrl,
        transcript: transcript,
      });
    }
  }, [mediaBlobUrl]);

  return (
    <div className="assignment-header">
      <div className="assignment-header--prompt">
        We have detected that this story has been assigned to you.
      </div>
      <div className="assignment-header--recording-prompt">
        <div className="assignment-header--stat-container">
          <div className="assignment-header--recording-indicator">
            {status === "recording" && "recording"}
          </div>
          {status === "recording" && (
            <div className="story__progress-bar--loader">
              <span className="story__progress-bar--audio-line"></span>
              <span className="story__progress-bar--audio-line"></span>
              <span className="story__progress-bar--audio-line"></span>
              <span className="story__progress-bar--audio-line"></span>
              <span className="story__progress-bar--audio-line"></span>
              <span className="story__progress-bar--audio-line"></span>
              <span className="story__progress-bar--audio-line"></span>
            </div>
          )}
          {accuracy !== null && (
            <div className="assignment-header--accuracy">
              Automated Assessment Accuracy: {accuracy * 100}%
            </div>
          )}
          {accuracy !== null && (
            <div className="assignment-header--missed-words">
              Missed Words: {missedWords.join(", ")}
            </div>
          )}
          <audio
            id="audio"
            className="assignment-header--audio"
            src={mediaBlobUrl ? mediaBlobUrl : undefined}
            controls
          />
          {mediaBlobUrl && (
            <div
              className="modal__submit"
              onClick={() => {
                document
                  .getElementsByClassName("story__finish")[0]
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Go to Questions
            </div>
          )}
        </div>
        Begin Recording{" "}
        <i
          className={
            status === "recording" || status === "paused"
              ? "fas fa-microphone-alt story__progress-bar--icon-outline--listening"
              : "fas fa-microphone-alt story__progress-bar--icon-outline"
          }
          onClick={() => {
            if (status === "recording" || status === "paused") {
              SpeechRecognition.stopListening();
              stopRecording();
            } else {
              SpeechRecognition.startListening({ continuous: true });
              startRecording();
              clearBlobUrl();
              setAccuracy(null);
            }
          }}
        />
        {(status === "recording" || status === "paused") && (
          <i
            className={`${
              status === "paused" ? "fas fa-play" : "fas fa-pause"
            }`}
            onClick={() => {
              if (status === "paused") {
                SpeechRecognition.startListening({ continuous: true });
                resumeRecording();
              } else {
                SpeechRecognition.stopListening();
                pauseRecording();
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AssignmentHeader;
