import React from "react";
import { useStories } from "../../Hooks/useStories";
import { Phonics } from "../../Models";
import { generateID } from "../../Utils";

const WordOfTheDay: React.FC = ({}) => {
  const { storyData } = useStories();
  return (
    <span
      className="user-details--word-day-container"
      style={{
        display: "flex",
        flexDirection: "column",

        borderRadius: "4px",
        boxShadow: "0px 15px 25px rgb(0 0 0 / 80%)",
      }}
    >
      <div className="user-details--wotd-container">
        <span className="user-details--wotd-title">Word of the Day: </span>
        <span
          className="user-details--stat"
          style={{ marginTop: "unset", pointerEvents: "none" }}
        >
          {storyData.words
            .find((word) => word.wordOfTheDay)
            ?.phonics.map((wordOfTheDayPhonic) => (
              <React.Fragment key={generateID()}>
                <span
                  className={
                    wordOfTheDayPhonic.position === 1
                      ? "user-details__wotd-phonic--first "
                      : wordOfTheDayPhonic.position ===
                        storyData.words.find((word) => word.wordOfTheDay)!
                          .phonics.length
                      ? "user-details__wotd-phonic--last "
                      : wordOfTheDayPhonic.position === 2
                      ? "user-details__wotd-phonic--second "
                      : wordOfTheDayPhonic.position ===
                        storyData.words.find((word) => word.wordOfTheDay)!
                          .phonics.length -
                          1
                      ? "user-details__wotd-phonic--penultimate "
                      : "user-details__wotd-phonic--middle "
                  }
                >
                  {wordOfTheDayPhonic.part}
                </span>
              </React.Fragment>
            ))}
        </span>
        {storyData.words.find((word) => word.wordOfTheDay) !== undefined && (
          <div
            onClick={() => {
              let audioToPlay: any = document.getElementById(
                `word-audio-${
                  storyData.words.find((word) => word.wordOfTheDay)?.text
                }`
              );
              audioToPlay.play();
            }}
          >
            <audio
              id={`word-audio-${
                storyData.words.find((word) => word.wordOfTheDay)?.text
              }`}
            >
              <source
                src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${
                  storyData.words.find((word) => word.wordOfTheDay)?.audio
                }`}
              ></source>
            </audio>
            <i
              className="fas fa-volume-up story__modal--sound-icon"
              style={{ transform: "unset" }}
            ></i>
          </div>
        )}
      </div>
      <span className="user-details--word-of-the-day">
        {storyData.words
          .find((word) => word.wordOfTheDay)
          ?.phonics.map((phonic, index) => {
            let currentPhonics: Phonics[] = [];
            for (const phoneme of phonic.phonemes) {
              const foundPhonic = storyData.phonics.find(
                (fullPhonic) => fullPhonic.phoneme === phoneme
              );
              if (foundPhonic) {
                currentPhonics.push(foundPhonic);
              }
            }
            return (
              <React.Fragment key={generateID()}>
                <span
                  className={`${
                    phonic.position === 1
                      ? "user-details__wotd-phonic-part--first "
                      : phonic.position ===
                        storyData.words.find((word) => word.wordOfTheDay)!
                          .phonics.length
                      ? "user-details__wotd-phonic-part--last "
                      : phonic.position === 2
                      ? "user-details__wotd-phonic-part--second "
                      : phonic.position ===
                        storyData.words.find((word) => word.wordOfTheDay)!
                          .phonics.length -
                          1
                      ? "user-details__wotd-phonic-part--penultimate "
                      : "user-details__wotd-phonic-part--middle "
                  } user-details--wotd-phonic-part`}
                >
                  {phonic.part}
                </span>
                {currentPhonics.map((fullPhonic, fullPhonicIndex) => {
                  return (
                    <React.Fragment key={generateID()}>
                      <span
                        className="user-details--wotd-phonic-audio"
                        onClick={() => {
                          let audioToPlay: any = document.getElementById(
                            `phonic-audio-${fullPhonic.audio}`
                          );
                          audioToPlay.play();
                          // if (tutorialPoint === 4) {
                          //   setTutorialPoint(tutorialPoint + 1);
                          // }
                        }}
                      >
                        <audio id={`phonic-audio-${fullPhonic.audio}`}>
                          <source
                            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${fullPhonic.audio}`}
                          ></source>
                        </audio>
                        <i
                          className="fas fa-volume-up story__modal--phonic-sound-icon"
                          style={{ padding: "unset" }}
                        ></i>
                      </span>
                    </React.Fragment>
                  );
                })}
                {currentPhonics.map((fullPhonic, fullPhonicIndex) => {
                  return (
                    <React.Fragment key={generateID()}>
                      <span className="user-details--wotd-phonic-name">
                        {fullPhonic.name}
                      </span>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
      </span>
    </span>
  );
};

export default WordOfTheDay;
