import React, { Ref, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDisplayData } from "../../Hooks/useDisplayData";
import { useStories } from "../../Hooks/useStories";
import { AllPhoneNumbers } from "../../Models";
import { FeaturedStoriesType, FeaturedStoriesType2 } from "./StudentPortal";
import { generateID, ValidateEmail } from "../../Utils";
import StoryCard from "./StoryCard";

type ReadingPortalRowProps = {
  rowTitle: string;
  loggedOut?: boolean;
  onload?: (title: string) => void;
  loadCounter?: FeaturedStoriesType;
  loadCounter2?: FeaturedStoriesType2;
};

const ReadingPortalRow: React.FC<ReadingPortalRowProps> = ({
  rowTitle,
  loggedOut,
  onload,
  loadCounter,
  loadCounter2,
}) => {
  const { storyData } = useStories();
  const [hideLeftButton, setHideLeftButton] = useState(true);
  const [hideRightButton, setHideRightButton] = useState(false);
  const [makeStoryUnclickable, setMakeStoryUnclickable] = useState(false);
  const leftButton = useRef<HTMLButtonElement>(null);
  const rightButton = useRef<HTMLButtonElement>(null);
  const rowDiv = useRef<HTMLDivElement>(null);
  let width = window.innerWidth;

  window.addEventListener("resize", () => {
    width = window.innerWidth;
    if (width <= 1024) {
      setHideLeftButton(true);
      setHideRightButton(true);
    } else {
      if (rowDiv.current) {
        if (rowDiv.current.scrollWidth === rowDiv.current.clientWidth) {
          setHideLeftButton(true);
          setHideRightButton(true);
        } else {
          if (rowDiv.current.scrollLeft > 0) {
            setHideLeftButton(false);
          } else {
            setHideLeftButton(true);
          }
          if (
            rowDiv.current.scrollWidth -
              rowDiv.current.clientWidth -
              rowDiv.current.scrollLeft >
            0
          ) {
            setHideRightButton(false);
          } else {
            setHideRightButton(true);
          }
        }
      }
    }
  });

  useEffect(() => {
    if (rowDiv.current !== null) {
      if (rowDiv.current.scrollWidth <= rowDiv.current.clientWidth) {
        setHideLeftButton(true);
        setHideRightButton(true);
      }
      rowDiv.current.addEventListener("scroll", () => {
        if (
          rowDiv.current !== null &&
          rowDiv.current.scrollWidth === rowDiv.current.clientWidth
        ) {
          setHideLeftButton(true);
          setHideRightButton(true);
        } else {
          if (width <= 1024) {
            setHideRightButton(true);
            setHideLeftButton(true);
          } else {
            if (rowDiv.current !== null && rowDiv.current.scrollLeft > 0) {
              setHideLeftButton(false);
            } else {
              setHideLeftButton(true);
            }
            if (
              rowDiv.current !== null &&
              rowDiv.current.scrollWidth -
                rowDiv.current.clientWidth -
                rowDiv.current.scrollLeft >
                0
            ) {
              setHideRightButton(false);
            } else {
              setHideRightButton(true);
            }
          }
        }
      });
    }
  }, [rowDiv.current]);

  useEffect(() => {
    if (width <= 1024) {
      setHideLeftButton(true);
      setHideRightButton(true);
    }
  }, []);

  return (
    <div
      className="reading-portal__row-stories"
      id={`${rowTitle}-stories`}
      ref={rowDiv}
    >
      {!hideLeftButton && (
        <button
          className="reading-portal__row-stories--scroll-left"
          ref={leftButton}
          onClick={() => {
            if (rowDiv.current) {
              setHideRightButton(false);
              setMakeStoryUnclickable(true);
              rowDiv.current.scrollBy({
                left: -(0.75 * rowDiv.current.clientWidth),
                behavior: "smooth",
              });
              setTimeout(() => {
                setMakeStoryUnclickable(false);
              }, 1000);
            }
          }}
        >
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
      )}
      {loggedOut
        ? loadCounter
          ? storyData.stories
              .filter((story) => Object.keys(loadCounter).includes(story.title))
              .map((story) => {
                return (
                  <React.Fragment key={generateID()}>
                    <StoryCard
                      onload={onload}
                      stopLoad={
                        loadCounter[story.title as keyof FeaturedStoriesType]
                      }
                      title={story.title}
                      level={story.level}
                      tags={story.tags}
                      cover={story.cover}
                      wordCount={
                        Array.from(
                          new Set(
                            story.pages
                              .map((page) => {
                                return page.page_text
                                  .replace(/\r/g, "")
                                  .split(/\n/)
                                  .join(";;")
                                  .split(";;")
                                  .join(" ")
                                  .split(" ");
                              })
                              .flat()
                          )
                        ).length
                      }
                      id={story._id}
                      priority_tag={story.priority_tag}
                      unclickable={makeStoryUnclickable}
                    ></StoryCard>
                  </React.Fragment>
                );
              })
          : storyData.stories
              .filter((story) =>
                Object.keys(loadCounter2!).includes(story.title)
              )
              .map((story) => {
                return (
                  <React.Fragment key={generateID()}>
                    <StoryCard
                      onload={onload}
                      stopLoad={
                        loadCounter2![story.title as keyof FeaturedStoriesType2]
                      }
                      title={story.title}
                      level={story.level}
                      tags={story.tags}
                      cover={story.cover}
                      wordCount={
                        Array.from(
                          new Set(
                            story.pages
                              .map((page) => {
                                return page.page_text
                                  .replace(/\r/g, "")
                                  .split(/\n/)
                                  .join(";;")
                                  .split(";;")
                                  .join(" ")
                                  .split(" ");
                              })
                              .flat()
                          )
                        ).length
                      }
                      id={story._id}
                      priority_tag={story.priority_tag}
                      unclickable={makeStoryUnclickable}
                    ></StoryCard>
                  </React.Fragment>
                );
              })
        : storyData.stories.map((story) => {
            return (
              (rowTitle.length > 1
                ? story.priority_tag
                  ? story.priority_tag === rowTitle
                  : story.tags.includes(rowTitle)
                : story.level === rowTitle) && (
                <React.Fragment key={generateID()}>
                  <StoryCard
                    title={story.title}
                    level={story.level}
                    tags={story.tags}
                    cover={story.cover}
                    wordCount={
                      Array.from(
                        new Set(
                          story.pages
                            .map((page) => {
                              return page.page_text
                                .replace(/\r/g, "")
                                .split(/\n/)
                                .join(";;")
                                .split(";;")
                                .join(" ")
                                .split(" ");
                            })
                            .flat()
                        )
                      ).length
                    }
                    id={story._id}
                    priority_tag={story.priority_tag}
                    unclickable={makeStoryUnclickable}
                  ></StoryCard>
                </React.Fragment>
              )
            );
          })}
      {!hideRightButton && (
        <button
          className="reading-portal__row-stories--scroll-right"
          ref={rightButton}
          onClick={() => {
            if (rowDiv.current) {
              setMakeStoryUnclickable(true);
              setHideLeftButton(false);
              rowDiv.current?.scrollBy({
                left: 0.75 * rowDiv.current.clientWidth,
                behavior: "smooth",
              });
              setTimeout(() => {
                setMakeStoryUnclickable(false);
              }, 1000);
            }
          }}
        >
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      )}
    </div>
  );
};

export default ReadingPortalRow;
