const google = {
  client_id: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`,
  scope: 'openid profile email',
};

const facebook = {
  appId: `${process.env.REACT_APP_FB_ID}`,
  cookie: true,
  xfbml: true,
  version: 'v2.11',
  scope: 'email',
};

export const googleConfig = google;
export const facebookConfig = facebook;
