import React from "react";
import { generateID } from "../../../Utils";
import Square from "./Square";

type BoardProps = {
  winner?: string;
  tie?: boolean;
  winningSquares?: number[];
  players: { p1: string; p2: string };
  playerNames: { p1: string; p2: string };
  squares: string[];
  onClick: (index: number) => void;
};

const Board: React.FC<BoardProps> = ({
  winner,
  tie,
  winningSquares,
  players,
  playerNames,
  squares,
  onClick,
}) => {
  return (
    <div className="tic-tac-toe__board">
      {winner && (
        <div className="tic-tac-toe__confetti">
          {Array.from(Array(150).keys()).map((val, index) => (
            <div key={generateID()} className={`confetti-${index}`}></div>
          ))}
        </div>
      )}
      {(winner || tie) && (
        <div className="tic-tac-toe__finish">
          <div className="tic-tac-toe__finish--text">
            {winner
              ? `WINNER! ${
                  winner === players.p1
                    ? `${playerNames.p1}: ${winner}`
                    : `${playerNames.p2}: ${winner}`
                }`
              : "TIE!"}
          </div>
        </div>
      )}
      {squares.map((square, index) => (
        <Square
          winningSquare={winningSquares?.includes(index)}
          value={square}
          onClick={() => onClick(index)}
          players={players}
        />
      ))}
    </div>
  );
};
export default Board;
