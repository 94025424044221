import React from "react";
import { Link, Redirect } from "react-router-dom";
import { useUserData } from "../Hooks/useUserData";

const MyList: React.FC = () => {
  const { userData } = useUserData();
  return localStorage.getItem("id") || (userData._id && userData._id !== "") ? (
    <section className="characters">
      <Link to="/">
        <i className="fa fa-arrow-left"></i>
      </Link>
      <div style={{ color: "white" }}>MY LIST</div>
    </section>
  ) : (
    <Redirect to="/" />
  );
};

export default MyList;
