import React, { useState, useEffect, ChangeEvent } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { generateID, GetBaseURL } from "../../Utils";
import { useUserData } from "../../Hooks/useUserData";
import { useDisplayData } from "../../Hooks/useDisplayData";
import PaymentForm from "./PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

type ManageSubscriptionProps = {};

const stripeTestPromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_STRIPE_KEY}`
    : `${process.env.REACT_APP_STRIPE_TEST_KEY}`
);

const ManageSubscription: React.FC<ManageSubscriptionProps> = ({}) => {
  const { userData, updateUserWithSubscription } = useUserData();
  const { hideModal } = useDisplayData();
  const [section, setSection] = useState(1);
  const [intent, setIntent] = useState("");
  const [slideInFromBack, setslideInFromBack] = useState(false);
  const [slideInFromFront, setslideInFromFront] = useState(false);
  const [changeCard, setChangeCard] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<any>(null);
  // const [paymentMethodId, setPaymentMethodId] = useState("");
  const [error, setError] = useState({
    type: "error",
    isActive: false,
    message: "",
  });

  const retrievePaymentMethods = async () => {
    const request = await fetch(
      `${GetBaseURL()}/api/payments/retrievePaymentMethods`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: userData.subscription_data.stripe_customer_id,
        }),
      }
    );
    const response = await request.json();
    if (request.status === 200) {
      setPaymentMethod(
        response.paymentMethods.find(
          (paymentMethod: any) => paymentMethod.current
        )
      );
    } else {
      console.log(response.message);
    }
  };

  useEffect(() => {
    if (
      userData.subscription_data.stripe_customer_id &&
      userData.subscription_data.stripe_customer_id !== ""
    ) {
      retrievePaymentMethods();
    }
  }, [userData]);

  const previousSection = () => {
    if (!(slideInFromFront && slideInFromBack)) {
      setslideInFromBack(true);
      setSection(section - 1);
      setTimeout(() => {
        setslideInFromBack(false);
      }, 500);
    }
  };

  const nextSection = () => {
    if (!(slideInFromFront && slideInFromBack)) {
      setslideInFromFront(true);
      setSection(section + 1);
      setTimeout(() => {
        setslideInFromFront(false);
      }, 500);
    }
  };

  const handleSubmit = async () => {
    const request = await fetch(
      `${GetBaseURL()}/api/payments/update-subscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId:
            intent === "annual"
              ? `${process.env.REACT_APP_STRIPE_ANNUAL_PRICE}`
              : intent === "monthly"
              ? `${process.env.REACT_APP_STRIPE_MONTHLY_PRICE}`
              : "cancel",
          customerId: userData.subscription_data.stripe_customer_id,
          userId: userData._id,
        }),
      }
    );
    const response = await request.json();
    if (request.status === 200) {
      setError({ type: "success", isActive: true, message: "Success!" });
      window.location.reload();
    } else {
      setError({ type: "error", isActive: true, message: response.message });
    }
  };

  return (
    <div className="modal__manage-subscription">
      {error.isActive && <span>{error.message}</span>}
      {section === 1 && (
        <div className="modal__payment-options">
          <div className="modal__prompt">Manage Subscription</div>
          <span className="modal__subheading">
            Your next billing date is{" "}
            {userData.subscription_data.next_billing_date}
          </span>
          <div className="modal__manage-cards">
            <div
              style={
                userData.subscription_data.billing_cycle === "monthly"
                  ? { pointerEvents: "none" }
                  : undefined
              }
              className={
                userData.subscription_data.billing_cycle === "monthly"
                  ? "modal__manage-card modal__manage-card--monthly modal__manage-card--current"
                  : "modal__manage-card modal__manage-card--monthly"
              }
              onClick={() => {
                setIntent("monthly");
                nextSection();
              }}
            >
              {userData.subscription_data.billing_cycle === "monthly" && (
                <div className="modal__manage-card--current-plan">
                  Current Plan
                </div>
              )}
              <div className="modal__manage-card--heading">Monthly</div>
              <div className="modal__manage-card--price-row">
                <span
                  className={
                    userData.subscription_data.billing_cycle === "monthly"
                      ? "modal__manage-card--price-white"
                      : "modal__manage-card--price"
                  }
                >
                  $9.99/mo
                </span>
              </div>

              <div className="modal__manage-card--subscription-benefit">
                Billed Monthly
              </div>
            </div>
            <div
              style={
                userData.subscription_data.billing_cycle === "annual"
                  ? {
                      pointerEvents: "none",
                    }
                  : undefined
              }
              className={
                userData.subscription_data.billing_cycle === "annual"
                  ? "modal__manage-card modal__manage-card--annual modal__manage-card--current"
                  : "modal__manage-card modal__manage-card--annual"
              }
              onClick={() => {
                setIntent("annual");
                nextSection();
              }}
            >
              {userData.subscription_data.billing_cycle === "annual" && (
                <div className="modal__manage-card--current-plan">
                  Current Plan
                </div>
              )}
              {/* <div className="modal__premium-card--annual-banner">
                Most Popular!
              </div> */}
              <div className="modal__manage-card--heading">Annual</div>
              <div className="modal__manage-card--price-row">
                {" "}
                <span className="modal__manage-card--original-price">
                  $9.99/mo
                </span>{" "}
                <span
                  className={
                    userData.subscription_data.billing_cycle === "annual"
                      ? "modal__manage-card--price-white"
                      : "modal__manage-card--price"
                  }
                >
                  $6.99/mo
                </span>
              </div>

              <div className="modal__manage-card--subscription-benefit">
                <br></br>
                $83.88 Billed Annually
              </div>
            </div>
            <div className="modal__manage-card--pay-info">
              <div className="modal__manage-card--heading">Payment Method</div>

              {changeCard ? (
                <Elements stripe={stripeTestPromise}>
                  <PaymentForm
                    planType={null}
                    setChangeCard={setChangeCard}
                    setPaymentMethod={setPaymentMethod}
                  />
                </Elements>
              ) : (
                <>
                  <div className="modal__manage-card--saved-cards">
                    {paymentMethod && (
                      <div className={"modal__manage-card--saved-cards--card"}>
                        <span>
                          <i
                            className={`fa fa-cc-${paymentMethod.card.brand.toLowerCase()}`}
                          ></i>{" "}
                          {paymentMethod.card.brand[0].toUpperCase() +
                            paymentMethod.card.brand.slice(1) +
                            " "}
                          ending in {paymentMethod.card.last4}
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    className="modal__manage-card--add"
                    onClick={() => setChangeCard(true)}
                  >
                    Change payment method
                  </div>
                </>
              )}
            </div>
            <div
              className="modal__manage-card modal__manage-card--cancel"
              onClick={() => {
                setIntent("cancel");
                nextSection();
              }}
            >
              <div className="modal__manage-card--heading">
                Cancel Subscription
              </div>
            </div>
          </div>
        </div>
      )}
      {section === 2 && (
        <div className="modal__payment-options">
          <div
            className="modal__manage-card--go-back"
            style={{ cursor: "pointer", color: "rgb(227, 82, 42)" }}
            onClick={() => previousSection()}
          >
            Go back
          </div>
          <div className="modal__prompt">
            {`${
              intent === "cancel"
                ? `Are you sure you want to cancel your subscription? You will
                still have access for until ${userData.subscription_data.next_billing_date}`
                : intent === "monthly"
                ? "Are you sure you want to change your subscription from annual to monthly?"
                : "Are you sure you want to change your subscription from monthly to annual?"
            }`}
          </div>
          <button
            className="modal__manage-card--confirm-button"
            onClick={() => handleSubmit()}
          >
            Confirm
          </button>
        </div>
      )}
    </div>
  );
};

export default ManageSubscription;
