import { AssignmentDetector } from ".";
import { ParentData, StudentData, Users } from "../Models";

export const CalculateTotalTallies = (user_data: Users) => {
  let totalTallies = user_data.subscription_data.freemium_tallies;

  if (user_data.account_type === "student") {
    totalTallies =
      (user_data.user_data as StudentData).linked_accounts.length > 0
        ? (user_data.user_data as StudentData).linked_accounts[0]
            .subscription_data.freemium_tallies
        : user_data.subscription_data.freemium_tallies;
  }
  return totalTallies;
};

export const CanRead = (user_data: Users, story_title: string, id?: string) => {
  const today = new Date(Date.now());

  // const currentYear = today.getFullYear();
  // const currentMonth = today.getMonth();
  // const currentDay = today.getDate();

  const totalTallies = CalculateTotalTallies(user_data);
  const latestTally = totalTallies[totalTallies.length - 1];

  if (
    user_data.subscription_data.plan_type === "paid" ||
    AssignmentDetector(user_data, id)
  ) {
    return {
      readable: true,
      totalTallies: totalTallies.map((tally) => tally.story_title),
    };
  } else {
    if (totalTallies.length > 0) {
      if (
        today.setHours(0, 0, 0, 0).valueOf() -
          new Date(latestTally.access_date).setHours(0, 0, 0, 0).valueOf() >
        0
      ) {
        return {
          readable: true,
          totalTallies: [totalTallies[totalTallies.length - 1].story_title],
        };
      } else {
        if (latestTally.story_title === story_title) {
          return {
            readable: true,
            totalTallies: [totalTallies[totalTallies.length - 1].story_title],
          };
        } else {
          return {
            readable: false,
            totalTallies: [totalTallies[totalTallies.length - 1].story_title],
          };
        }
      }
    } else {
      return {
        readable: true,
        totalTallies: totalTallies.map((tally) => tally.story_title),
      };
    }
    // if (totalTallies.some((tally) => tally.story_title === story_title)) {
    //   const tallyIndex = totalTallies.findIndex(
    //     (tally) => tally.story_title === story_title
    //   );
    //   const access_date = new Date(totalTallies[tallyIndex].access_date);
    //   if (
    //     access_date.getMonth() === currentMonth ||
    //     (access_date.getMonth() === currentMonth - 1 &&
    //       access_date.getDate() >= currentDay) ||
    //     (access_date.getFullYear() === currentYear - 1 &&
    //       access_date.getMonth() === 12 &&
    //       currentMonth === 1 &&
    //       access_date.getDate() >= currentDay)
    //   ) {
    //     return {
    //       readable: true,
    //       totalTallies: totalTallies.map((tally) => tally.story_title),
    //     };
    //   } else {
    //     if (
    //       totalTallies.filter((val) => val.story_title !== story_title).length <
    //       5
    //     ) {
    //       return {
    //         readable: true,
    //         totalTallies: totalTallies.map((tally) => tally.story_title),
    //       };
    //     } else {
    //       return {
    //         readable: false,
    //         totalTallies: totalTallies.map((tally) => tally.story_title),
    //       };
    //     }
    //   }
    // } else {
    //   if (totalTallies.length < 5) {
    //     return {
    //       readable: true,
    //       totalTallies: totalTallies.map((tally) => tally.story_title),
    //     };
    //   } else {
    //     return {
    //       readable: false,
    //       totalTallies: totalTallies.map((tally) => tally.story_title),
    //     };
    //   }
    // }
  }
};
