import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDisplayData } from "../Hooks/useDisplayData";
import { useUserData } from "../Hooks/useUserData";
import { ParentData, StudentData } from "../Models";
import { generateID } from "../Utils/GenerateID";

const Header: React.FC = () => {
  const { userData, logout, setUser } = useUserData();
  const {
    displayData,
    showLoginModal,
    showRegisterModal,
    showAddChildProfileModal,
    showManageSubscriptionModal,
    showLinkAccountModal,
  } = useDisplayData();
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth > 1023 ? false : true
  );
  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav((prev: boolean) => {
      return prev === true ? false : true;
    });
  };

  const closeNav = () => {
    setShowNav(false);
  };

  window.addEventListener("resize", () => {
    if (isMobile && window.innerWidth > 1023) {
      setIsMobile(false);
      closeNav();
    }
    if (!isMobile && window.innerWidth <= 1023) {
      setIsMobile(true);
      closeNav();
    }
  });

  return (
    <div
      className={
        userData._id && userData._id !== ""
          ? "header__wrapper"
          : "header__wrapper--logged-out"
      }
    >
      <header
        className={
          userData._id && userData._id !== ""
            ? "header"
            : "header header__logged-out"
        }
      >
        <Link to="/">
          <div
            className="header__logo"
            onClick={() =>
              userData.account_type === "student"
                ? setUser(
                    (userData.user_data as StudentData).linked_accounts[0]._id
                  )
                : null
            }
          >
            <span className="header__logo--upper">NO FUSS</span>
            <span className="header__logo--lower">READERS</span>
          </div>
        </Link>
        {!isMobile && (
          <>
            <div className="header__nav-options-container">
              {userData._id &&
                userData._id !== "" &&
                userData.account_type === "parent" &&
                (userData.user_data as ParentData).linked_accounts.map(
                  (linkedAccount) => (
                    <React.Fragment key={generateID()}>
                      <span
                        className="header__nav-option-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => setUser(linkedAccount._id)}
                      >
                        {`${
                          linkedAccount.account_type === "parent"
                            ? "Parent"
                            : linkedAccount.user_data.name
                        }`}
                      </span>
                    </React.Fragment>
                  )
                )}
              {userData._id &&
              userData._id !== "" &&
              userData.account_type === "student" ? (
                (userData.user_data as StudentData).linked_accounts.length >
                0 ? (
                  <span
                    className="header__parent-back"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setUser(
                        (userData.user_data as StudentData).linked_accounts[0]
                          ._id
                      )
                    }
                  >
                    <i className="fa fa-arrow-left header__fa-fa"></i> To Parent
                  </span>
                ) : (
                  <span
                    className="header__nav-option-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => showLinkAccountModal(userData._id)}
                  >
                    Link to Parent
                  </span>
                )
              ) : null}
              {userData._id &&
              userData._id !== "" &&
              userData.account_type === "student" ? (
                <Link to="/tracker">
                  <span className="header__nav-option-2">Tracked Words</span>
                </Link>
              ) : null}

              {/* <span className="header__nav-option">Read Again</span> */}
            </div>

            <div className="header__icons-container">
              {/* <i className="fa fa-search"></i>
              <i className="fa fa-bell"></i> */}

              {userData._id && userData._id !== "" ? (
                <div
                  className="header__user"
                  onMouseEnter={() => setShowUserOptions(true)}
                  onClick={() => setShowUserOptions(!showUserOptions)}
                  onMouseLeave={() => setShowUserOptions(false)}
                >
                  <div className="header__dropdown-wrapper">
                    <div className="header__profile-wrapper">
                      <div className="header__image-wrapper">
                        <img
                          alt="user image"
                          src="https://study-portal.s3.us-east-2.amazonaws.com/monsterIcon.webp"
                          onError={(e: any) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://study-portal.s3.us-east-2.amazonaws.com/monsterIcon.png";
                          }}
                        ></img>
                      </div>
                      <span className="header__user-name">
                        {`${
                          userData.account_type === "parent"
                            ? "Parent"
                            : userData.user_data.name
                        }`}
                      </span>
                    </div>
                    <i className="fa fa-chevron-down header__arrow"></i>
                  </div>
                  {userData._id && userData._id !== "" && showUserOptions && (
                    <div className="header__user-options--dropdown">
                      {(userData.account_type === "parent"
                        ? (userData.user_data as ParentData)
                        : (userData.user_data as StudentData)
                      ).linked_accounts.map((linked_account) => {
                        return (
                          <React.Fragment key={generateID()}>
                            <span
                              className="header__user-options--option"
                              onClick={() => setUser(linked_account._id)}
                            >
                              {`${
                                linked_account.account_type === "parent"
                                  ? "Parent"
                                  : linked_account.user_data.name
                              }`}
                            </span>
                          </React.Fragment>
                        );
                      })}
                      {
                        // (userData.account_type === "parent"
                        //   ? (userData.user_data as ParentData)
                        //   : (userData.user_data as StudentData)
                        // ).linked_accounts.length < 4 ?
                        (userData.account_type === "parent"
                          ? (userData.user_data as ParentData)
                          : (userData.user_data as StudentData)
                        ).linked_accounts.length > 0 ? (
                          <span
                            className="header__user-options--option"
                            onClick={() => {
                              setShowUserOptions(false);
                              showAddChildProfileModal();
                            }}
                          >
                            Add Child Profile
                          </span>
                        ) : (
                          <span
                            className="header__user-options--option"
                            onClick={() => {
                              setShowUserOptions(false);
                              showLinkAccountModal(userData._id);
                            }}
                          >
                            Link to Parent
                          </span>
                        )
                        //  : null
                      }
                      {(userData.account_type === "parent"
                        ? userData.subscription_data.stripe_customer_id &&
                          userData.subscription_data.stripe_customer_id !==
                            "" &&
                          userData.subscription_data.plan_type === "paid"
                        : (userData.user_data as StudentData).linked_accounts
                            .length > 0 &&
                          (userData.user_data as StudentData).linked_accounts[0]
                            .subscription_data.stripe_customer_id &&
                          (userData.user_data as StudentData).linked_accounts[0]
                            .subscription_data.stripe_customer_id !== "" &&
                          (userData.user_data as StudentData).linked_accounts[0]
                            .subscription_data.plan_type === "paid") && (
                        <span
                          className="header__user-options--option"
                          onClick={() => {
                            if (userData.account_type !== "parent") {
                              setUser(
                                (userData.user_data as StudentData)
                                  .linked_accounts[0]._id
                              );
                            }
                            showManageSubscriptionModal();
                          }}
                        >
                          Manage Subscription
                        </span>
                      )}
                      {/* {userData.account_type === "parent" && (
                        <span
                          className="header__user-options--option"
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_SITE_URL}/referrals/${userData._id}`
                            )
                          }
                        >
                          Referrals
                        </span>
                      )} */}
                      <span
                        className="header__user-options--option"
                        onClick={() => logout()}
                      >
                        Logout
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div className="header__user-prompts">
                  <button
                    className="header__user-prompts--login"
                    onClick={() => showLoginModal(true)}
                  >
                    Login
                  </button>

                  <button
                    className="header__user-prompts--register"
                    onClick={() => showRegisterModal(true)}
                  >
                    Create Free Account
                  </button>
                </div>
              )}
            </div>
          </>
        )}
        {isMobile && (
          <div className="header__icons-container">
            {/* <i className="fa fa-search"></i>
          <i className="fa fa-bell"></i> */}

            {userData._id && userData._id !== "" ? (
              <div
                className="header__user"
                onMouseEnter={() => setShowUserOptions(true)}
                onClick={() => setShowUserOptions(!showUserOptions)}
                onMouseLeave={() => setShowUserOptions(false)}
              >
                <div className="header__dropdown-wrapper">
                  <div className="header__profile-wrapper">
                    <div className="header__image-wrapper">
                      <img
                        alt="user image"
                        src="https://study-portal.s3.us-east-2.amazonaws.com/monsterIcon.webp"
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://study-portal.s3.us-east-2.amazonaws.com/monsterIcon.png";
                        }}
                      ></img>
                    </div>
                    <span className="header__user-name">
                      {`${
                        userData.account_type === "parent"
                          ? "Parent"
                          : userData.user_data.name
                      }`}
                    </span>
                  </div>
                  <i className="fa fa-chevron-down header__arrow"></i>
                </div>
                {userData._id && userData._id !== "" && showUserOptions && (
                  <div className="header__user-options--dropdown">
                    {(userData.account_type === "parent"
                      ? (userData.user_data as ParentData)
                      : (userData.user_data as StudentData)
                    ).linked_accounts.map((linked_account) => {
                      return (
                        <React.Fragment key={generateID()}>
                          <span
                            className="header__user-options--option"
                            onClick={() => setUser(linked_account._id)}
                          >
                            {`${
                              linked_account.account_type === "parent"
                                ? "Parent"
                                : linked_account.user_data.name
                            }`}
                          </span>
                        </React.Fragment>
                      );
                    })}
                    {
                      // (userData.account_type === "parent"
                      //   ? (userData.user_data as ParentData)
                      //   : (userData.user_data as StudentData)
                      // ).linked_accounts.length < 4 ?
                      (userData.account_type === "parent"
                        ? (userData.user_data as ParentData)
                        : (userData.user_data as StudentData)
                      ).linked_accounts.length > 0 ? (
                        <span
                          className="header__user-options--option"
                          onClick={() => {
                            setShowUserOptions(false);
                            showAddChildProfileModal();
                          }}
                        >
                          Add Child Profile
                        </span>
                      ) : (
                        <span
                          className="header__user-options--option"
                          onClick={() => {
                            setShowUserOptions(false);
                            showLinkAccountModal(userData._id);
                          }}
                        >
                          Link to Parent
                        </span>
                      )
                      // : null
                    }

                    {userData._id &&
                    userData._id !== "" &&
                    userData.account_type === "student" ? (
                      <Link to="/tracker">
                        <span>Tracked Words</span>
                      </Link>
                    ) : null}

                    {/* <span className="header__user-options--option">
                      Read Again
                    </span> */}
                    {(userData.account_type === "parent"
                      ? userData.subscription_data.stripe_customer_id &&
                        userData.subscription_data.stripe_customer_id !== "" &&
                        userData.subscription_data.plan_type === "paid"
                      : (userData.user_data as StudentData).linked_accounts[0]
                          .subscription_data.stripe_customer_id &&
                        (userData.user_data as StudentData).linked_accounts[0]
                          .subscription_data.stripe_customer_id !== "" &&
                        (userData.user_data as StudentData).linked_accounts[0]
                          .subscription_data.plan_type === "paid") && (
                      <span
                        className="header__user-options--option"
                        onClick={() => {
                          if (userData.account_type !== "parent") {
                            setUser(
                              (userData.user_data as StudentData)
                                .linked_accounts[0]._id
                            );
                          }
                          showManageSubscriptionModal();
                        }}
                      >
                        Manage Subscription
                      </span>
                    )}
                    {/* {userData.account_type === "parent" && (
                      <span
                        className="header__user-options--option"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_SITE_URL}/referrals/${userData._id}`
                          )
                        }
                      >
                        Referrals
                      </span>
                    )} */}
                    <span
                      className="header__user-options--option"
                      onClick={() => logout()}
                    >
                      Logout
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div className="header__user-prompts">
                {/* <button
                  className="header__user-prompts--register"
                  style={{ marginRight: "1rem" }}
                  onClick={() => showLoginModal(true)}
                >
                  Login
                </button> */}
                <button
                  className="header__user-prompts--register"
                  onClick={() => showRegisterModal(true)}
                >
                  Sign Up
                </button>
              </div>
            )}
          </div>
        )}
      </header>
      {isMobile && (
        <section
          className={showNav ? "responsive-nav active" : "responsive-nav"}
        >
          <div className="header__nav-options-container--mobile">
            {userData._id &&
            userData._id !== "" &&
            userData.account_type === "student" ? (
              <Link to="/tracker">
                <span>Tracked Words</span>
              </Link>
            ) : null}

            {/* <span className="header__nav-option">Read Again</span> */}
          </div>

          <div className="header__icons-container">
            {/* <i className="fa fa-search"></i>
            <i className="fa fa-bell"></i> */}

            {userData._id && userData._id !== "" ? (
              <div
                className="header__user"
                onMouseEnter={() => setShowUserOptions(true)}
                onClick={() => setShowUserOptions(!showUserOptions)}
                onMouseLeave={() => setShowUserOptions(false)}
              >
                <div className="header__dropdown-wrapper">
                  <div className="header__profile-wrapper">
                    <div className="header__image-wrapper">
                      <img
                        alt="user image"
                        src="https://study-portal.s3.us-east-2.amazonaws.com/monsterIcon.webp"
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://study-portal.s3.us-east-2.amazonaws.com/monsterIcon.png";
                        }}
                      ></img>
                    </div>
                    <span className="header__user-name">
                      {`${
                        userData.account_type === "parent"
                          ? "Parent"
                          : userData.user_data.name
                      }`}
                    </span>
                  </div>
                  <i className="fa fa-chevron-down header__arrow"></i>
                </div>
                {userData._id && userData._id !== "" && showUserOptions && (
                  <div className="header__user-options--dropdown">
                    {(userData.account_type === "parent"
                      ? (userData.user_data as ParentData)
                      : (userData.user_data as StudentData)
                    ).linked_accounts.map((linked_account) => {
                      return (
                        <React.Fragment key={generateID()}>
                          <span
                            className="header__user-options--option"
                            onClick={() => setUser(linked_account._id)}
                          >
                            {`${
                              linked_account.account_type === "parent"
                                ? "Parent"
                                : linked_account.user_data.name
                            }`}
                          </span>
                        </React.Fragment>
                      );
                    })}
                    {
                      // (userData.account_type === "parent"
                      //   ? (userData.user_data as ParentData)
                      //   : (userData.user_data as StudentData)
                      // ).linked_accounts.length < 4 ?
                      (userData.account_type === "parent"
                        ? (userData.user_data as ParentData)
                        : (userData.user_data as StudentData)
                      ).linked_accounts.length > 0 ? (
                        <span
                          className="header__user-options--option"
                          onClick={() => {
                            setShowUserOptions(false);
                            showAddChildProfileModal();
                          }}
                        >
                          Add Child Profile
                        </span>
                      ) : (
                        <span
                          className="header__user-options--option"
                          onClick={() => {
                            setShowUserOptions(false);
                            showLinkAccountModal(userData._id);
                          }}
                        >
                          Link to Parent
                        </span>
                      )
                      // : null
                    }
                    {(userData.account_type === "parent"
                      ? userData.subscription_data.stripe_customer_id &&
                        userData.subscription_data.stripe_customer_id !== "" &&
                        userData.subscription_data.plan_type === "paid"
                      : (userData.user_data as StudentData).linked_accounts[0]
                          .subscription_data.stripe_customer_id &&
                        (userData.user_data as StudentData).linked_accounts[0]
                          .subscription_data.stripe_customer_id !== "" &&
                        (userData.user_data as StudentData).linked_accounts[0]
                          .subscription_data.plan_type === "paid") && (
                      <span
                        className="header__user-options--option"
                        onClick={() => {
                          if (userData.account_type !== "parent") {
                            setUser(
                              (userData.user_data as StudentData)
                                .linked_accounts[0]._id
                            );
                          }
                          showManageSubscriptionModal();
                        }}
                      >
                        Manage Subscription
                      </span>
                    )}
                    {/* {userData.account_type === "parent" && (
                      <span
                        className="header__user-options--option"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_SITE_URL}/referrals/${userData._id}`
                          )
                        }
                      >
                        Referrals
                      </span>
                    )} */}
                    <span
                      className="header__user-options--option"
                      onClick={() => logout()}
                    >
                      Logout
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div className="header__user-prompts">
                {/* <button
                  className="header__user-prompts--register"
                  style={{ marginRight: "1rem" }}
                  onClick={() => showLoginModal(true)}
                >
                  Login
                </button> */}
                <button
                  className="header__user-prompts--register"
                  onClick={() => showRegisterModal(true)}
                >
                  Sign Up
                </button>
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default Header;
