import { GetBaseURL } from "./URLUtils";

export const SendEmail = async (
  message: string,
  email: string,
  subject: string
) => {
  const POST_REQUEST = {
    email: email,
    message: message,
    subject: subject,
  };

  const request = await fetch(
    `${GetBaseURL()}/api/emails/send?key=${
      process.env.REACT_APP_PERSONAL_AUTH_KEY
    }`,
    {
      method: "POST",
      body: JSON.stringify(POST_REQUEST),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const response = await request.json();
  if (request.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const SendHTMLEmail = async (
  message: string,
  email: string,
  subject: string
) => {
  const POST_REQUEST = {
    email: email,
    message: message,
    subject: subject,
  };

  const request = await fetch(
    `${GetBaseURL()}/api/emails/send_html?key=${process.env.REACT_APP_API_KEY}`,
    {
      method: "POST",
      body: JSON.stringify(POST_REQUEST),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const response = await request.json();
  if (request.status === 200) {
    return true;
  } else {
    return false;
  }
};
