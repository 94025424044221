import React, { useState, useEffect } from "react";
import { StudentAnswers } from "../Components/Partials/Questions";
import { Story, StudentData, TrackedWords } from "../Models";
export interface DisplayContextProps {
  displayData: Display;
  setDisplayData: (displayData: any) => void;
}

export type Display = {
  showModal: boolean;
  login?: boolean;
  register?: boolean;
  addChildProfile?: boolean;
  addClassroom?: boolean;
  inviteTeacher?: boolean;
  stripe?: boolean;
  signIntoClass?: boolean;
  fromBanner?: boolean;
  fromLimit?: boolean;
  manageSubscription?: boolean;
  childProfiles?: boolean;
  childProfileType?: "play" | "read";
  nextStory?: boolean;
  randomStory?: Story;
  score?: string;
  studentAnswers?: StudentAnswers;
  // redirectToStory?: boolean;
  // storyID?: string;
  redirectToHome?: boolean;
  trackedWords?: boolean;
  storiesRead?: boolean;
  recentlyLearned?: boolean;
  childData?: StudentData;
  child_id?: string;
  changeReadingLevel?: boolean;
  changeGrade?: boolean;
  linkAccount?: boolean;
  deleteProfile?: boolean;
  homepageScrollHeight?: number;
  initialReload?: boolean;
  scrollToStories?: boolean;
  previewStory?: boolean;
  story_details?: Story;
  games?: boolean;
  comingSoon?: boolean;
  comingSoonType?: string;
  share?: boolean;
  title?: string;
};

const DisplayContext = React.createContext({} as DisplayContextProps);

const DisplayContextProvider = (props: any) => {
  const [displayData, setDisplayData] = useState<Display>({ showModal: false });

  const providerValue: DisplayContextProps = {
    displayData,
    setDisplayData,
  };

  return (
    <DisplayContext.Provider value={providerValue}>
      {props.children}
    </DisplayContext.Provider>
  );
};

export { DisplayContext, DisplayContextProvider };
