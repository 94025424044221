import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { useStories } from "../Hooks/useStories";
import StoryPageText from "../Components/Partials/StoryPageText";
import { StylesContextProvider } from "../Context/StylesContext";
import { Phonics, Story, StoryPage, WordPhonics } from "../Models/Stories";
import { generateID } from "../Utils/GenerateID";
import { useUserData } from "../Hooks/useUserData";
import { StudentData, TrackedWords } from "../Models";
import { GetBaseURL } from "../Utils";

interface MatchParams {
  storyID: string;
}

interface ComponentProps extends RouteComponentProps<MatchParams> {}

const EditStoryImages: React.FC<ComponentProps> = ({ match }) => {
  const { storyData, UpdateStories } = useStories();
  const { userData } = useUserData();
  const [storyDetails, setStoryDetails] = useState<Story | undefined>();
  const [newStoryDetails, setNewStoryDetails] = useState<Story>({
    title: "",
    cover: "",
    pages: [],
    level: "",
    tags: [],
    priority_tag: "",
    questions: [],
    associated_words: [],
  });
  const tagsRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState({
    isActive: false,
    type: "error",
    message: "",
  });

  useEffect(() => {
    let foundStory = storyData.stories.find(
      (story) => story._id === match.params.storyID
    );
    if (foundStory) {
      const sortedPages = foundStory.pages.sort(
        (first, second) => first.page_number - second.page_number
      );
      foundStory.pages = sortedPages;
      setStoryDetails(foundStory);
      setNewStoryDetails(foundStory);
    }
  }, [storyData]);

  const updateAllImages = async () => {
    const request = await fetch(
      `${GetBaseURL()}/api/stories/update/${match.params.storyID}?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ newStoryDetails }),
      }
    );
    if (
      (document.getElementById("cover-image-webp") as HTMLInputElement)
        .value !== ""
    ) {
      submitFile(
        document.getElementById("cover-image-webp") as HTMLInputElement
      );
    }
    if (
      (document.getElementById("cover-image-jpg") as HTMLInputElement).value !==
      ""
    ) {
      submitFile(
        document.getElementById("cover-image-jpg") as HTMLInputElement
      );
    }
    if (
      (document.getElementById("cover-image-png") as HTMLInputElement).value !==
      ""
    ) {
      submitFile(
        document.getElementById("cover-image-png") as HTMLInputElement
      );
    }
    for (let i = 1; i <= newStoryDetails.pages.length; i++) {
      if (
        (document.getElementById(`page-${i}-image-webp`) as HTMLInputElement)
          .value !== ""
      ) {
        await submitFile(
          document.getElementById(`page-${i}-image-webp`) as HTMLInputElement
        );
      }
      if (
        (document.getElementById(`page-${i}-image-jpg`) as HTMLInputElement)
          .value !== ""
      ) {
        await submitFile(
          document.getElementById(`page-${i}-image-jpg`) as HTMLInputElement
        );
      }
      if (
        (document.getElementById(`page-${i}-image-png`) as HTMLInputElement)
          .value !== ""
      ) {
        await submitFile(
          document.getElementById(`page-${i}-image-png`) as HTMLInputElement
        );
      }
    }
    const response = await request.json();

    request.status === 200
      ? setError({ type: "success", isActive: true, message: response.message })
      : setError({ type: "error", isActive: true, message: response.message });

    UpdateStories();
  };

  const submitFile = async (input: HTMLInputElement) => {
    if (input) {
      if (input.files === null || input.files.length === 0) {
        console.log("No file submitted");
      } else {
        const FD = new FormData();
        if (input.id.includes("files")) {
          for (let i = 0; i < input.files.length; i++) {
            FD.append(
              i === 0 ? newStoryDetails.title : `${newStoryDetails.title} ${i}`,
              input.files[i]
            );
            console.log(input.files[i]);
          }
        } else {
          FD.append(
            input.id.includes("cover-image")
              ? newStoryDetails.title
              : `${newStoryDetails.title} ${input.id
                  .replace("page-", "")
                  .replace("-image-webp", "")
                  .replace("-image-jpg", "")
                  .replace("-image-png", "")}`,
            input.files[0]
          );
        }

        const splitFileName = input.files[0].name.split(".");
        const URL = `${GetBaseURL()}/api/stories/addStoryDocument/${
          splitFileName[splitFileName.length - 1]
        }?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`;

        const request = await fetch(URL, {
          body: FD,
          method: "POST",
        });
        const response = await request.json();
        request.status === 200
          ? setError({
              isActive: true,
              type: "success",
              message: response.message,
            })
          : setError({
              isActive: true,
              type: "error",
              message: response.message,
            });
      }
    } else {
      console.log("Not current");
    }
  };

  return userData.user_data.username &&
    userData.user_data.username !== "" &&
    `${process.env.REACT_APP_NFT_ADMINS}`.includes(
      userData.user_data.username
    ) ? (
    storyDetails ? (
      <div className="edit-story">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "2rem",
          }}
        >
          <input
            style={{ width: "auto" }}
            type="file"
            id="webp-files"
            multiple
          />
          <button
            className="edit-story__submit"
            onClick={() =>
              submitFile(
                document.getElementById("webp-files") as HTMLInputElement
              )
            }
          >
            Submit multiple WEBP Images
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "2rem",
          }}
        >
          <input
            style={{ width: "auto" }}
            type="file"
            id="jpg-files"
            multiple
          />
          <button
            className="edit-story__submit"
            onClick={() =>
              submitFile(
                document.getElementById("jpg-files") as HTMLInputElement
              )
            }
          >
            Submit multiple JPG Images
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "2rem",
          }}
        >
          <input
            style={{ width: "auto" }}
            type="file"
            id="png-files"
            multiple
          />
          <button
            className="edit-story__submit"
            onClick={() =>
              submitFile(
                document.getElementById("png-files") as HTMLInputElement
              )
            }
          >
            Submit multiple PNG Images
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "2rem",
          }}
        >
          <div style={{ marginRight: "1rem" }}>Cover:</div>
          <div className="edit-story__image-upload-container">
            <span>WEBP</span>
            <input
              type="file"
              id={`cover-image-webp`}
              style={{ width: "auto" }}
            />
            <img
              style={{ width: "100px" }}
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${storyDetails.title
                .split(" ")
                .join("")}.webp`}
              alt="No WEBP"
            />
            <div
              className="edit-story__submit"
              onClick={() =>
                submitFile(
                  document.getElementById(
                    `cover-image-webp`
                  ) as HTMLInputElement
                )
              }
            >
              Submit WEBP
            </div>
          </div>
          <div className="edit-story__image-upload-container">
            <span>JPG</span>
            <input
              type="file"
              id={`cover-image-jpg`}
              style={{ width: "auto" }}
            />
            <img
              style={{ width: "100px" }}
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${storyDetails.title
                .split(" ")
                .join("")}.jpg`}
              alt="No JPG"
            />
            <div
              className="edit-story__submit"
              onClick={() =>
                submitFile(
                  document.getElementById(`cover-image-jpg`) as HTMLInputElement
                )
              }
            >
              Submit JPG
            </div>
          </div>
          <div className="edit-story__image-upload-container">
            <span>PNG</span>
            <input
              type="file"
              id={`cover-image-png`}
              style={{ width: "auto" }}
            />
            <img
              style={{ width: "100px" }}
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${storyDetails.title
                .split(" ")
                .join("")}.png`}
              alt="No PNG"
            />
            <div
              className="edit-story__submit"
              onClick={() =>
                submitFile(
                  document.getElementById(`cover-image-png`) as HTMLInputElement
                )
              }
            >
              Submit PNG
            </div>
          </div>
        </div>
        {storyDetails.pages.map((page) => (
          <React.Fragment key={generateID()}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "2rem",
              }}
            >
              <div style={{ marginRight: "1rem" }}>
                Page {page.page_number}:
              </div>
              <div className="edit-story__image-upload-container">
                <span>WEBP</span>
                <input
                  type="file"
                  id={`page-${page.page_number}-image-webp`}
                  style={{ width: "auto" }}
                />
                <img
                  style={{ width: "100px" }}
                  src={`${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${storyDetails.title.split(" ").join("")}${
                    page.page_number
                  }.webp`}
                  alt="No WEBP"
                />
                <div
                  className="edit-story__submit"
                  onClick={() =>
                    submitFile(
                      document.getElementById(
                        `page-${page.page_number}-image-webp`
                      ) as HTMLInputElement
                    )
                  }
                >
                  Submit WEBP
                </div>
              </div>
              <div className="edit-story__image-upload-container">
                <span>JPG</span>
                <input
                  type="file"
                  id={`page-${page.page_number}-image-jpg`}
                  style={{ width: "auto" }}
                />
                <img
                  style={{ width: "100px" }}
                  src={`${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${storyDetails.title.split(" ").join("")}${
                    page.page_number
                  }.jpg`}
                  alt="No JPG"
                />
                <div
                  className="edit-story__submit"
                  onClick={() =>
                    submitFile(
                      document.getElementById(
                        `page-${page.page_number}-image-jpg`
                      ) as HTMLInputElement
                    )
                  }
                >
                  Submit JPG
                </div>
              </div>
              <div className="edit-story__image-upload-container">
                <span>PNG</span>
                <input
                  type="file"
                  id={`page-${page.page_number}-image-png`}
                  style={{ width: "auto" }}
                />
                <img
                  style={{ width: "100px" }}
                  src={`${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${storyDetails.title.split(" ").join("")}${
                    page.page_number
                  }.png`}
                  alt="No PNG"
                />
                <div
                  className="edit-story__submit"
                  onClick={() =>
                    submitFile(
                      document.getElementById(
                        `page-${page.page_number}-image-png`
                      ) as HTMLInputElement
                    )
                  }
                >
                  Submit PNG
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}

        {error.isActive && <div>{error.message}</div>}
        <div className="edit-story__submit" onClick={() => updateAllImages()}>
          Submit All Images
        </div>
      </div>
    ) : (
      <div style={{ color: "white", display: "flex", flexDirection: "column" }}>
        <span>Unable to locate the story to edit.</span>
      </div>
    )
  ) : (
    <div style={{ color: "white", display: "flex", flexDirection: "column" }}>
      <span>Unauthorized access.</span>
    </div>
  );
};

export default EditStoryImages;
