import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import Header from "./Components/Header";
import StoryComponent from "./Pages/StoryComponent";
import { StoryContextProvider } from "./Context/StoryContext";
import { StylesContextProvider } from "./Context/StylesContext";
import { UserContextProvider } from "./Context/UserContext";
import Home from "./Pages/Home";
import ReadingPortal from "./Pages/ReadingPortal";
import Characters from "./Pages/Characters";
import { DisplayContextProvider } from "./Context/DisplayContext";
import MyList from "./Pages/MyList";
import Tracker from "./Pages/Tracker";
import EditStoryImages from "./Pages/EditStoryImages";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";
import AssociatedWords from "./Pages/AssociatedWords";
import SuccessfulSubscription from "./Pages/SuccessfulSubscription";
import Verification from "./Pages/Verification";
import TicTacToe from "./Pages/TicTacToe";
import MemoryGame from "./Pages/MemoryGame";
import ReferralsPage from "./Pages/ReferralsPage";
import ResetPassword from "./Pages/ResetPassword";
import MoonRockVerification from "./Pages/MoonRockVerification";
import RollAndRead from "./Pages/RollAndRead";

function App() {
  return (
    <div className="App">
      <DisplayContextProvider>
        <UserContextProvider>
          <StoryContextProvider>
            {/* <Route exact path="/" component={Home} /> */}
            <Route exact path="/" component={ReadingPortal} />
            <Route exact path="/characters" component={Characters} />
            <Route exact path="/my-list" component={MyList} />
            <Route exact path="/tracker" component={Tracker} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <Route exact path="/success" component={SuccessfulSubscription} />
            <Route exact path="/tictactoe" component={TicTacToe} />
            <Route exact path="/memorygame" component={MemoryGame} />
            <Route exact path="/rollandread" component={RollAndRead} />
            <Route path="/story/:storyID" component={StoryComponent} />
            <Route path="/edit-images/:storyID" component={EditStoryImages} />
            <Route path="/associatedWords/:word" component={AssociatedWords} />
            <Route path="/verify/:userID" component={Verification} />
            <Route path="/referrals/:userID" component={ReferralsPage} />
            <Route path="/ref/:userID" component={ReadingPortal} />
            <Route path="/reset/:encryptedEmail" component={ResetPassword} />
            <Route
              path="/moonrock-verify/:studentID"
              component={MoonRockVerification}
            />
          </StoryContextProvider>
        </UserContextProvider>
      </DisplayContextProvider>
    </div>
  );
}

export default App;
