export const Grades = [
  "Pre-K",
  "KG",
  "Gr 1",
  "Gr 2",
  "Gr 3",
  "Gr 4",
  "Gr 5",
  // "Gr 6",
  // "Gr 7",
  // "Gr 8",
];
