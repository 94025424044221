import { randomBytes } from "crypto";
import React from "react";
import { diceRoll } from "./DiceHandler.js";

const Dice: React.FC = () => {
  const handleDiceRoll = () => {
    let randNum = Math.floor(Math.random() * 6 + 1);

    diceRoll(randNum);
    console.log(randNum);
  };

  return (
    <>
      <div className="panel">
        <div className="dice">
          <div className="side one">
            <span className="dot"></span>
          </div>
          <div className="side two">
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
          <div className="side three">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
          <div className="side four">
            <div className="kolona">
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
            <div className="kolona">
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
          <div className="side five">
            <div className="kolona">
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
            <div className="kolona">
              <span className="dot"></span>
            </div>
            <div className="kolona">
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
          <div className="side six">
            <div className="kolona">
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
            <div className="kolona">
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
            <div className="kolona">
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
        </div>
      </div>

      <div className="custom">
        {/* <h2>Dice Color</h2>
        <input
          className="picker"
          id="dice-color"
          type="color"
          value="#000000"
        />
        <h2>Dot Color</h2>
        <input className="picker" id="dot-color" type="color" /> */}
        <button id="spin" onClick={handleDiceRoll}>
          Roll
        </button>
      </div>
    </>
  );
};
export default Dice;
