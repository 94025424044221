import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDisplayData } from "../../Hooks/useDisplayData";
import { useStories } from "../../Hooks/useStories";
import { useUserData } from "../../Hooks/useUserData";
import { ParentData } from "../../Models";
import { AssignmentDetector } from "../../Utils";
import { CanRead } from "../../Utils/Readability";

export type StoryCardProps = {
  title: string;
  level: string;
  tags: string[];
  wordCount: number;
  cover: string;
  id?: string;
  priority_tag: string;
  onload?: (title: string) => void;
  stopLoad?: boolean;
  onclick?: () => void;
  unclickable?: boolean;
  ignoreCover?: boolean;
};

const StoryCard: React.FC<StoryCardProps> = ({
  title,
  level,
  tags,
  wordCount,
  cover,
  id,
  priority_tag,
  onload,
  stopLoad,
  onclick,
  unclickable,
  ignoreCover,
}) => {
  const { userData, updateSubscriptionData } = useUserData();
  const {
    displayData,
    hideModal,
    showStripeModal,
    showRegisterModal,
    showPreviewStoryModal,
    // redirectToStory,
    setHomePageScrollHeight,
    setInitialReload,
    showShareModal,
  } = useDisplayData();
  const { SetCurrentStoryID, storyData } = useStories();
  const [canRead, setCanRead] = useState(false);
  const [unlocked, setUnlocked] = useState(false);
  const [extensionsTried, setExtensionsTried] = useState({
    jpg: false,
    png: false,
    jpeg: false,
  });
  const [correctExtension, setCorrectExtension] = useState("");
  const [joinedTags, setJoinedTags] = useState(tags.join(" - "));
  const [isInStory, setIsInStory] = useState(false);
  // const [hoverReadingLevel, setHoverReadingLevel] = useState(false);

  useEffect(() => {
    window.location.href.includes("story")
      ? setIsInStory(true)
      : setIsInStory(false);
  }, []);

  useEffect(() => {
    if (userData._id && userData._id !== "") {
      const readability = CanRead(userData, title, id);
      setCanRead(readability.readable);
      setUnlocked(readability.totalTallies.includes(title));
    }
  }, [userData]);

  useEffect(() => {
    let newJoinedTags = joinedTags.split(" - ");
    if (priority_tag) {
      newJoinedTags.splice(newJoinedTags.indexOf(priority_tag), 1);
      newJoinedTags.unshift(priority_tag);
    }

    if (newJoinedTags.join(" - ").length > 30) {
      newJoinedTags.pop();
    }

    setJoinedTags(newJoinedTags.join(" - "));
  }, [joinedTags]);

  useEffect(() => {
    if (onload && stopLoad === false && !ignoreCover) {
      const image = document.getElementById("story-cover");
      image?.addEventListener("load", () => {
        onload(title);
      });
      const img = new Image();
      img.src = image?.getAttribute("src")!;
      if (img.complete) {
        onload(title);
      }
    }
  }, []);

  return localStorage.getItem("id") || (userData._id && userData._id !== "") ? (
    canRead ? (
      userData.subscription_data.plan_type === "free" &&
      !CanRead(userData, title, id).totalTallies.includes(title) ? (
        <a className="story-card-wrapper" style={{ cursor: "pointer" }}>
          <article
            style={isInStory ? { width: "140px" } : undefined}
            className={
              isInStory ? "story-card story-card--center" : "story-card"
            }
            onClick={() => {
              if (AssignmentDetector(userData, id) !== undefined) {
                window.open(`${process.env.REACT_APP_SITE_URL}/story/${id}`);
              } else {
                showPreviewStoryModal(
                  storyData.stories.find((story) => story._id === id),
                  false,
                  false
                );
              }
            }}
          >
            {AssignmentDetector(userData, id) !== undefined && (
              <div className="story-card__unlocked-ribbon">Assignment</div>
            )}
            {/* //!share button */}
            <div
              className={"story-card--share"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                showShareModal(title);
              }}
            >
              <i className="fa fa-share-alt" />
            </div>
            {!ignoreCover && (
              <div
                style={isInStory ? { height: "241px" } : undefined}
                className="story-card__image"
              >
                <img
                  id="story-cover"
                  className={isInStory ? "story-card__image--img" : undefined}
                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${cover}`}
                  alt="Story Cover"
                  loading={onload ? "eager" : "lazy"}
                  onError={(e: any) => {
                    if (!extensionsTried.jpg || correctExtension === "jpg") {
                      e.target.src = `${
                        process.env.REACT_APP_AWS_BUCKET_URL
                      }/${cover.replace("webp", "jpg")}`;
                      setExtensionsTried((prev: any) => ({
                        ...prev,
                        jpg: true,
                      }));
                      setCorrectExtension("jpg");
                    } else if (
                      !extensionsTried.png ||
                      correctExtension === "png"
                    ) {
                      e.target.src = `${
                        process.env.REACT_APP_AWS_BUCKET_URL
                      }/${cover.replace("webp", "png")}`;
                      setExtensionsTried((prev: any) => ({
                        ...prev,
                        png: true,
                      }));
                      setCorrectExtension("png");
                    } else if (
                      !extensionsTried.jpeg ||
                      correctExtension === "jpeg"
                    ) {
                      e.target.src = `${
                        process.env.REACT_APP_AWS_BUCKET_URL
                      }/${cover.replace("webp", "jpeg")}`;
                      setExtensionsTried((prev: any) => ({
                        ...prev,
                        jpeg: true,
                      }));
                      setCorrectExtension("jpeg");
                    }
                  }}
                />
              </div>
            )}
            <div
              className={
                isInStory
                  ? "story-card__content-wrapper story-card__content-wrapper--box-shadow"
                  : "story-card__content-wrapper"
              }
              style={
                isInStory
                  ? { height: "50px" }
                  : ignoreCover
                  ? { boxShadow: "3px 3px 10px 0px", borderRadius: "8px" }
                  : undefined
              }
            >
              <div className="story-card__first-row">
                <h2
                  className="story-card__title"
                  style={isInStory ? { fontSize: "0.9rem" } : undefined}
                >
                  {title}
                </h2>
                <div
                  className="story-card__level-container"
                  // onMouseEnter={() => setHoverReadingLevel(true)}
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   e.stopPropagation();
                  //   setHoverReadingLevel(true);
                  // }}
                  // onMouseLeave={() => setHoverReadingLevel(false)}
                >
                  <span className="story-card__level-text">LEVEL</span>
                  <span className={`level-${level} story-card__level-letter`}>
                    {level}
                  </span>
                  {/* {hoverReadingLevel && (
                <a
                  href="https://www.sos.wa.gov/_assets/library/libraries/firsttuesdays/readingchart.pdf"
                  target="_blank"
                  className="story-card__reading-level-blurb"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {level.charCodeAt(0) < 68
                    ? "Kindergarten"
                    : level.charCodeAt(0) === 68
                    ? "KG-Grade 1"
                    : level.charCodeAt(0) < 75
                    ? "Grade 1"
                    : level.charCodeAt(0) < 78
                    ? "Grade 2"
                    : level.charCodeAt(0) < 81
                    ? "Grade 3"
                    : level.charCodeAt(0) < 84
                    ? "Grade 4"
                    : level.charCodeAt(0) < 87
                    ? "Grade 5"
                    : level.charCodeAt(0) < 90
                    ? "Grade 6"
                    : "Grades 7-8"}
                </a>
              )} */}
                </div>
              </div>
              {!isInStory && (
                <div className="story-card__second-row">
                  <span className="story-card__word-count">
                    {wordCount} words
                  </span>
                  <span
                    className="story-card__tags"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {level.charCodeAt(0) < 68
                      ? "Kindergarten"
                      : level.charCodeAt(0) === 68
                      ? "KG-Grade 1"
                      : level.charCodeAt(0) < 75
                      ? "Grade 1"
                      : level.charCodeAt(0) < 78
                      ? "Grade 2"
                      : level.charCodeAt(0) < 81
                      ? "Grade 3"
                      : level.charCodeAt(0) < 84
                      ? "Grade 4"
                      : level.charCodeAt(0) < 87
                      ? "Grade 5"
                      : level.charCodeAt(0) < 90
                      ? "Grade 6"
                      : "Grades 7-8"}
                  </span>
                </div>
              )}
            </div>
          </article>
        </a>
      ) : (
        <Link
          style={
            (displayData.addChildProfile &&
              userData.account_type === "parent" &&
              (userData.user_data as ParentData).linked_accounts.length ===
                0) ||
            unclickable
              ? { pointerEvents: "none" }
              : isInStory
              ? { margin: "unset" }
              : undefined
          }
          to={`/story/${id}`}
          className="story-card-wrapper"
        >
          <article
            style={isInStory ? { width: "140px" } : undefined}
            className={
              isInStory ? "story-card story-card--center" : "story-card"
            }
            onClick={() => {
              onclick && onclick();
              updateSubscriptionData(title);
              setHomePageScrollHeight(window.scrollY);
              setInitialReload(true);
              hideModal();
            }}
          >
            {AssignmentDetector(userData, id) !== undefined ? (
              <div className="story-card__unlocked-ribbon">Assignment</div>
            ) : unlocked && userData.subscription_data.plan_type === "free" ? (
              <div className="story-card__unlocked-ribbon">Unlocked</div>
            ) : null}
            {/* //!share button */}
            <div
              className={"story-card--share"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                showShareModal(title);
              }}
            >
              <i className="fa fa-share-alt" />
            </div>
            {!ignoreCover && (
              <div
                style={isInStory ? { height: "241px" } : undefined}
                className="story-card__image"
              >
                <img
                  id="story-cover"
                  className={isInStory ? "story-card__image--img" : undefined}
                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${cover}`}
                  alt="Story Cover"
                  loading={onload ? "eager" : "lazy"}
                  onError={(e: any) => {
                    if (!extensionsTried.jpg || correctExtension === "jpg") {
                      e.target.src = `${
                        process.env.REACT_APP_AWS_BUCKET_URL
                      }/${cover.replace("webp", "jpg")}`;
                      setExtensionsTried((prev: any) => ({
                        ...prev,
                        jpg: true,
                      }));
                      setCorrectExtension("jpg");
                    } else if (
                      !extensionsTried.png ||
                      correctExtension === "png"
                    ) {
                      e.target.src = `${
                        process.env.REACT_APP_AWS_BUCKET_URL
                      }/${cover.replace("webp", "png")}`;
                      setExtensionsTried((prev: any) => ({
                        ...prev,
                        png: true,
                      }));
                      setCorrectExtension("png");
                    } else if (
                      !extensionsTried.jpeg ||
                      correctExtension === "jpeg"
                    ) {
                      e.target.src = `${
                        process.env.REACT_APP_AWS_BUCKET_URL
                      }/${cover.replace("webp", "jpeg")}`;
                      setExtensionsTried((prev: any) => ({
                        ...prev,
                        jpeg: true,
                      }));
                      setCorrectExtension("jpeg");
                    }
                  }}
                />
              </div>
            )}
            <div
              className={
                isInStory
                  ? "story-card__content-wrapper story-card__content-wrapper--box-shadow"
                  : "story-card__content-wrapper"
              }
              style={
                isInStory
                  ? { height: "50px" }
                  : ignoreCover
                  ? { boxShadow: "3px 3px 10px 0px", borderRadius: "8px" }
                  : undefined
              }
            >
              <div className="story-card__first-row">
                <h2
                  className="story-card__title"
                  style={isInStory ? { fontSize: "0.9rem" } : undefined}
                >
                  {title}
                </h2>
                <div
                  className="story-card__level-container"
                  // onMouseEnter={() => setHoverReadingLevel(true)}
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   e.stopPropagation();
                  //   setHoverReadingLevel(true);
                  // }}
                  // onMouseLeave={() => setHoverReadingLevel(false)}
                >
                  <span className="story-card__level-text">LEVEL</span>
                  <span className={`level-${level} story-card__level-letter`}>
                    {level}
                  </span>
                  {/* {hoverReadingLevel && (
                  <a
                    href="https://www.sos.wa.gov/_assets/library/libraries/firsttuesdays/readingchart.pdf"
                    target="_blank"
                    className="story-card__reading-level-blurb"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {level.charCodeAt(0) < 68
                      ? "Kindergarten"
                      : level.charCodeAt(0) === 68
                      ? "KG-Grade 1"
                      : level.charCodeAt(0) < 75
                      ? "Grade 1"
                      : level.charCodeAt(0) < 78
                      ? "Grade 2"
                      : level.charCodeAt(0) < 81
                      ? "Grade 3"
                      : level.charCodeAt(0) < 84
                      ? "Grade 4"
                      : level.charCodeAt(0) < 87
                      ? "Grade 5"
                      : level.charCodeAt(0) < 90
                      ? "Grade 6"
                      : "Grades 7-8"}
                  </a>
                )} */}
                </div>
              </div>
              {!isInStory && (
                <div className="story-card__second-row">
                  <span className="story-card__word-count">
                    {wordCount} words
                  </span>
                  <span
                    className="story-card__tags"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {level.charCodeAt(0) < 68
                      ? "Kindergarten"
                      : level.charCodeAt(0) === 68
                      ? "KG-Grade 1"
                      : level.charCodeAt(0) < 75
                      ? "Grade 1"
                      : level.charCodeAt(0) < 78
                      ? "Grade 2"
                      : level.charCodeAt(0) < 81
                      ? "Grade 3"
                      : level.charCodeAt(0) < 84
                      ? "Grade 4"
                      : level.charCodeAt(0) < 87
                      ? "Grade 5"
                      : level.charCodeAt(0) < 90
                      ? "Grade 6"
                      : "Grades 7-8"}
                  </span>
                </div>
              )}
            </div>
          </article>
        </Link>
      )
    ) : (
      <a className="story-card-wrapper">
        <article
          style={
            (displayData.addChildProfile &&
              userData.account_type === "parent" &&
              (userData.user_data as ParentData).linked_accounts.length ===
                0) ||
            unclickable
              ? { pointerEvents: "none" }
              : { cursor: "pointer" }
          }
          className={isInStory ? "story-card story-card--center" : "story-card"}
          onClick={() => {
            if (AssignmentDetector(userData, id) !== undefined) {
              window.open(`${process.env.REACT_APP_SITE_URL}/story/${id}`);
            } else {
              showPreviewStoryModal(
                storyData.stories.find((story) => story._id === id),
                true,
                false
              );
            }
            // showStripeModal({
            //   fromBanner: false,
            //   fromLimit: true,
            //   story_details: storyData.stories.find(
            //     (story) => story._id === id
            //   ),
            // })
          }}
        >
          {AssignmentDetector(userData, id) !== undefined && (
            <div className="story-card__unlocked-ribbon">Assignment</div>
          )}
          {/* //!share button */}
          <div
            className={"story-card--share"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              showShareModal(title);
            }}
          >
            <i className="fa fa-share-alt" />
          </div>
          {!ignoreCover && (
            <div className="story-card__image">
              <img
                id="story-cover"
                src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${cover}`}
                className={isInStory ? "story-card__image--img" : undefined}
                alt="Story Cover"
                loading={onload ? "eager" : "lazy"}
                onError={(e: any) => {
                  if (!extensionsTried.jpg || correctExtension === "jpg") {
                    e.target.src = `${
                      process.env.REACT_APP_AWS_BUCKET_URL
                    }/${cover.replace("webp", "jpg")}`;
                    setExtensionsTried((prev: any) => ({ ...prev, jpg: true }));
                    setCorrectExtension("jpg");
                  } else if (
                    !extensionsTried.png ||
                    correctExtension === "png"
                  ) {
                    e.target.src = `${
                      process.env.REACT_APP_AWS_BUCKET_URL
                    }/${cover.replace("webp", "png")}`;
                    setExtensionsTried((prev: any) => ({ ...prev, png: true }));
                    setCorrectExtension("png");
                  } else if (
                    !extensionsTried.jpeg ||
                    correctExtension === "jpeg"
                  ) {
                    e.target.src = `${
                      process.env.REACT_APP_AWS_BUCKET_URL
                    }/${cover.replace("webp", "jpeg")}`;
                    setExtensionsTried((prev: any) => ({
                      ...prev,
                      jpeg: true,
                    }));
                    setCorrectExtension("jpeg");
                  }
                }}
              />
            </div>
          )}
          <div
            className={
              isInStory
                ? "story-card__content-wrapper story-card__content-wrapper--box-shadow"
                : "story-card__content-wrapper"
            }
          >
            <div className="story-card__first-row">
              <h2 className="story-card__title">{title}</h2>
              <div
                className="story-card__level-container"
                // onMouseEnter={() => setHoverReadingLevel(true)}
                // onClick={(e) => {
                //   e.preventDefault();
                //   e.stopPropagation();
                //   setHoverReadingLevel(true);
                // }}
                // onMouseLeave={() => setHoverReadingLevel(false)}
              >
                <span className="story-card__level-text">LEVEL</span>
                <span className={`level-${level} story-card__level-letter`}>
                  {level}
                  {/* onhover/onclick popup that states the grade level with link to chart */}
                </span>
              </div>
            </div>
            <div className="story-card__second-row">
              <span className="story-card__word-count">{wordCount} words</span>
              <span
                className="story-card__tags"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {level.charCodeAt(0) < 68
                  ? "Kindergarten"
                  : level.charCodeAt(0) === 68
                  ? "KG-Grade 1"
                  : level.charCodeAt(0) < 75
                  ? "Grade 1"
                  : level.charCodeAt(0) < 78
                  ? "Grade 2"
                  : level.charCodeAt(0) < 81
                  ? "Grade 3"
                  : level.charCodeAt(0) < 84
                  ? "Grade 4"
                  : level.charCodeAt(0) < 87
                  ? "Grade 5"
                  : level.charCodeAt(0) < 90
                  ? "Grade 6"
                  : "Grades 7-8"}
              </span>
            </div>
          </div>
        </article>
      </a>
    )
  ) : (
    <a className="story-card-wrapper">
      <article
        className={isInStory ? "story-card story-card--center" : "story-card"}
        style={{ cursor: "pointer" }}
        onClick={() => {
          showRegisterModal();
          // redirectToStory(false, id);
        }}
      >
        {!ignoreCover && (
          <div className="story-card__image">
            <img
              id="story-cover"
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${cover}`}
              className={isInStory ? "story-card__image--img" : undefined}
              alt="Story Cover"
              loading={onload ? "eager" : "lazy"}
              onError={(e: any) => {
                if (!extensionsTried.jpg || correctExtension === "jpg") {
                  e.target.src = `${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${cover.replace("webp", "jpg")}`;
                  setExtensionsTried((prev: any) => ({ ...prev, jpg: true }));
                  setCorrectExtension("jpg");
                } else if (!extensionsTried.png || correctExtension === "png") {
                  e.target.src = `${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${cover.replace("webp", "png")}`;
                  setExtensionsTried((prev: any) => ({ ...prev, png: true }));
                  setCorrectExtension("png");
                } else if (
                  !extensionsTried.jpeg ||
                  correctExtension === "jpeg"
                ) {
                  e.target.src = `${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${cover.replace("webp", "jpeg")}`;
                  setExtensionsTried((prev: any) => ({ ...prev, jpeg: true }));
                  setCorrectExtension("jpeg");
                }
              }}
            />
          </div>
        )}

        <div
          className={
            isInStory
              ? "story-card__content-wrapper story-card__content-wrapper--box-shadow"
              : "story-card__content-wrapper"
          }
        >
          <div className="story-card__first-row">
            <h2 className="story-card__title">{title}</h2>
            <div
              className="story-card__level-container"
              // onMouseEnter={() => setHoverReadingLevel(true)}
              // onClick={(e) => {
              //   e.preventDefault();
              //   e.stopPropagation();
              //   setHoverReadingLevel(true);
              // }}
              // onMouseLeave={() => setHoverReadingLevel(false)}
            >
              <span className="story-card__level-text">LEVEL</span>
              <span className={`level-${level} story-card__level-letter`}>
                {level}
              </span>
            </div>
          </div>
          <div className="story-card__second-row">
            <span className="story-card__word-count">{wordCount} words</span>
            <span
              className="story-card__tags"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {level.charCodeAt(0) < 68
                ? "Kindergarten"
                : level.charCodeAt(0) === 68
                ? "KG-Grade 1"
                : level.charCodeAt(0) < 75
                ? "Grade 1"
                : level.charCodeAt(0) < 78
                ? "Grade 2"
                : level.charCodeAt(0) < 81
                ? "Grade 3"
                : level.charCodeAt(0) < 84
                ? "Grade 4"
                : level.charCodeAt(0) < 87
                ? "Grade 5"
                : level.charCodeAt(0) < 90
                ? "Grade 6"
                : "Grades 7-8"}
            </span>
          </div>
        </div>
      </article>
    </a>
  );
};

export default StoryCard;
