import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDisplayData } from "../../Hooks/useDisplayData";
import { useStories } from "../../Hooks/useStories";
import { useUserData } from "../../Hooks/useUserData";
import { ParentData, ReadingLevels, StudentData } from "../../Models";
import { encrypt, generateID, GetBaseURL } from "../../Utils";

import UnlockIcon from "../../assets/unlock.png";
import Header from "../Header";
import Footer from "./Footer";
import Modal from "./Modal";
import ReadingPortalRow from "./ReadingPortalRow";
import StoryCard from "./StoryCard";
import UserDetailsCard from "./UserDetailsCard";
import NewEncryption from "../../Utils/NewEncryption";
import WordOfTheDay from "./WordOfTheDay";
import Lessons from "./Lessons";
import MiniGames from "./MiniGames";
import Worksheets from "./Worksheets";

export type FeaturedStoriesType = {
  "Button Bunny Learns Colors": boolean;
  "Ciara and her Baby Sister": boolean;
  "Halloween Costume Party": boolean;
  "Aisha and her Dollhouse": boolean;
  "Moon Sand": boolean;
  "The Bookman and the Mice": boolean;
  "The Clock Shop": boolean;
  "Buster's Toppings": boolean;
};

export type FeaturedStoriesType2 = {
  "Space Race": boolean;
  "Brooklyn Bear Plays Simon Says": boolean;
  "George the Giraffe": boolean;
  "The Dog Bone Mystery": boolean;
  "Roller Coaster": boolean;
  "My Pet Dino Is Missing!": boolean;
  "Suhail and the Red Ferrari": boolean;
  "Drake the Dragon": boolean;
};

const StudentPortal: React.FC = () => {
  const [rows, setRows] = useState<string[]>([]);
  const [excludedRows, setExcludedRows] = useState<string[]>([]);
  const { storyData, FetchAllCategories, SwitchFilter } = useStories();
  const {
    displayData,
    showModal,
    hideModal,
    showStripeModal,
    // redirectToStory,
    showAddChildProfileModal,
    setHomePageScrollHeight,
    showChildProfilesModal,
    setScrollToStories,
    showGamesModal,
    showComingSoonModal,
    showTrackedWordsModal,
  } = useDisplayData();
  const { userData, setUser } = useUserData();
  const [loadCounter, setLoadCounter] = useState<FeaturedStoriesType>({
    "Button Bunny Learns Colors": false,
    "Ciara and her Baby Sister": false,
    "Halloween Costume Party": false,
    "Aisha and her Dollhouse": false,
    "Moon Sand": false,
    "The Bookman and the Mice": false,
    "The Clock Shop": false,
    "Buster's Toppings": false,
  });
  const [loadCounter2, setLoadCounter2] = useState<FeaturedStoriesType2>({
    "Space Race": false,
    "Brooklyn Bear Plays Simon Says": false,
    "George the Giraffe": false,
    "The Dog Bone Mystery": false,
    "Roller Coaster": false,
    "My Pet Dino Is Missing!": false,
    "Suhail and the Red Ferrari": false,
    "Drake the Dragon": false,
  });
  const [counter, setCounter] = useState(0);
  const [searchText, setSearchText] = useState("");

  // document.body.style.overflow = "auto";

  useEffect(() => {
    if (displayData.scrollToStories) {
      if (document.getElementById("filter-start")) {
        document
          .getElementById("filter-start")
          ?.scrollIntoView({ behavior: "smooth" });
        setScrollToStories(false);
      }
    }
  });

  useEffect(() => {
    if (displayData.homepageScrollHeight) {
      window.scrollTo(0, displayData.homepageScrollHeight);
    }
    if (window.scrollY === displayData.homepageScrollHeight) {
      setHomePageScrollHeight(undefined);
    }
  });

  useEffect(() => {
    if (excludedRows.length === 0 && userData._id && userData._id !== "") {
      setCounter(counter + 1);
      const rowsToSet =
        storyData.filter === "category" ? FetchAllCategories() : ReadingLevels;
      setRows(rowsToSet);

      for (const row of rowsToSet) {
        document.getElementById(`${row}-stories`)?.children.length! - 1 <= 0
          ? setExcludedRows((prev: string[]) => [...prev, row])
          : setExcludedRows((prev: string[]) => [...prev]);
      }
    }
  }, [counter, userData, storyData.filter]);

  const onload = (title: string) => {
    Object.values(loadCounter).includes(false) &&
      setLoadCounter((prev: any) => ({ ...prev, [title]: true }));
  };

  const onload2 = (title: string) => {
    Object.values(loadCounter2).includes(false) &&
      setLoadCounter2((prev: any) => ({ ...prev, [title]: true }));
  };

  useEffect(() => {
    if (
      userData.account_type === "parent" &&
      (userData.user_data as ParentData).linked_accounts.length === 0
    ) {
      showAddChildProfileModal();
    }
  }, [userData]);

  return (
    <div
      style={{
        background:
          userData._id && userData._id !== ""
            ? undefined
            : `linear-gradient(to bottom, #0F4F7D, #000, #000)`,
      }}
    >
      <div
        className="home-reading-wrapper"
        //         style={{
        //           backgroundImage: `linear-gradient(
        // 90deg
        // ,rgba(0,0,0,.7),transparent,transparent),url(https://study-portal.s3.us-east-2.amazonaws.com/${process.env.REACT_APP_HOMEPAGE_IMAGE})`,
        //         }}
      >
        <div
          className={
            displayData.showModal ? "reading-portal__overlay" : "reading-portal"
          }
          onClick={() => {
            (!(
              displayData.addChildProfile ||
              userData.account_type === "parent" ||
              (userData.user_data as ParentData).linked_accounts.length === 0
            ) ||
              (userData.account_type === "parent" &&
                !displayData.addChildProfile &&
                (userData.user_data as ParentData).linked_accounts.length >
                  0)) &&
              hideModal();
          }}
        ></div>
        {userData._id &&
          userData._id !== "" &&
          userData.subscription_data.plan_type === "free" && (
            <>
              <div
                style={
                  displayData.addChildProfile &&
                  userData.account_type === "parent" &&
                  (userData.user_data as ParentData).linked_accounts.length ===
                    0
                    ? { pointerEvents: "none" }
                    : undefined
                }
                className="reading-portal__subscribe-banner"
                onClick={() => {
                  showStripeModal({ fromBanner: true, fromLimit: false });
                  fetch(
                    `${GetBaseURL()}/api/users/trackProductView?key=${
                      process.env.REACT_APP_PERSONAL_AUTH_KEY
                    }`,
                    {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        email:
                          userData.account_type === "student"
                            ? (userData.user_data as StudentData)
                                .linked_accounts[0].user_data.email
                            : userData.user_data.email,
                        content_name: "Clicked unlock on banner",
                        event_name: "ViewContent",
                      }),
                    }
                  );
                }}
              >
                <span className="reading-portal__subscribe-prompt">
                  <img src={UnlockIcon} />
                  Unlock Premium
                </span>
                <button className="reading-portal__subscribe-button">
                  Unlock!
                </button>
              </div>
              <div className="reading-portal__subscribe-placeholder"></div>
            </>
          )}
        <Header></Header>
        {displayData.showModal && (
          <Modal
            login={displayData.login}
            register={displayData.register}
            addChildProfile={displayData.addChildProfile}
            inviteTeacher={displayData.inviteTeacher}
            stripe={displayData.stripe}
            signIntoClass={displayData.signIntoClass}
            fromBanner={displayData.fromBanner}
            fromLimit={displayData.fromLimit}
            manageSubscription={displayData.manageSubscription}
            trackedWords={displayData.trackedWords}
            childData={displayData.childData}
            hideModal={hideModal}
          ></Modal>
        )}
        {userData._id === "" && (
          <div className="reading-portal__heading-container">
            {/* <div className="reading-portal__home-video-container">
            <iframe
              src="https://player.vimeo.com/video/588282126"
              width="364.8"
              height="321.48"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </div> */}
            <img
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/headline-1.svg`}
              className="reading-portal__headline"
            ></img>
            <img
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/subheadline-1.svg`}
              className="reading-portal__subheadline"
            ></img>
          </div>
        )}
        {userData._id && userData._id !== "" && (
          <div>
            <div className="reading-portal__user-details-row">
              <Lessons />

              <MiniGames />

              <Worksheets />

              <WordOfTheDay />

              {process.env.REACT_APP_NFT_ADMINS &&
                process.env.REACT_APP_NFT_ADMINS.includes(
                  userData.user_data.email!
                ) && (
                  <button
                    className="reading-portal__go-to-ttt"
                    onClick={async () => {
                      await fetch(`${GetBaseURL()}/api/words/changeWOTD`, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                      });
                    }}
                  >
                    Change wotd
                  </button>
                )}
              <div
                className="reading-portal__referrals"
                onClick={() => {
                  if (userData.account_type === "parent") {
                    window.open(
                      `${process.env.REACT_APP_SITE_URL}/referrals/${userData._id}`
                    );
                  } else {
                    setUser(
                      (userData.user_data as StudentData).linked_accounts[0]._id
                    );
                    window.open(
                      `${process.env.REACT_APP_SITE_URL}/referrals/${
                        (userData.user_data as StudentData).linked_accounts[0]
                          ._id
                      }`
                    );
                  }
                }}
              >
                <div className="reading-portal__referrals--text-dark">
                  SHARE &amp; EARN
                </div>

                <div className="reading-portal__referrals--text-white">
                  Share with friends, earn $10 Cash each time.
                </div>

                <div className="reading-portal__referrals--text-conditions">
                  * conditions apply
                </div>
              </div>
              {userData._id &&
                userData._id !== "" &&
                userData.account_type === "parent" && (
                  <section className="user-details__children-tracked-words-container">
                    {(userData.user_data as ParentData).linked_accounts.map(
                      (linked_account) => {
                        return (
                          <React.Fragment key={generateID()}>
                            <div className="user-details__children-tracked-words--child-container">
                              <div>{linked_account.user_data.name}</div>
                              <div
                                className="user-details__children-tracked-words--tracked-words-container"
                                onClick={() => {
                                  showTrackedWordsModal(
                                    linked_account.user_data as StudentData,
                                    linked_account._id
                                  );
                                }}
                              >
                                <div className="user-details__children-tracked-words--tracked-words-number">
                                  {
                                    (linked_account.user_data as StudentData)
                                      .perm_tracked_words.length
                                  }
                                </div>
                                <div className="user-details__children-tracked-words--tracked-words-text">
                                  Tracked Words
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      }
                    )}
                  </section>
                )}
            </div>

            {/* <div className="reading-portal__buttons-container">
              <div
                className="reading-portal__go-to-stories"
                onClick={() =>
                  document
                    .getElementById("filter-start")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                <div className="reading-portal__reading-button-img-holder">
                  <img
                    src="https://study-portal.s3.us-east-2.amazonaws.com/Book-Button-Icon.svg"
                    alt="Book Icon"
                    className="reading-portal__reading-button-img"
                  />
                </div>
                <div className="reading-portal__reading-button-text">Books</div>
              </div>
              <div
                className="reading-portal__go-to-MoonRok"
                onClick={() => showComingSoonModal("MoonRok")}
              >
                <div className="reading-portal__moonrok-button-img-holder">
                  <img
                    src="https://study-portal.s3.us-east-2.amazonaws.com/moonrock-logo.png"
                    alt="Book Icon"
                    className="reading-portal__moonrok-button-img"
                  />
                </div>
                <div className="reading-portal__reading-button-text">
                  Project Moon Rock
                </div>
              </div>
              <div
                className="reading-portal__go-to-worksheets"
                onClick={() =>
                  window.open(
                    `https://nofusstutors.com/readingRedirect/${
                      userData.account_type === "parent"
                        ? encrypt(userData.user_data.email!)
                        : encrypt(
                            (userData.user_data as StudentData)
                              .linked_accounts[0].user_data.email!
                          )
                    }`,
                    "_blank"
                  )
                }
              >
                <div className="reading-portal__worksheet-button-img-holder">
                  <img
                    src="https://study-portal.s3.us-east-2.amazonaws.com/Notebook-Button-Icon.svg"
                    alt="Book Icon"
                    className="reading-portal__reading-button-img"
                  />
                </div>
                <div className="reading-portal__reading-button-text">
                  Worksheets
                </div>
              </div>

              <div
                className="reading-portal__go-to-games"
                onClick={() => showGamesModal()}
              >
                <div className="reading-portal__game-button-img-holder">
                  <img
                    src="https://study-portal.s3.us-east-2.amazonaws.com/Game-Button-Icon.svg"
                    alt="Book Icon"
                    className="reading-portal__reading-button-img"
                  />
                </div>
                <div className="reading-portal__reading-button-text">
                  Mini Games
                </div>
              </div>
            </div> */}
          </div>
        )}
        {/* {userData._id && userData._id !== "" && window.innerWidth <= 1024 && (
          <div className="user-details__scroll-prompt-container">
            <span className="user-details__scroll-prompt">
              Scroll to see more
            </span>
            <i className="fa fa-chevron-right user-details__scroll-right" />
          </div>
        )} */}
        {(userData.account_type === "student" ||
          userData.account_type === "parent") && (
          <>
            {/* {userData._id &&
              userData._id !== "" &&
              userData.account_type === "student" && (
                <section className="user-details">
             

                  <UserDetailsCard
                    type="stats"
                    level={(userData.user_data as StudentData).reading_level}
                  />
                  {userData.subscription_data.plan_type === "free" && (
                    <UserDetailsCard type="unlocked" />
                  )}
                  {userData.subscription_data.plan_type === "paid" && (
                    <UserDetailsCard type="recently-read" />
                  )}
                  <UserDetailsCard type="school" />
                  <UserDetailsCard type="tutor" />
                </section>
              )} */}

            {!userData._id || userData._id === "" ? (
              <section
                className={
                  userData._id !== ""
                    ? "reading-portal__rows"
                    : "reading-portal__rows--signed-out"
                }
              >
                <div className="reading-portal__row">
                  <span className="reading-portal__row-title">
                    Click A Book Cover Below
                  </span>
                  <ReadingPortalRow
                    rowTitle="logged-out"
                    loggedOut={true}
                    onload={onload}
                    loadCounter={loadCounter}
                  />
                  <ReadingPortalRow
                    rowTitle="logged-out-2"
                    loggedOut={true}
                    onload={onload2}
                    loadCounter2={loadCounter2}
                  />
                </div>
              </section>
            ) : (
              <section
                className={
                  userData._id !== ""
                    ? "reading-portal__rows"
                    : "reading-portal__rows--signed-out"
                }
              >
                <div className="reading-portal__books-filter-row">
                  <div className="reading-portal__books-section-title">
                    Books
                  </div>
                  <div
                    className="reading-portal__filters-container"
                    id="filter-start"
                  >
                    <div className="reading-portal__search-container">
                      <i
                        style={{
                          color: "#333",
                          fontSize: "1.5rem",
                        }}
                        className="fa fa-search"
                      ></i>
                      <input
                        className="reading-portal__search-container--input"
                        type="text"
                        placeholder="Search Books"
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                    <div className="reading-portal__filter">
                      <span className="reading-portal__filter--text">
                        Filter:
                      </span>
                      <span
                        className={
                          storyData.filter === "category"
                            ? "reading-portal__filter--button--selected"
                            : "reading-portal__filter--button"
                        }
                        onClick={() => {
                          if (storyData.filter === "level") {
                            setExcludedRows([]);
                            SwitchFilter("category");
                          }
                        }}
                      >
                        Category
                      </span>
                      <span
                        className={
                          storyData.filter === "level"
                            ? "reading-portal__filter--button--selected"
                            : "reading-portal__filter--button"
                        }
                        onClick={() => {
                          if (storyData.filter === "category") {
                            setExcludedRows([]);
                            SwitchFilter("level");
                          }
                        }}
                      >
                        Reading Level
                      </span>
                    </div>
                  </div>
                </div>
                {searchText === "" ? (
                  storyData.filter === "category" ? (
                    rows.map((rowTitle) => {
                      return excludedRows.includes(rowTitle) ? null : (
                        <React.Fragment key={generateID()}>
                          <div className="reading-portal__row">
                            <span className="reading-portal__row-title">
                              {rowTitle}
                            </span>
                            {storyData.stories.length > 0 && (
                              <ReadingPortalRow rowTitle={rowTitle} />
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    ReadingLevels.map((level) => {
                      return excludedRows.includes(level) ? null : (
                        <React.Fragment key={generateID()}>
                          <div className="reading-portal__row">
                            <span className="reading-portal__row-title">
                              Level {level}
                            </span>
                            {storyData.stories.length > 0 && (
                              <ReadingPortalRow rowTitle={level} />
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })
                  )
                ) : (
                  <>
                    <span className="reading-portal__row-title">
                      Search Results...
                    </span>
                    <div className="reading-portal__row-stories--no-scroll">
                      {storyData.stories
                        .filter(
                          (filteringStory) =>
                            filteringStory.title
                              .toLowerCase()
                              .includes(searchText.toLowerCase()) ||
                            filteringStory.tags
                              .join("")
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                        )
                        .map((story) => {
                          return (
                            <React.Fragment key={generateID()}>
                              <StoryCard
                                title={story.title}
                                level={story.level}
                                tags={story.tags}
                                cover={story.cover}
                                wordCount={
                                  Array.from(
                                    new Set(
                                      story.pages
                                        .map((page) => {
                                          return page.page_text
                                            .replace(/\r/g, "")
                                            .split(/\n/)
                                            .join(";;")
                                            .split(";;")
                                            .join(" ")
                                            .split(" ");
                                        })
                                        .flat()
                                    )
                                  ).length
                                }
                                id={story._id}
                                priority_tag={story.priority_tag}
                              ></StoryCard>
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </>
                )}
              </section>
            )}
          </>
        )}
        <div className="reading-portal__footer-before"></div>
        <div className="reading-portal__footer-before-white"></div>
        <Footer />
      </div>
    </div>
  );
};

export default StudentPortal;
