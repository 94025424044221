import React from "react";

type SquareProps = {
  winningSquare?: boolean;
  value: string;
  onClick: () => void;
  players: { p1: string; p2: string };
};

const Square: React.FC<SquareProps> = ({
  winningSquare,
  value,
  onClick,
  players,
}) => {
  return (
    <button
      className={`tic-tac-toe__squares${
        winningSquare ? " tic-tac-toe__squares--winner" : ""
      }${
        value === players.p1
          ? ` tic-tac-toe__p1-col`
          : value === players.p2
          ? ` tic-tac-toe__p2-col`
          : ""
      }`}
      onClick={onClick}
    >
      {value}
    </button>
  );
};
export default Square;
