import React, { useLayoutEffect, useRef, useState } from "react";
import MemoryGrid from "../Components/Games/MemoryGame/MemoryGrid";
import { generateID } from "../Utils";

const MemoryGame: React.FC = () => {
  const [custom, setCustom] = useState<boolean | null>(null);
  const [customWords, setCustomWords] = useState<
    { word: string; number: number }[]
  >([
    { word: "crowd", number: 1 },
    { word: "count", number: 2 },
    { word: "mount", number: 3 },
    { word: "proud", number: 4 },
    { word: "found", number: 5 },
    { word: "sound", number: 6 },
  ]);
  return (
    <div className="memory-game">
      {custom === null ? (
        <div className="memory-game__settings">
          <div className="memory-game__settings--top">
            <div className="memory-game__settings--text">
              Choose your own words or phonics
            </div>
            <div className="memory-game__settings--input-container">
              {[1, 2, 3, 4, 5, 6].map((number) => (
                <input
                  className="memory-game__settings--input"
                  defaultValue={
                    customWords.find((word) => word.number === number)
                      ? customWords.find((word) => word.number === number)!.word
                      : ""
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCustomWords((prev) => [
                      ...prev.filter((word) => word.number !== number),
                      { word: e.target.value, number },
                    ]);
                  }}
                />
              ))}
            </div>

            <div
              className="memory-game__new-game"
              style={{ width: "fit-content", margin: "1.5rem auto" }}
              onClick={() => setCustom(true)}
            >
              Start Game
            </div>
          </div>
          {/* <div
            className="memory-game__new-game"
            style={{ width: "fit-content" }}
            onClick={() => setCustom(false)}
          >
            Start with default settings
          </div> */}
        </div>
      ) : custom === true ? (
        <MemoryGrid
          customWords={customWords.map((word) => word.word)}
          setCustom={setCustom}
        />
      ) : (
        <MemoryGrid setCustom={setCustom} />
      )}
    </div>
  );
};

export default MemoryGame;
