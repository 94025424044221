import React, { useEffect, useState } from "react";
import { GetBaseURL } from "../Utils";

const MoonRockVerification: React.FC = () => {
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState(false);

  const VerifyMoonRockUser = async () => {
    const pathnames = window.location.pathname.split("/");
    const student_id = pathnames[pathnames.length - 1];
    const request = await fetch(
      `${GetBaseURL()}/api/users/verifyMoonRock?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ student_id }),
      }
    );
    const response = await request.json();
    if (request.status === 200) {
      setVerifying(false);
    } else {
      setVerifying(false);
      setError(true);
    }
  };

  useEffect(() => {
    VerifyMoonRockUser();
  }, []);
  return (
    <div style={{ fontSize: "2rem", margin: "auto", color: "cyan" }}>
      {verifying
        ? "Verifying..."
        : error
        ? "There was an error. Please contact the dev team."
        : "Your child has been verified!"}
    </div>
  );
};

export default MoonRockVerification;
