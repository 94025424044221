import { createCipheriv, createDecipheriv, createHash } from "crypto";

const IV = createHash("sha256").update("secretkey").digest();
const KEY = createHash("sha256").update("anotherkey").digest();
const LAST_KEY = createHash("sha256").update("anotherkey").digest();

const RESIZED_IV = Buffer.allocUnsafe(16);

IV.copy(RESIZED_IV);

export const encrypt = (value: string) => {
  const crypted = createCipheriv("aes-256-gcm", KEY, RESIZED_IV);
  const string = crypted.update(value, "binary", "hex");

  return string;
};

export const decrypt = (value: string) => {
  const DECIPHER = createDecipheriv("aes-256-gcm", LAST_KEY, RESIZED_IV);
  const STRING = DECIPHER.update(value, "hex", "binary");
  return STRING;
};
