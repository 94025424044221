export const contractions = [
  "aren't",
  "can't",
  "couldn't",
  "could've",
  "didn't",
  "doesn't",
  "don't",
  "hadn't",
  "hasn't",
  "haven't",
  "he'd",
  "he'll",
  "he's",
  "i'd",
  "i'll",
  "i'm",
  "i've",
  "isn't",
  "it's",
  "it'll",
  "it'd",
  "jack-o'-lantern",
  "let's",
  "ma'am",
  "mightn't",
  "might've",
  "mustn't",
  "must've",
  "ne'er-do-well",
  "o'clock",
  "shan't",
  "she'd",
  "she'll",
  "she's",
  "shouldn't",
  "should've",
  "that's",
  "that'll",
  "that'd",
  "there's",
  "there'll",
  "they'd",
  "they'll",
  "they're",
  "they've",
  "'twas",
  "we're",
  "we'd",
  "we'll",
  "we've",
  "wasn't",
  "weren't",
  "what'll",
  "what're",
  "what's",
  "what've",
  "where's",
  "where'll",
  "where'd",
  "where're",
  "when's",
  "when'll",
  "when'd",
  "when're",
  "when've",
  "who's",
  "who'd",
  "who'll",
  "who've",
  "why's",
  "why're",
  "why'll",
  "why'd",
  "why've",
  "will-o'-the-wisp",
  "won't",
  "wouldn't",
  "how's",
  "how're",
  "how'll",
  "how'd",
  "how've",
  "you're",
  "you'll",
  "you'd",
  "you've",
];
