import React, { useState } from "react";
import { useEffect } from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { useUserData } from "../Hooks/useUserData";
import { GetBaseURL } from "../Utils";

interface MatchParams {
  userID: string;
}

interface ComponentProps extends RouteComponentProps<MatchParams> {}

const Verification: React.FC<ComponentProps> = ({ match }) => {
  const { setUser } = useUserData();
  const [verified, setVerified] = useState(false);
  const [failed, setFailed] = useState(false);

  const verifyEmail = async (id: string) => {
    const request = await fetch(`${GetBaseURL()}/api/users/verify`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id }),
    });
    const response = await request.json();
    if (request.status === 200) {
      setVerified(true);
      setUser(id);
    } else {
      setFailed(true);
      console.log(response);
    }
  };

  useEffect(() => {
    verifyEmail(match.params.userID);
  }, [match]);

  return (
    <section className="verification">
      {verified ? (
        <div className="verification__main">
          <div className="verification__heading">
            Successfully verified email!
          </div>
          <Link to="/" className="verification__button">
            Start Reading
          </Link>
        </div>
      ) : failed ? (
        <div className="verification_main">
          <div className="verification__heading">
            Oops! Something went wrong.
          </div>
        </div>
      ) : (
        <div className="verification__main">
          <div className="verification__heading">Verifying...</div>
        </div>
      )}
    </section>
  );
};

export default Verification;
