import React, { useEffect, useState } from "react";
import StudentPortal from "../Components/Partials/StudentPortal";

import { useUserData } from "../Hooks/useUserData";

const ReadingPortal: React.FC = () => {
  const { userData } = useUserData();

  return !userData._id && userData._id !== "" ? (
    <div className="story__loading">
      <div className="story__loading--text">Loading...</div>

      <div className="story__loading--globe">
        <div className="story__loading--bird">
          <div className="story__loading--body">
            <div className="story__loading--eye story__loading--left"></div>
            <div className="story__loading--eye story__loading--right"></div>
            <div className="story__loading--beak">
              <div></div>
            </div>
            <div className="story__loading--feet"></div>
            <div className="story__loading--wire"></div>
          </div>
          <div className="story__loading--hills"></div>
          <div className="story__loading--cloud"></div>
          <div className="story__loading--cloud story__loading--small"></div>
        </div>
      </div>
    </div>
  ) : (
    <StudentPortal />
  );
};

export default ReadingPortal;
