import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { useStories } from "../Hooks/useStories";
import StoryPageText from "../Components/Partials/StoryPageText";
import { StylesContextProvider } from "../Context/StylesContext";
import { Phonics, Story, StoryPage, WordPhonics } from "../Models/Stories";
import { generateID } from "../Utils/GenerateID";
import { useUserData } from "../Hooks/useUserData";
import { StudentData, TrackedWords } from "../Models";
import { useDisplayData } from "../Hooks/useDisplayData";
import Modal from "../Components/Partials/Modal";
import Questions from "../Components/Partials/Questions";
import StoryProgressBar from "../Components/Partials/StoryProgressBar";
import EditStory from "./EditStory";
import HTMLFlipBook from "react-pageflip";
// import { flipPageBack, flipPageForward } from "../Utils/PageFlip";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { AssignmentDetector, GetBaseURL } from "../Utils";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useReactMediaRecorder } from "react-media-recorder";
import AssignmentHeader from "../Components/Partials/AssignmentHeader";

interface MatchParams {
  storyID: string;
}

export type ModalProps = {
  show?: boolean;
  word: string;
  definitions: string[];
  phonics: WordPhonics[];
  images?: string[];
  trackedWords?: string[];
  displayPhonics?: string[];
  imageNumber: number;
  audio?: string;
  pageNumber?: number;
};

export type SetAssignmentType = {
  missedWords: string[];
  accuracy: number;
  mediaBlobUrl: string | null;
  transcript: string;
};

interface ComponentProps extends RouteComponentProps<MatchParams> {}

const StoryComponent: React.FC<ComponentProps> = ({ match }) => {
  const {
    userData,
    updateTrackedWords,
    // stopDisplayingTutorial,
    addStoryAsRead,
    updateLastActive,
    // addNewScore,
    submitAssignment,
  } = useUserData();

  const [page, setPage] = useState(0);
  const [assignmentData, setAssignmentData] =
    useState<SetAssignmentType | null>(null);
  const [modalProperties, setModalProperties] = useState<ModalProps>({
    show: false,
    word: "",
    definitions: [],
    phonics: [],
    images: undefined,
    imageNumber: 0,
    pageNumber: 0,
  });
  const { storyData, SetCurrentStoryID } = useStories();
  const {
    displayData,
    showRegisterModal,
    hideModal,
    // redirectToStory,
    showChildProfilesModal,
    showNextStoryModal,
    setInitialReload,
  } = useDisplayData();
  const [storyDetails, setStoryDetails] = useState<Story | undefined>();
  const [trackedWords, setTrackedWords] = useState<TrackedWords[]>([]);
  const [permTrackedWords, setPermTrackedWords] = useState<TrackedWords[]>(
    userData.account_type === "student"
      ? (userData.user_data as StudentData).perm_tracked_words
      : []
  );
  const [tracker, setTracker] = useState<ModalProps[]>([]);
  const [displayArrows, setDisplayArrows] = useState(false);
  const [resetHighlight, setResetHighlight] = useState(false);
  const [assignment, setAssignment] = useState<string | undefined>(undefined);

  // const [tutorialPoint, setTutorialPoint] = useState(
  //   window.innerWidth > 1024 ? 0 : 1
  // );
  const [width, setWidth] = useState(window.innerWidth);
  const [currentPage, setCurrentPage] = useState(0);
  const [showTracking, setShowTracking] = useState(false);
  const [storyPage, setStoryPage] = useState<StoryPage>({
    page_number: 0,
    page_text: "",
    page_image: "",
    lines: [],
  });

  const storyRef = useRef<HTMLDivElement>(null);
  const storyWrapperRef = useRef<HTMLDivElement>(null);

  const [extensionsTried, setExtensionsTried] = useState({
    jpg: false,
    png: false,
    jpeg: false,
  });
  const [correctExtension, setCorrectExtension] = useState("");
  const [firstPhonicPosition, setFirstPhonicPosition] = useState({
    x: 0,
    y: 0,
  });
  const [wordAudioPosition, setWordAudioPosition] = useState({ x: 0, y: 0 });
  const firstPhonicRef = useRef<HTMLSpanElement>(null);
  const wordAudioRef = useRef<HTMLLIElement>(null);
  const wordContainerRef = useRef<HTMLDivElement>(null);
  const wordRef = useRef<HTMLSpanElement>(null);
  const [showModalTop, setShowModalTop] = useState(true);
  const [showQuestions, setShowQuestions] = useState(false);
  const [questionsSubmitted, setQuestionsSubmitted] = useState(false);
  const [previousAnswers, setPreviousAnswers] = useState({
    studentAnswers: { correct1: false, correct2: false, correct3: false },
    selectedAnswers: { q1: "", q2: "", q3: "" },
  });
  const [editStory, setEditStory] = useState(false);
  // const [showHeader, setShowHeader] = useState(false);
  const [scrollDistance, setScrollDistance] = useState<number | undefined>(
    undefined
  );
  const [overrideFontSize, setOverrideFontSize] = useState(1.8);
  const [showProgressBarDropdown, setShowProgressBarDropdown] = useState(false);
  const [overrideWordHighlight, setOverrideWordHighlight] = useState({
    overrideWordToHighlight: "",
    overrideWordToHighlightIndex: 0,
    overrideWordToHighlightLineIndex: 0,
  });
  const [lineDetails, setLineDetails] = useState({ line: "", lineIndex: 0 });
  const [voiceWordProperties, setVoiceWordProperties] = useState({
    wordIndex: 0,
    pageNumber: 1,
  });
  const [missedWords, setMissedWords] = useState<string[]>([]);
  const [accuracy, setAccuracy] = useState<number | null>(null);

  // useEffect(() => {
  //   if (storyDetails && storyWrapperRef.current) {
  //     storyWrapperRef.current.scrollIntoView();
  //     setTimeout(() => {
  //       document.body.style.overflow = "hidden";
  //     }, 300);
  //   }
  // }, [storyDetails, storyWrapperRef.current]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      // if (window.innerWidth <= 1024) {
      //   setTutorialPoint(1);
      // }
    });
  });

  useEffect(() => {
    if (storyDetails && userData._id && userData._id !== "") {
      updateLastActive(new Date(Date.now()));
      AssignmentDetector(userData, storyDetails?._id, setAssignment);
    }
  }, [userData, storyDetails]);

  useEffect(() => {
    if (storyDetails) {
      const foundPage = storyDetails.pages.find(
        (storyPage) => page === storyPage.page_number
      );
      if (foundPage) {
        setStoryPage(foundPage);
      }
    }
  }, [page, storyDetails]);

  useEffect(() => {
    setStoryDetails(
      storyData.stories.find((story) => story._id === match.params.storyID)
    );

    if (storyData.currentStoryID !== match.params.storyID) {
      SetCurrentStoryID(match.params.storyID);
      resetStory();
    }
  }, [storyData, match]);

  useEffect(() => {
    if (userData.account_type === "student") {
      setPermTrackedWords(
        (userData.user_data as StudentData).perm_tracked_words
      );
      if (userData.account_type === "student") {
        updateLastActive(new Date(Date.now()));
      }
    }
  }, [(userData.user_data as StudentData).perm_tracked_words]);

  // useEffect(() => {
  //   if (firstPhonicRef.current) {
  //     setFirstPhonicPosition({
  //       x: firstPhonicRef.current.offsetLeft,
  //       y: firstPhonicRef.current.offsetTop,
  //     });
  //   }
  //   if (wordAudioRef.current) {
  //     setWordAudioPosition({
  //       x: wordAudioRef.current.offsetLeft,
  //       y: wordAudioRef.current.offsetTop,
  //     });
  //   }
  // }, [
  //   firstPhonicRef,
  //   wordAudioRef,
  //   // tutorialPoint
  // ]);

  const resetStory = () => {
    setPage(0);
    setModalProperties({
      show: false,
      word: "",
      definitions: [],
      phonics: [],
      images: undefined,
      imageNumber: 0,
    });
    setTrackedWords([]);
    setTracker([]);
    setDisplayArrows(false);
    setResetHighlight(false);
    setCurrentPage(0);
    setShowTracking(false);
    setStoryPage({
      page_number: 0,
      page_text: "",
      page_image: "",
      lines: [],
    });

    setExtensionsTried({
      jpg: false,
      png: false,
      jpeg: false,
    });
    setShowModalTop(true);
    window.scrollTo(0, 0);
  };

  const setLine = (line: string, lineIndex: number, pageNumber: number) => {
    setLineDetails({ line, lineIndex });
    setCurrentPage(pageNumber);
  };

  const onClick = (
    word: string,
    definitions: string[],
    phonics: WordPhonics[],
    pageNumber: number,
    images?: string[],
    audio?: string
  ) => {
    setShowTracking(false);
    const userLoggedIn = localStorage.getItem("id"); //check local storage for logged in user
    let tempTrackedWords = trackedWords; //set up a temp variable for tracked words that will update immediately as opposed to state which does not update immediately
    const indexOfWord = trackedWords.findIndex((val) => val.word === word);
    let permIndexOfWord = -1;
    if (userLoggedIn) {
      permIndexOfWord = permTrackedWords.findIndex((val) => val.word === word);
    }
    if (word === "") {
      setModalProperties({
        show: false,
        word: "",
        definitions: [],
        phonics: [],
        images: undefined,
        imageNumber: 0,
      });
    } else {
      if (indexOfWord === -1) {
        tempTrackedWords.push({
          word,
          definitions,
          phonics,
          displayPhonics: [],
          positions: Array.from(Array(phonics.length).keys()).map((n) => n + 1),
        }); //push to the temp variable so we can immediately use it for the permanent tracked words logic below
        setTrackedWords(tempTrackedWords);
      }
      if (permIndexOfWord === -1 && userLoggedIn) {
        setPermTrackedWords((prev: TrackedWords[]) => [
          ...prev,
          {
            word,
            definitions,
            phonics,
            displayPhonics: [],
            positions: Array.from(Array(phonics.length).keys()).map(
              (n) => n + 1
            ),
            times_clicked: 1,
          },
        ]);
      } else {
        if (userLoggedIn) {
          const newPermTrackedWords = permTrackedWords;
          newPermTrackedWords[permIndexOfWord].times_clicked! += 1;
          if (
            tempTrackedWords.find(
              (tempTrackedWord) => tempTrackedWord.word === word
            )?.positions.length === 0
          ) {
            newPermTrackedWords[permIndexOfWord].displayPhonics = [];
            newPermTrackedWords[permIndexOfWord].positions = Array.from(
              Array(phonics.length).keys()
            ).map((n) => n + 1);
          }
          setPermTrackedWords(newPermTrackedWords);
        }
      }
      setModalProperties({
        show: true,
        word,
        definitions,
        phonics,
        images,
        imageNumber: 0,
        audio,
        pageNumber,
      });
    }
    setCurrentPage(pageNumber);
    if (word === "") {
      setResetHighlight(true);
    } else {
      setResetHighlight(false);
    }
  };

  const clickPhonic = (
    word: string,
    definitions: string[],
    phonics: WordPhonics[],
    phonicNames: string,
    position: number,
    stayClicked?: boolean
  ) => {
    let newTrackedWords: TrackedWords[] = trackedWords;
    let permNewTrackedWords: TrackedWords[] = permTrackedWords;
    const indexOfWord = trackedWords.findIndex((val) => val.word === word);
    const permIndexOfWord = permTrackedWords.findIndex(
      (val) => val.word === word
    );
    if (
      indexOfWord !== -1 &&
      !trackedWords[indexOfWord].positions.includes(position)
    ) {
      newTrackedWords[indexOfWord].positions.push(position);
      newTrackedWords[indexOfWord].displayPhonics.push(phonicNames);
    } else if (indexOfWord === -1) {
      newTrackedWords.push({
        word,
        definitions,
        phonics,
        positions: [],
        displayPhonics: [],
      });
    } else {
      if (!stayClicked) {
        newTrackedWords[indexOfWord].positions.splice(
          newTrackedWords[indexOfWord].positions.indexOf(position),
          1
        );
        newTrackedWords[indexOfWord].displayPhonics.splice(
          newTrackedWords[indexOfWord].displayPhonics.indexOf(phonicNames),
          1
        );
      }
    }
    if (
      permIndexOfWord !== -1 &&
      !permTrackedWords[permIndexOfWord].positions.includes(position)
    ) {
      permNewTrackedWords[permIndexOfWord].positions.push(position);
      permNewTrackedWords[permIndexOfWord].displayPhonics.push(phonicNames);
    } else if (permIndexOfWord === -1) {
      permNewTrackedWords.push({
        word,
        definitions,
        phonics,
        positions: [],
        displayPhonics: [],
        times_clicked: 1,
      });
    }

    setTrackedWords(newTrackedWords);
    setPermTrackedWords(permNewTrackedWords);
    const newTracker = tracker.filter((val) => val.word !== word);
    setTracker([
      ...newTracker,
      {
        word,
        definitions: modalProperties.definitions,
        phonics: modalProperties.phonics,
        images: modalProperties.images,
        displayPhonics: newTrackedWords[indexOfWord].displayPhonics,
        imageNumber: 0,
      },
    ]);
  };

  const removeWord = (word: string) => {
    setTrackedWords((prev: TrackedWords[]) =>
      prev.filter((val) => val.word !== word)
    );
    const permIndexOfWord = permTrackedWords.findIndex(
      (val) => val.word === word
    );
    const newPermTrackedWords = permTrackedWords;
    newPermTrackedWords[permIndexOfWord].times_clicked! -= 1;
    setPermTrackedWords(newPermTrackedWords);
    if (modalProperties.word === word) {
      setModalProperties({
        show: false,
        word: "",
        definitions: [],
        phonics: [],
        images: undefined,
        imageNumber: 0,
      });
      setResetHighlight(true);
    }
  };

  useEffect(() => {
    const beforeUnloadEvent = async (e: any) => {
      if (userData._id) {
        await updateTrackedWords(permTrackedWords);
      }
    };
    window.addEventListener("beforeunload", beforeUnloadEvent);
    return () => window.removeEventListener("beforeunload", beforeUnloadEvent);
  }, [permTrackedWords]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollDistance(
        window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0
      );
    });
  }, []);

  useEffect(() => {
    if (width > 1024) {
      storyRef.current?.children[0].children[0].children[1].children[0].children[0].addEventListener(
        "scroll",
        () => {
          setScrollDistance(
            storyRef.current?.children[0].children[0].children[1].children[0]
              .children[0].scrollTop
          );
        }
      );
    }
  }, [storyDetails]);

  return storyDetails ? (
    userData._id &&
    userData._id !== "" &&
    userData.subscription_data.plan_type === "free" &&
    (userData.account_type === "student"
      ? (userData.user_data as StudentData).linked_accounts.length > 0 &&
        (userData.user_data as StudentData).linked_accounts[0].subscription_data
          .freemium_tallies[
          (userData.user_data as StudentData).linked_accounts[0]
            .subscription_data.freemium_tallies.length - 1
        ]
      : userData.subscription_data.freemium_tallies[
          userData.subscription_data.freemium_tallies.length - 1
        ]) &&
    (userData.user_data as StudentData).linked_accounts.length > 0 &&
    (userData.account_type === "student"
      ? (userData.user_data as StudentData).linked_accounts[0].subscription_data
          .freemium_tallies[
          (userData.user_data as StudentData).linked_accounts[0]
            .subscription_data.freemium_tallies.length - 1
        ]
      : userData.subscription_data.freemium_tallies[
          userData.subscription_data.freemium_tallies.length - 1
        ]
    ).story_title !== storyDetails.title ? (
      <Redirect to="/" />
    ) : (
      <div className="story-wrapper" ref={storyWrapperRef}>
        <div className="story">
          {width > 1024 && (
            <div
              className="story__outside"
              onClick={() => onClick("", [""], [], 0)}
            ></div>
          )}
          {displayData.showModal && (
            <Modal
              login={displayData.login}
              register={displayData.register}
              addChildProfile={displayData.addChildProfile}
              inviteTeacher={displayData.inviteTeacher}
              childProfiles={displayData.childProfiles}
              nextStory={displayData.nextStory}
              stripe={displayData.stripe}
              fromBanner={displayData.fromBanner}
              fromLimit={displayData.fromLimit}
              hideModal={hideModal}
            ></Modal>
          )}
          {width > 1024 &&
            (userData._id && userData._id !== "" ? (
              <Link to="/">
                <div
                  className="story__back-home-arrow"
                  onClick={async () => {
                    if (userData._id) {
                      await updateTrackedWords(permTrackedWords);
                    }
                  }}
                >
                  {/* <img
                  className="story__back-home-arrow--img"
                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/back-arrow.svg`}
                  alt={"No Image"}
                /> */}
                  <i className="fa fa-arrow-left story__back-home-arrow" />
                  <div className=""></div>
                </div>
              </Link>
            ) : (
              <div
                className="story__see-more"
                onClick={() => showRegisterModal()}
              >
                {/* <img
                className="story__back-home-arrow--img"
                src={`${process.env.REACT_APP_AWS_BUCKET_URL}/back-arrow.svg`}
                alt={"No Image"}
              /> */}
                <i className="fa fa-arrow-left story__back-home-arrow" />
                Enter Your Universe
              </div>
            ))}
          <div
            className="story__container-middle"
            ref={storyRef}
            // onTouchStart={() => {
            //   setShowHeader(!showHeader);
            // }}
            // onClick={() => {
            //   setShowHeader(!showHeader);
            // }}
          >
            {width < 1024 && (
              <>
                {
                  <StoryPageText
                    title={true}
                    image={storyDetails.cover}
                    pageNumber={0}
                    lines={[]}
                    onClick={() => {}}
                    setPage={setPage}
                    page={page}
                    scrollDistance={scrollDistance}
                    assignment={assignment}
                  />
                }
                {storyDetails.pages
                  .sort(
                    (first, second) => first.page_number - second.page_number
                  )
                  .map((mappedPage) => (
                    <StoryPageText
                      image={mappedPage.page_image}
                      pageNumber={mappedPage.page_number}
                      currentPage={currentPage}
                      lines={mappedPage.lines}
                      fontSize={mappedPage.font_size}
                      fontColor={mappedPage.font_color}
                      lineHeight={mappedPage.line_height}
                      top={mappedPage.top}
                      right={mappedPage.right}
                      marginBottom={mappedPage.margin_bottom}
                      textAlign={mappedPage.text_align}
                      word_margin={mappedPage.word_margin}
                      word_tb_padding={mappedPage.word_tb_padding}
                      word_rl_padding={mappedPage.word_rl_padding}
                      word_container_width={mappedPage.word_container_width}
                      modalProperties={modalProperties}
                      trackedWords={trackedWords}
                      onClick={onClick}
                      resetHighlight={resetHighlight}
                      // setShowHeader={setShowHeader}
                      clickPhonic={clickPhonic}
                      removeWord={removeWord}
                      isMobile={true}
                      setPage={setPage}
                      page={page}
                      storyDetails={storyDetails}
                      setShowQuestions={setShowQuestions}
                      showQuestions={showQuestions}
                      mobileFriendly={storyDetails.mobileFriendly}
                      wordContainerRef={wordContainerRef}
                      overrideFontSize={overrideFontSize}
                      overrideWordHighlight={overrideWordHighlight}
                      setOverrideWordHighlight={setOverrideWordHighlight}
                      lineDetails={lineDetails}
                      setVoiceWordProperties={setVoiceWordProperties}
                    />
                  ))}
                <Questions
                  questions={storyDetails.questions}
                  storyDetails={storyDetails}
                  onSubmit={async (studentAnswers, selectedAnswers, score) => {
                    setPreviousAnswers({ studentAnswers, selectedAnswers });
                    updateTrackedWords(permTrackedWords);
                    if (userData.account_type === "student") {
                      addStoryAsRead(storyDetails._id, score);
                      if (assignment && assignmentData) {
                        let blob = await fetch(
                          assignmentData!.mediaBlobUrl!
                        ).then((r) => r.blob());
                        const FD = new FormData();
                        FD.append(
                          `${userData.user_data.name
                            .split(" ")
                            .join("")}-${storyDetails.title
                            .split(" ")
                            .join("")}`,
                          blob
                        );

                        const URL = `${GetBaseURL()}/api/stories/addStoryDocument/wav?key=${
                          process.env.REACT_APP_PERSONAL_AUTH_KEY
                        }`;
                        await fetch(URL, {
                          body: FD,
                          method: "POST",
                        });
                        submitAssignment(
                          assignment,
                          `${userData.user_data.name
                            .split(" ")
                            .join("")}-${storyDetails.title
                            .split(" ")
                            .join("")}.wav`,
                          assignmentData.accuracy,
                          assignmentData.missedWords,
                          score,
                          assignmentData.transcript,
                          storyDetails._id
                        );
                        setQuestionsSubmitted(true);
                      }
                    } else {
                      setQuestionsSubmitted(true);
                    }
                  }}
                  alreadySubmitted={questionsSubmitted}
                  previousAnswers={previousAnswers}
                  setShowQuestions={setShowQuestions}
                  hideCorrectAnswers={assignment ? true : false}
                  recordingSubmitted={
                    assignmentData?.mediaBlobUrl ? true : false
                  }
                />
              </>
            )}

            {width >= 1024 && (
              <SimpleBar style={{ height: "100vh" }}>
                <StoryPageText
                  title={true}
                  image={storyDetails.cover}
                  pageNumber={0}
                  lines={[]}
                  onClick={() => {}}
                  setPage={setPage}
                  page={page}
                  isMobile={true}
                  scrollDistance={scrollDistance}
                  assignment={assignment}
                />
                {storyDetails.pages
                  .sort(
                    (first, second) => first.page_number - second.page_number
                  )
                  .map((mappedPage) => (
                    <StoryPageText
                      image={mappedPage.page_image}
                      pageNumber={mappedPage.page_number}
                      currentPage={currentPage}
                      lines={mappedPage.lines}
                      fontSize={mappedPage.font_size}
                      fontColor={mappedPage.font_color}
                      lineHeight={mappedPage.line_height}
                      top={mappedPage.top}
                      right={mappedPage.right}
                      marginBottom={mappedPage.margin_bottom}
                      textAlign={mappedPage.text_align}
                      word_margin={mappedPage.word_margin}
                      word_tb_padding={mappedPage.word_tb_padding}
                      word_rl_padding={mappedPage.word_rl_padding}
                      word_container_width={mappedPage.word_container_width}
                      modalProperties={modalProperties}
                      trackedWords={trackedWords}
                      onClick={onClick}
                      resetHighlight={resetHighlight}
                      // setShowHeader={setShowHeader}
                      clickPhonic={clickPhonic}
                      removeWord={removeWord}
                      isMobile={width <= 1024 ? true : false}
                      setPage={setPage}
                      page={page}
                      storyDetails={storyDetails}
                      setShowQuestions={setShowQuestions}
                      showQuestions={showQuestions}
                      mobileFriendly={storyDetails.mobileFriendly}
                      overrideFontSize={overrideFontSize}
                    />
                  ))}
                <Questions
                  questions={storyDetails.questions}
                  storyDetails={storyDetails}
                  onSubmit={async (studentAnswers, selectedAnswers, score) => {
                    setPreviousAnswers({ studentAnswers, selectedAnswers });
                    updateTrackedWords(permTrackedWords);
                    if (userData.account_type === "student") {
                      addStoryAsRead(storyDetails._id, score);
                      if (assignment && assignmentData) {
                        let blob = await fetch(
                          assignmentData!.mediaBlobUrl!
                        ).then((r) => r.blob());
                        const FD = new FormData();
                        FD.append(
                          `${userData.user_data.name
                            .split(" ")
                            .join("")}-${storyDetails.title
                            .split(" ")
                            .join("")}`,
                          blob
                        );

                        const URL = `${GetBaseURL()}/api/stories/addStoryDocument/wav?key=${
                          process.env.REACT_APP_PERSONAL_AUTH_KEY
                        }`;
                        await fetch(URL, {
                          body: FD,
                          method: "POST",
                        });
                        submitAssignment(
                          assignment,
                          `${userData.user_data.name
                            .split(" ")
                            .join("")}-${storyDetails.title
                            .split(" ")
                            .join("")}.wav`,
                          assignmentData.accuracy,
                          assignmentData.missedWords,
                          score,
                          assignmentData.transcript,
                          storyDetails._id
                        );
                        setQuestionsSubmitted(true);
                      }
                    } else {
                      setQuestionsSubmitted(true);
                    }
                  }}
                  alreadySubmitted={questionsSubmitted}
                  previousAnswers={previousAnswers}
                  setShowQuestions={setShowQuestions}
                  hideCorrectAnswers={assignment ? true : false}
                  recordingSubmitted={
                    assignmentData?.mediaBlobUrl ? true : false
                  }
                />
              </SimpleBar>
            )}
            {assignment && (
              <AssignmentHeader
                storyDetails={storyDetails}
                setAssignmentData={setAssignmentData}
              />
            )}
          </div>

          {width > 1024 ? (
            <>
              <div className="story__container-page-flip">
                {userData.user_data.username &&
                  userData.user_data.username !== "" &&
                  `${process.env.REACT_APP_NFT_ADMINS}`.includes(
                    userData.user_data.username
                  ) && (
                    <>
                      <Link
                        to={`/edit-images/${storyDetails._id}`}
                        target="_blank"
                      >
                        Edit Images
                      </Link>
                      <div
                        className="story__edit-button"
                        onClick={() => setEditStory(!editStory)}
                      >
                        Edit{" "}
                        {page === 0
                          ? "Cover/Story Attributes"
                          : "Page Attributes"}
                      </div>
                    </>
                  )}
                {/* {userData.displayTutorial && tutorialPoint === 0 && (
                <div className="story__tutorial--start">
                  <span
                    className="story__tutorial--stop"
                    onClick={() => stopDisplayingTutorial()}
                  >
                    x
                  </span>
                  <div className="story__tutorial--start-prompt">
                    Get Started!
                  </div>
                </div>
              )} */}
                {/* {page === 0 && (
                <button
                  onClick={() => {
                    setPage(page + 1);
                    // if (tutorialPoint === 0) {
                    //   setTutorialPoint(tutorialPoint + 1);
                    // }
                  }}
                  className="story__start"
                >
                  START
                </button>
              )} */}

                <div className="story__arrows-progress-container">
                  {/* {page < storyDetails.pages.length && page > 0 && (
                  <button
                    className="story__next-button"
                    onClick={() => {
                      flipPageForward(
                        page,
                        setPage,
                        setShowQuestions,
                        storyDetails
                      );
                      onClick("", [""], [], 0);
                    }}
                  >
                    <img
                      className="story__next-arrow--img"
                      src={`${process.env.REACT_APP_AWS_BUCKET_URL}/arrow-right.svg`}
                      alt={"No Image"}
                    />
                  </button>
                )}
                {page === storyDetails.pages.length && !showQuestions && (
                  <div
                    className="story__next-placeholder story__questions-text"
                    onClick={() => setShowQuestions(true)}
                  >
                    Questions
                  </div>
                )}

                {page > 0 && (
                  <button
                    className="story__back-button"
                    onClick={() => {
                      if (showQuestions) {
                        setShowQuestions(false);
                      } else {
                        flipPageBack(page, setPage);
                      }
                      onClick("", [""], [], 0);
                    }}
                  >
                    <img
                      className="story__back-arrow--img"
                      src={`${process.env.REACT_APP_AWS_BUCKET_URL}/arrow-left.svg`}
                      alt={"No Image"}
                    />
                  </button>
                )} */}

                  {
                    <div className="story__page-number">
                      {/* <span className="story__page-number--label">Page</span>{" "}
                    {page}
                    <span className="story__page-number--label-2">
                      / {storyDetails.pages.length}
                    </span> */}
                      <span
                        className="story__page-number--options"
                        onClick={() =>
                          setShowProgressBarDropdown(!showProgressBarDropdown)
                        }
                      >
                        <i className="fa fa-text" style={{ fontSize: "1rem" }}>
                          T
                        </i>
                        <i
                          className="fa fa-text"
                          style={{ fontSize: "1.8rem" }}
                        >
                          T
                        </i>
                        {showProgressBarDropdown && (
                          <div className="story__page-number--dropdown">
                            <div className="story__page-number--dropdown-arrow"></div>
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                setOverrideFontSize(
                                  (prev: number) => prev - 0.2
                                );
                              }}
                              className="story__page-number--font-size"
                            >
                              <i
                                className="fa fa-text"
                                style={{ fontSize: "1rem" }}
                              >
                                A
                              </i>
                            </span>
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                setOverrideFontSize(
                                  (prev: number) => prev + 0.2
                                );
                              }}
                              className="story__page-number--font-size"
                            >
                              <i
                                className="fa fa-text"
                                style={{ fontSize: "1.8rem" }}
                              >
                                A
                              </i>
                            </span>
                          </div>
                        )}
                      </span>

                      <span className="story__page-number--label">
                        Progress
                      </span>
                      <progress
                        className="story__progress-bar"
                        value={scrollDistance}
                        max={
                          storyRef.current
                            ? storyRef.current.children[0].children[0]
                                .children[1].children[0].children[0]
                                .scrollHeight - 1200
                            : undefined
                        }
                      ></progress>
                    </div>
                  }
                </div>
              </div>
              {editStory ? (
                <div className="story__container-right">
                  <EditStory storyDetails={storyDetails} currentPage={page} />
                </div>
              ) : (
                <div className="story__container-right">
                  <div className="story__modal">
                    <div className="story__modal--img-holder">
                      {modalProperties.imageNumber > 0 && displayArrows && (
                        <div>
                          <button
                            className="story__modal--img-back"
                            onClick={() =>
                              setModalProperties((prev: ModalProps) => ({
                                ...prev,
                                imageNumber: modalProperties.imageNumber - 1,
                              }))
                            }
                          >
                            <i className="fas fa-chevron-left"></i>
                          </button>
                        </div>
                      )}
                      {modalProperties.images &&
                      modalProperties.images.length > 0 &&
                      modalProperties.images[modalProperties.imageNumber] ? (
                        <img
                          src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${
                            modalProperties.images[modalProperties.imageNumber]
                          }`}
                          onError={(e: any) => {
                            if (!extensionsTried.jpg) {
                              e.target.src = `${
                                process.env.REACT_APP_AWS_BUCKET_URL
                              }/${modalProperties.images![
                                modalProperties.imageNumber
                              ].replace("webp", "jpg")}`;
                              setExtensionsTried((prev: any) => ({
                                ...prev,
                                jpg: true,
                              }));
                            } else if (!extensionsTried.png) {
                              e.target.src = `${
                                process.env.REACT_APP_AWS_BUCKET_URL
                              }/${modalProperties
                                .images![modalProperties.imageNumber].replace(
                                  "webp",
                                  "png"
                                )
                                .replace("jpg", "png")}`;
                              setExtensionsTried((prev: any) => ({
                                ...prev,
                                png: true,
                              }));
                            } else if (!extensionsTried.jpeg) {
                              e.target.src = `${
                                process.env.REACT_APP_AWS_BUCKET_URL
                              }/${modalProperties
                                .images![modalProperties.imageNumber].replace(
                                  "webp",
                                  "jpeg"
                                )
                                .replace("png", "jpeg")}`;
                              setExtensionsTried((prev: any) => ({
                                ...prev,
                                jpeg: true,
                              }));
                            } else {
                              setModalProperties((prev: any) => ({
                                ...prev,
                                images: [],
                              }));
                            }
                          }}
                          alt={
                            modalProperties.images[modalProperties.imageNumber]
                          }
                        />
                      ) : modalProperties.show ? (
                        <div className="story__modal--no-img-div">
                          <div className="story__modal--no-img">
                            {modalProperties.word}
                          </div>
                          {/* <img
                        className="story__modal--img story__modal--placeholder-svg"
                        src={`${process.env.REACT_APP_AWS_BUCKET_URL}/no-image.svg`}
                        alt={"No Image"}
                      />
                      <div className="story__modal--img-text">
                        Sorry, no image for this word yet.
                      </div> */}
                        </div>
                      ) : (
                        <div className="story__modal--img-div">
                          <img
                            className="story__modal--img story__modal--placeholder-svg"
                            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/click-word-splash.svg`}
                            alt={"Default Image"}
                          />
                          <div className="story__modal--img-text">
                            Click the word when you get stuck!
                          </div>
                        </div>
                      )}

                      {modalProperties.images &&
                        modalProperties.imageNumber <
                          modalProperties.images.length - 1 &&
                        displayArrows && (
                          <div>
                            <button
                              className="story__modal--img-next"
                              onClick={() =>
                                setModalProperties((prev: ModalProps) => ({
                                  ...prev,
                                  imageNumber: modalProperties.imageNumber + 1,
                                }))
                              }
                            >
                              <i className="fas fa-chevron-right img-chevron-right"></i>
                            </button>
                          </div>
                        )}
                    </div>

                    {modalProperties.show &&
                      modalProperties.word !== "" &&
                      modalProperties.phonics.length !== 0 && (
                        <div className="story__modal--word-wrapper">
                          <span className="story__modal--word">
                            {modalProperties.word}
                          </span>
                          <Link
                            to={`/associatedWords/${modalProperties.word}`}
                            target="_blank"
                            className="story__modal--practice-phonics"
                          >
                            Similar words
                            <i className="fa fa-chevron-right story__modal--chev-right"></i>
                          </Link>
                        </div>
                      )}
                    {modalProperties.phonics.length !== 0 && (
                      <div className="story__modal--word-sound-wrapper">
                        <div className="story__modal--phonics">
                          {modalProperties.phonics.map(
                            (phonic, phonicIndex) => {
                              let currentPhonics: Phonics[] = [];
                              for (const phoneme of phonic.phonemes) {
                                const foundPhonic = storyData.phonics.find(
                                  (fullPhonic) => fullPhonic.phoneme === phoneme
                                );
                                if (foundPhonic) {
                                  currentPhonics.push(foundPhonic);
                                }
                              }
                              const indexOfWord = trackedWords.findIndex(
                                (val) => val.word === modalProperties.word
                              );

                              return (
                                <React.Fragment key={generateID()}>
                                  <div className="story__modal--phonic-part-container">
                                    <span
                                      className={`${
                                        trackedWords[
                                          indexOfWord
                                        ].positions.includes(phonic.position)
                                          ? phonic.position === 1
                                            ? "story__phonic-part--first "
                                            : phonic.position ===
                                              modalProperties.phonics.length
                                            ? "story__phonic-part--last "
                                            : phonic.position === 2
                                            ? "story__phonic-part--second "
                                            : phonic.position ===
                                              modalProperties.phonics.length - 1
                                            ? "story__phonic-part--penultimate "
                                            : "story__phonic-part--middle "
                                          : ""
                                      }${
                                        modalProperties.phonics.length > 7
                                          ? "story__modal--phonic-part-small"
                                          : "story__modal--phonic-part"
                                      }`}
                                    >
                                      {`${phonic.part}`}
                                    </span>
                                    <span
                                      className={`${
                                        trackedWords[
                                          indexOfWord
                                        ].positions.includes(phonic.position)
                                          ? phonic.position === 1
                                            ? "story__phonic-click--first "
                                            : phonic.position ===
                                              modalProperties.phonics.length
                                            ? "story__phonic-click--last "
                                            : phonic.position === 2
                                            ? "story__phonic-click--second "
                                            : phonic.position ===
                                              modalProperties.phonics.length - 1
                                            ? "story__phonic-click--penultimate "
                                            : "story__phonic-click--middle "
                                          : ""
                                      }${
                                        modalProperties.phonics.length > 7
                                          ? "story__modal--phonic-click-small"
                                          : "story__modal--phonic-click"
                                      }`}
                                      ref={
                                        phonicIndex === 0
                                          ? firstPhonicRef
                                          : undefined
                                      }
                                      onClick={() => {
                                        clickPhonic(
                                          modalProperties.word,
                                          modalProperties.definitions,
                                          modalProperties.phonics,
                                          phonic.phonemes.length > 1 &&
                                            phonic.multiple
                                            ? currentPhonics
                                                .map(
                                                  (fullPhonic) =>
                                                    fullPhonic.name
                                                )
                                                .join(" AND ")
                                            : !phonic.multiple
                                            ? currentPhonics
                                                .map(
                                                  (fullPhonic) =>
                                                    fullPhonic.name
                                                )
                                                .join(" OR ")
                                            : currentPhonics.map(
                                                (fullPhonic) => fullPhonic.name
                                              )[0],
                                          phonic.position
                                        );
                                      }}
                                    ></span>
                                    {currentPhonics.map(
                                      (fullPhonic, fullPhonicIndex) => {
                                        return (
                                          <React.Fragment key={generateID()}>
                                            <div
                                              onClick={() => {
                                                clickPhonic(
                                                  modalProperties.word,
                                                  modalProperties.definitions,
                                                  modalProperties.phonics,
                                                  phonic.phonemes.length > 1 &&
                                                    phonic.multiple
                                                    ? currentPhonics
                                                        .map(
                                                          (fullPhonic) =>
                                                            fullPhonic.name
                                                        )
                                                        .join(" AND ")
                                                    : !phonic.multiple
                                                    ? currentPhonics
                                                        .map(
                                                          (fullPhonic) =>
                                                            fullPhonic.name
                                                        )
                                                        .join(" OR ")
                                                    : currentPhonics.map(
                                                        (fullPhonic) =>
                                                          fullPhonic.name
                                                      )[0],
                                                  phonic.position,
                                                  true
                                                );
                                                setTimeout(() => {
                                                  let audioToPlay: any =
                                                    document.getElementById(
                                                      `phonic-audio-${fullPhonic.audio}`
                                                    );
                                                  audioToPlay.play();
                                                }, 0);
                                              }}
                                            >
                                              <audio
                                                id={`phonic-audio-${fullPhonic.audio}`}
                                              >
                                                <source
                                                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${fullPhonic.audio}`}
                                                ></source>
                                              </audio>
                                              <i className="fas fa-volume-up story__modal--phonic-sound-icon"></i>
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </div>
                                </React.Fragment>
                              );
                            }
                          )}
                        </div>
                        {modalProperties.show && (
                          <React.Fragment key={generateID()}>
                            <div
                              onClick={() => {
                                let audioToPlay: any = document.getElementById(
                                  `word-audio-${modalProperties.word}`
                                );
                                audioToPlay.play();
                              }}
                            >
                              <audio id={`word-audio-${modalProperties.word}`}>
                                <source
                                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${modalProperties.audio}`}
                                ></source>
                              </audio>
                              <i
                                className="fas fa-volume-up story__modal--sound-icon"
                                ref={wordAudioRef}
                              ></i>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="story__tracking-container--title">
                    <div>
                      <span
                        className={`story__tracking-container--number${
                          userData._id !== "" ? "" : " blur"
                        }`}
                      >
                        {trackedWords.length}
                      </span>{" "}
                      <span
                        className={`story__tracking-container--text${
                          userData._id !== "" ? "" : " blur"
                        }`}
                      >
                        Tracked Words to Revisit:
                      </span>
                    </div>

                    <div>
                      {userData.account_type === "parent" && (
                        <button
                          className="story__tracking-section--login-prompt"
                          onClick={() => showChildProfilesModal("read")}
                        >
                          Switch to your child's profile to track missed words!
                        </button>
                      )}
                    </div>
                  </div>

                  <div
                    className={`story__tracking-container${
                      userData.account_type === "student" ? "" : " blur"
                    }`}
                  >
                    {trackedWords.map((wordProperties) => {
                      return (
                        <React.Fragment key={generateID()}>
                          <div className="story__tracking-section">
                            {wordProperties.phonics.map((phonic, index) => {
                              let currentPhonics: Phonics[] = [];
                              for (const phoneme of phonic.phonemes) {
                                const foundPhonic = storyData.phonics.find(
                                  (fullPhonic) => fullPhonic.phoneme === phoneme
                                );
                                if (foundPhonic) {
                                  currentPhonics.push(foundPhonic);
                                }
                              }

                              return (
                                <React.Fragment key={generateID()}>
                                  <div className="story__tracking-section--phonic-part-container">
                                    <span
                                      className={`${
                                        trackedWords[
                                          trackedWords.findIndex(
                                            (val) =>
                                              val.word === wordProperties.word
                                          )
                                        ].positions.includes(phonic.position)
                                          ? phonic.position === 1
                                            ? "story__phonic-part--first "
                                            : phonic.position ===
                                              wordProperties.phonics.length
                                            ? "story__phonic-part--last "
                                            : phonic.position === 2
                                            ? "story__phonic-part--second "
                                            : phonic.position ===
                                              wordProperties.phonics.length - 1
                                            ? "story__phonic-part--penultimate "
                                            : "story__phonic-part--middle "
                                          : ""
                                      }${
                                        wordProperties.phonics.length > 7
                                          ? "story__tracking-section--phonic-part-small"
                                          : "story__tracking-section--phonic-part"
                                      }`}
                                    >
                                      {`${phonic.part}`}
                                    </span>
                                    <span
                                      className={`${
                                        trackedWords[
                                          trackedWords.findIndex(
                                            (val) =>
                                              val.word === wordProperties.word
                                          )
                                        ].positions.includes(phonic.position)
                                          ? phonic.position === 1
                                            ? "story__phonic-click--first "
                                            : phonic.position ===
                                              wordProperties.phonics.length
                                            ? "story__phonic-click--last "
                                            : phonic.position === 2
                                            ? "story__phonic-click--second "
                                            : phonic.position ===
                                              wordProperties.phonics.length - 1
                                            ? "story__phonic-click--penultimate "
                                            : "story__phonic-click--middle "
                                          : ""
                                      }${
                                        wordProperties.phonics.length > 7
                                          ? "story__tracking-section--phonic-click-small"
                                          : "story__tracking-section--phonic-click"
                                      }`}
                                      onClick={() =>
                                        clickPhonic(
                                          wordProperties.word,
                                          wordProperties.definitions,
                                          wordProperties.phonics,
                                          phonic.phonemes.length > 1 &&
                                            phonic.multiple
                                            ? currentPhonics
                                                .map(
                                                  (fullPhonic) =>
                                                    fullPhonic.name
                                                )
                                                .join(" AND ")
                                            : !phonic.multiple
                                            ? currentPhonics
                                                .map(
                                                  (fullPhonic) =>
                                                    fullPhonic.name
                                                )
                                                .join(" OR ")
                                            : currentPhonics.map(
                                                (fullPhonic) => fullPhonic.name
                                              )[0],
                                          phonic.position
                                        )
                                      }
                                    ></span>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                            <button
                              className="story__tracking-section--remove-word"
                              onClick={() => removeWord(wordProperties.word)}
                            >
                              <i className="fas fa-minus story__tracking-section--minus-icon"></i>
                            </button>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {/* mobile */}

              <StoryProgressBar
                permTrackedWords={permTrackedWords}
                // setShowHeader={setShowHeader}
                scrollDistance={scrollDistance}
                wordContainerRef={wordContainerRef}
                storyWrapperRef={storyWrapperRef}
                setOverrideFontSize={setOverrideFontSize}
                modalProperties={modalProperties}
                trackedWords={trackedWords}
                clickPhonic={clickPhonic}
                storyPages={storyDetails.pages.sort(
                  (first, second) => first.page_number - second.page_number
                )}
                onClick={onClick}
                setOverrideWordHighlight={setOverrideWordHighlight}
                lineDetails={lineDetails}
                setLineDetails={setLineDetails}
                setLine={setLine}
                setModalProperties={setModalProperties}
                voiceWordProperties={voiceWordProperties}
              />
            </>
          )}
        </div>
      </div>
    )
  ) : (
    <div className="story__loading">
      <div className="story__loading--text">
        <div>Loading</div>
        <div className="story__loading--lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div className="story__loading--globe">
        <div className="story__loading--bird">
          <div className="story__loading--body">
            <div className="story__loading--eye story__loading--left"></div>
            <div className="story__loading--eye story__loading--right"></div>
            <div className="story__loading--beak">
              <div></div>
            </div>
            <div className="story__loading--feet"></div>
            <div className="story__loading--wire"></div>
          </div>
          <div className="story__loading--hills"></div>
          <div className="story__loading--cloud"></div>
          <div className="story__loading--cloud story__loading--small"></div>
        </div>
      </div>
    </div>
  );
};

export default StoryComponent;
