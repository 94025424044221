import React, { useLayoutEffect, useRef, useState } from "react";
import Board from "../Components/Games/TicTacToe/Board";
import { useUserData } from "../Hooks/useUserData";
import { calculateWinner, generateID } from "../Utils";

const TicTacToe: React.FC = () => {
  const { userData } = useUserData();
  const [history, setHistory] = useState([Array(9).fill(null)]);
  const [stepNumber, setStepNumber] = useState(0);
  const [xIsNext, setXIsNext] = useState(true);
  const winner = calculateWinner(history[stepNumber])?.winner;
  const winningSquares = calculateWinner(history[stepNumber])?.squares;
  const tie = history[9] && !history[9].includes(null) && !winner;
  const [donePickingPlayers, setDonePickingPlayers] = useState(false);
  const p1Ref = useRef<HTMLInputElement>(null);
  const p2Ref = useRef<HTMLInputElement>(null);
  const [playerNames, setPlayerNames] = useState({
    p1: userData.user_data.name,
    p2: "Player 2",
  });
  const [players, setPlayers] = useState({ p1: "b", p2: "d" });
  const [error, setError] = useState({
    type: "error",
    isActive: false,
    message: "",
  });
  const xO = xIsNext ? players.p1 : players.p2;

  useLayoutEffect(() => {
    setPlayerNames((prev: any) => ({ ...prev, p1: userData.user_data.name }));
  }, [userData]);

  const handeClick = (index: number) => {
    const historyPoint = history.slice(0, stepNumber + 1);
    const current = historyPoint[stepNumber];
    const squares = [...current];
    if (winner || squares[index]) return;
    squares[index] = xO;
    setHistory([...historyPoint, squares]);
    setStepNumber(historyPoint.length);
    setXIsNext(!xIsNext);
  };

  const jumpTo = (step: number) => {
    if (step === 0) {
      setHistory([Array(9).fill(null)]);
    }
    setStepNumber(step);
    setXIsNext(step % 2 === 0);
  };

  const renderMoves = () =>
    history.map((_step, move) => {
      const destination = move ? `Go to move #${move}` : "Go to Start";
      return (
        <li className="tic-tac-toe__li" key={generateID()}>
          <div
            className="tic-tac-toe__info-wrapper--jump-to"
            onClick={() => jumpTo(move)}
          >
            {destination}
          </div>
        </li>
      );
    });

  const resetError = () =>
    setError((prev: any) => ({ ...prev, isActive: false }));

  const resetGame = () => {
    setHistory([Array(9).fill(null)]);
    setStepNumber(0);
    setXIsNext(true);
    setDonePickingPlayers(false);
  };

  return (
    <div className="tic-tac-toe">
      <ul className="tic-tac-toe__bg-bubbles">
        <li>x</li>
        <li>o</li>
        <li>o</li>
        <li>x</li>
        <li>x</li>
        <li>o</li>
        <li>x</li>
        <li>o</li>
        <li>x</li>
        <li>o</li>
      </ul>

      {error.isActive && (
        <div className="tic-tac-toe__error">{error.message}</div>
      )}

      {!donePickingPlayers && (
        <div className="tic-tac-toe__z-wrapper">
          <div className="tic-tac-toe__headline">Tic Tac Toe</div>
          <div className="tic-tac-toe__players-start-wrapper">
            <div className="tic-tac-toe__players-wrapper">
              <div className="tic-tac-toe__player-column">
                <label className="tic-tac-toe__label">Player 1:</label>
                <input
                  type="text"
                  placeholder="Player 1"
                  className="tic-tac-toe__name-input"
                  defaultValue={userData.user_data.name}
                  onChange={(e) => {
                    setPlayerNames((prev: any) => ({
                      ...prev,
                      p1: e.target.value,
                    }));
                  }}
                />

                <label className="tic-tac-toe__label-2">Letter or Word:</label>
                <input
                  className="tic-tac-toe__input"
                  type="text"
                  maxLength={7}
                  placeholder="b"
                  defaultValue={players.p1}
                  ref={p1Ref}
                />
              </div>
              <div className="tic-tac-toe__player-column">
                <label className="tic-tac-toe__label">Player 2:</label>
                <input
                  type="text"
                  className="tic-tac-toe__name-input"
                  placeholder="Player 2"
                  defaultValue={"Player 2"}
                  onChange={(e) => {
                    setPlayerNames((prev: any) => ({
                      ...prev,
                      p2: e.target.value,
                    }));
                  }}
                />
                <label className="tic-tac-toe__label-2">Letter or Word:</label>
                <input
                  className="tic-tac-toe__input"
                  type="text"
                  maxLength={7}
                  placeholder="d"
                  defaultValue={players.p2}
                  ref={p2Ref}
                />
              </div>
            </div>
            <div className="tic-tac-toe__instructions">
              These will replace the X's and O's in the game!
              <div className="tic-tac-toe__instructions-2">
                Choose letters or words that you want to practice.
              </div>
            </div>
            <div
              className="tic-tac-toe__default-button"
              onClick={() => {
                if (p1Ref.current && p2Ref.current) {
                  if (p1Ref.current.value === "") {
                    setPlayers((prev: any) => ({ ...prev, p1: "b" }));
                  } else {
                    setPlayers((prev: any) => ({
                      ...prev,
                      p1: p1Ref.current?.value,
                    }));
                  }
                  if (p2Ref.current.value === "") {
                    setPlayers((prev: any) => ({ ...prev, p2: "d" }));
                  } else {
                    setPlayers((prev: any) => ({
                      ...prev,
                      p2: p2Ref.current?.value,
                    }));
                  }
                  if (
                    p1Ref.current.value !== "" &&
                    p1Ref.current.value === p2Ref.current.value
                  ) {
                    setError({
                      type: "error",
                      isActive: true,
                      message: "Players must have different letters/words",
                    });
                  } else {
                    resetError();
                    setDonePickingPlayers(true);
                  }
                }
              }}
            >
              Play!
            </div>
          </div>
        </div>
      )}

      {donePickingPlayers && (
        <>
          <div className="tic-tac-toe__z-wrapper-play">
            <div className="tic-tac-toe__players-start-wrapper">
              {/* <div className="tic-tac-toe__headline">Tic Tac Toe</div> */}
              <div className="tic-tac-toe__players-wrapper">
                <div className="tic-tac-toe__player-column">
                  <div
                    className={
                      xO === players.p1
                        ? "tic-tac-toe__player-highlight"
                        : "tic-tac-toe__player-highlight--empty"
                    }
                  >
                    <div className="tic-tac-toe__turn-indicator">
                      {xO === players.p1 ? "Your turn" : ""}
                    </div>
                    <label className="tic-tac-toe__label">
                      {playerNames.p1}
                    </label>
                  </div>
                  <span className="tic-tac-toe__span">{players.p1}</span>
                </div>
                <div className="tic-tac-toe__player-column">
                  <div
                    className={
                      xO === players.p2
                        ? "tic-tac-toe__player-highlight"
                        : "tic-tac-toe__player-highlight--empty"
                    }
                  >
                    <div className="tic-tac-toe__turn-indicator">
                      {xO === players.p2 ? "Your turn" : ""}
                    </div>
                    <label className="tic-tac-toe__label">
                      {playerNames.p2}
                    </label>
                  </div>
                  <span className="tic-tac-toe__span">{players.p2}</span>
                </div>
              </div>
              <div className="tic-tac-toe__buttons-wrapper">
                {winner || tie ? (
                  <>
                    <div
                      className="tic-tac-toe__default-button"
                      onClick={() => resetGame()}
                    >
                      Change letters
                    </div>
                    <div
                      className="tic-tac-toe__default-button"
                      onClick={() => jumpTo(0)}
                    >
                      Play Again?
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="tic-tac-toe__default-button"
                      onClick={() => resetGame()}
                    >
                      Change letters
                    </div>
                    <div
                      className="tic-tac-toe__default-button"
                      onClick={() => jumpTo(0)}
                    >
                      Restart
                    </div>
                  </>
                )}
              </div>
            </div>
            <Board
              winner={winner}
              tie={tie}
              players={players}
              playerNames={playerNames}
              winningSquares={winningSquares}
              squares={history[stepNumber]}
              onClick={handeClick}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TicTacToe;
