import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { useStories } from "../Hooks/useStories";
import StoryPageText from "../Components/Partials/StoryPageText";
import { StylesContextProvider } from "../Context/StylesContext";
import { Phonics, Story, StoryPage, WordPhonics } from "../Models/Stories";
import { generateID } from "../Utils/GenerateID";
import { useUserData } from "../Hooks/useUserData";
import { StudentData, TrackedWords } from "../Models";
import { GetBaseURL } from "../Utils";

// interface MatchParams {
//   storyID: string;
// }

type DocumentNames = {
  titles: string[];
  imageFilenames: string[];
};

type EditStoryProps = {
  storyDetails: Story;
  currentPage: number;
};

// interface ComponentProps extends RouteComponentProps<MatchParams> {}

const EditStory: React.FC<EditStoryProps> = ({ storyDetails, currentPage }) => {
  const { storyData, UpdateStories } = useStories();
  const { userData } = useUserData();
  // const [storyDetails, setStoryDetails] = useState<Story | undefined>();
  const [newStoryDetails, setNewStoryDetails] = useState<Story>(storyDetails);
  const tagsRef = useRef<HTMLInputElement>(null);
  const globalFontSizeRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState({
    isActive: false,
    type: "error",
    message: "",
  });

  useEffect(() => {
    if (storyDetails) {
      setNewStoryDetails((prev: Story) => ({
        ...prev,
        pages: prev.pages.sort(
          (first, second) => first.page_number - second.page_number
        ),
      }));
    }
  }, [storyDetails]);

  const updateStory = async () => {
    const request = await fetch(
      `${GetBaseURL()}/api/stories/update/${storyDetails._id}?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ newStoryDetails }),
      }
    );

    const response = await request.json();

    request.status === 200
      ? setError({ type: "success", isActive: true, message: response.message })
      : setError({ type: "error", isActive: true, message: response.message });

    UpdateStories();
  };

  const updateGlobalFontSize = async () => {
    if (globalFontSizeRef.current && globalFontSizeRef.current.value !== "") {
      let storyDetailsToSend = newStoryDetails;
      for (const page of storyDetailsToSend.pages) {
        page.font_size = globalFontSizeRef.current.value.trim();
      }

      const request = await fetch(
        `${GetBaseURL()}/api/stories/update/${storyDetails._id}?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ newStoryDetails: storyDetailsToSend }),
        }
      );

      const response = await request.json();

      request.status === 200
        ? setError({
            type: "success",
            isActive: true,
            message: response.message,
          })
        : setError({
            type: "error",
            isActive: true,
            message: response.message,
          });

      UpdateStories();
    } else {
      setError({
        type: "error",
        isActive: true,
        message: "Invalid input for global font size.",
      });
    }
  };

  const submitFile = async (input: HTMLInputElement) => {
    if (input) {
      if (input.files === null || input.files.length === 0) {
        console.log("No file submitted");
      } else {
        const FD = new FormData();
        FD.append(
          input.id.includes("cover-image")
            ? newStoryDetails.title
            : `${newStoryDetails.title} ${input.id
                .replace("page-", "")
                .replace("-image-webp", "")
                .replace("-image-jpg", "")
                .replace("-image-png", "")}`,
          input.files[0]
        );

        const splitFileName = input.files[0].name.split(".");
        const URL = `${GetBaseURL()}/api/stories/addStoryDocument/${
          splitFileName[splitFileName.length - 1]
        }?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`;

        const request = await fetch(URL, {
          body: FD,
          method: "POST",
        });
        const response = await request.json();
        request.status === 200
          ? setError({
              isActive: true,
              type: "success",
              message: response.message,
            })
          : setError({
              isActive: true,
              type: "error",
              message: response.message,
            });
      }
    } else {
      console.log("Not current");
    }
  };

  const updateCSS = (
    page: number,
    attribute:
      | "font_size"
      | "line_height"
      | "top"
      | "right"
      | "margin_bottom"
      | "text_align"
      | "word_margin"
      | "word_tb_padding"
      | "word_rl_padding"
      | "word_container_width"
      | "font_color"
  ) => {
    let mutablePages = newStoryDetails!.pages;
    if (attribute === "text_align") {
      const currentAttribute = (
        document.getElementById(
          `page-${newStoryDetails.pages[page - 1].page_number}-${attribute}`
        ) as HTMLInputElement
      ).value as
        | "start"
        | "end"
        | "left"
        | "right"
        | "center"
        | "justify"
        | "match-parent"
        | "";
      mutablePages[page - 1].text_align =
        currentAttribute !== ""
          ? currentAttribute
          : mutablePages[page - 1].text_align;
    } else {
      const currentAttribute = (
        document.getElementById(
          `page-${newStoryDetails.pages[page - 1].page_number}-${attribute}`
        ) as HTMLInputElement
      ).value;
      mutablePages[page - 1][attribute] =
        currentAttribute !== ""
          ? currentAttribute
          : mutablePages[page - 1][attribute];
    }
    setNewStoryDetails((prev: Story) => ({
      ...prev,
      pages: mutablePages,
    }));
    updateStory();
  };

  return userData.user_data.username &&
    userData.user_data.username !== "" &&
    `${process.env.REACT_APP_NFT_ADMINS}`.includes(
      userData.user_data.username
    ) ? (
    storyDetails ? (
      <div className="edit-story">
        <label>Title</label>
        <span>
          If you'd like to change this attribute, please message the dev team
          and we'll do it internally.
        </span>
        <label>Cover</label>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="edit-story__image-upload-container">
            <span>WEBP</span>
            <input
              type="file"
              id={`cover-image-webp`}
              style={{ width: "auto" }}
            />
            <img
              style={{ width: "100px" }}
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${storyDetails.title
                .split(" ")
                .join("")}.webp`}
              alt="No WEBP"
            />
            <div
              className="edit-story__submit"
              onClick={() =>
                submitFile(
                  document.getElementById(
                    `cover-image-webp`
                  ) as HTMLInputElement
                )
              }
            >
              Submit WEBP
            </div>
          </div>
          <div className="edit-story__image-upload-container">
            <span>JPG</span>
            <input
              type="file"
              id={`cover-image-jpg`}
              style={{ width: "auto" }}
            />
            <img
              style={{ width: "100px" }}
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${storyDetails.title
                .split(" ")
                .join("")}.jpg`}
              alt="No JPG"
            />
            <div
              className="edit-story__submit"
              onClick={() =>
                submitFile(
                  document.getElementById(`cover-image-jpg`) as HTMLInputElement
                )
              }
            >
              Submit JPG
            </div>
          </div>
          <div className="edit-story__image-upload-container">
            <span>PNG</span>
            <input
              type="file"
              id={`cover-image-png`}
              style={{ width: "auto" }}
            />
            <img
              style={{ width: "100px" }}
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${storyDetails.title
                .split(" ")
                .join("")}.png`}
              alt="No PNG"
            />
            <div
              className="edit-story__submit"
              onClick={() =>
                submitFile(
                  document.getElementById(`cover-image-png`) as HTMLInputElement
                )
              }
            >
              Submit PNG
            </div>
          </div>
        </div>
        <span>
          <label>Level</label>
          <input
            key={generateID()}
            type="text"
            placeholder={storyDetails.level}
            defaultValue={storyDetails.level}
            id="level"
            onBlur={() => {
              newStoryDetails.level =
                (document.getElementById("level") as HTMLInputElement).value !==
                ""
                  ? (document.getElementById("level") as HTMLInputElement).value
                  : storyDetails.level;

              updateStory();
            }}
          />
        </span>
        <span>
          <label>Priority Tag</label>
          <input
            key={generateID()}
            type="text"
            placeholder={storyDetails.priority_tag}
            defaultValue={storyDetails.priority_tag}
            id="priority-tag"
            onBlur={() => {
              newStoryDetails.priority_tag =
                (document.getElementById("priority-tag") as HTMLInputElement)
                  .value !== ""
                  ? (
                      document.getElementById(
                        "priority-tag"
                      ) as HTMLInputElement
                    ).value
                  : storyDetails.priority_tag;

              updateStory();
            }}
          />
        </span>
        <label>Tags</label>
        <div>
          {newStoryDetails.tags.map((tag, index) => {
            return (
              <div>
                <span key={index}>{tag}</span>{" "}
                <span
                  onClick={() => {
                    let filteredTags = newStoryDetails.tags.filter((string) => {
                      return string !== tag;
                    });
                    newStoryDetails.tags = filteredTags;

                    updateStory();
                  }}
                >
                  X
                </span>
              </div>
            );
          })}
          <div>
            <input
              ref={tagsRef}
              type="text"
              placeholder="Tags"
              data-cy="storiesUpload__input--tags"
              className="storiesUpload__tags-input"
            />
            <button
              data-cy="storiesUpload__submit--tags"
              onClick={(e) => {
                e.preventDefault();
                if (tagsRef.current && tagsRef.current.value !== "") {
                  const newTag = tagsRef.current.value;
                  newStoryDetails.tags.push(newTag);
                  tagsRef.current!.value = "";
                  updateStory();
                }
              }}
            >
              Add Tags
            </button>
          </div>
          <span>
            Global Font Size (applies to every page, specify units as rem or px)
          </span>
          <input
            key={generateID()}
            ref={globalFontSizeRef}
            type="text"
            placeholder={`${newStoryDetails.pages[0].font_size}`}
            defaultValue={newStoryDetails.pages[0].font_size}
            id={`page-${newStoryDetails.pages[0].page_number}-global-font_size`}
            onBlur={() => {
              updateGlobalFontSize();
            }}
          />
        </div>

        {newStoryDetails.pages.map((storyPage) => (
          <>
            <span style={{ fontSize: "1.5rem", marginTop: "50px" }}>
              The following styles will apply to page {storyPage.page_number}:
            </span>
            <span>
              <label>Font Size (specify units as rem or px)</label>
              <input
                key={generateID()}
                type="text"
                placeholder={`${storyPage.font_size}`}
                defaultValue={storyPage.font_size}
                id={`page-${storyPage.page_number}-font_size`}
                onBlur={() => {
                  updateCSS(storyPage.page_number, "font_size");
                }}
              />
            </span>
            <span>
              <label>
                Font Color (text or rgb(x, y, z) or hexcode [precede hexcode
                with hashtag (#)])
              </label>
              <input
                key={generateID()}
                type="text"
                placeholder={`${storyPage.font_color}`}
                defaultValue={storyPage.font_color}
                id={`page-${storyPage.page_number}-font_color`}
                onBlur={() => {
                  updateCSS(storyPage.page_number, "font_color");
                }}
              />
            </span>
            <span>
              <label>Line Height (specify units if you want)</label>
              <input
                key={generateID()}
                type="text"
                placeholder={`${storyPage.line_height}`}
                defaultValue={storyPage.line_height}
                id={`page-${storyPage.page_number}-line_height`}
                onBlur={() => {
                  updateCSS(storyPage.page_number, "line_height");
                }}
              />
            </span>
            <span>
              <label>Top (specify units)</label>
              <input
                key={generateID()}
                type="text"
                placeholder={`${storyPage.top}`}
                defaultValue={storyPage.top}
                id={`page-${storyPage.page_number}-top`}
                onBlur={() => {
                  updateCSS(storyPage.page_number, "top");
                }}
              />
            </span>
            <span>
              <label>Right (specify units)</label>
              <input
                key={generateID()}
                type="string"
                placeholder={`${storyPage.right}`}
                defaultValue={storyPage.right}
                id={`page-${storyPage.page_number}-right`}
                onBlur={() => {
                  updateCSS(storyPage.page_number, "right");
                }}
              />
            </span>
            <span>
              <label>Bottom margin per line (specify units)</label>
              <input
                key={generateID()}
                type="text"
                placeholder={`${storyPage.margin_bottom}`}
                defaultValue={storyPage.margin_bottom}
                id={`page-${storyPage.page_number}-margin_bottom`}
                onBlur={() => {
                  updateCSS(storyPage.page_number, "margin_bottom");
                }}
              />
            </span>
            <span>
              <label>Text-align (left, center, right, etc.)</label>
              <input
                key={generateID()}
                type="text"
                placeholder={`${storyPage.text_align}`}
                defaultValue={storyPage.text_align}
                id={`page-${storyPage.page_number}-text_align`}
                onBlur={() => {
                  updateCSS(storyPage.page_number, "text_align");
                }}
              />
            </span>
            <span>
              <label>Word margin (specify units)</label>
              <input
                key={generateID()}
                type="text"
                placeholder={`${storyPage.word_margin}`}
                defaultValue={storyPage.word_margin}
                id={`page-${storyPage.page_number}-word_margin`}
                onBlur={() => {
                  updateCSS(storyPage.page_number, "word_margin");
                }}
              />
            </span>
            <span>
              <label>Word top-bottom padding (specify units)</label>
              <input
                key={generateID()}
                type="text"
                placeholder={`${storyPage.word_tb_padding}`}
                defaultValue={storyPage.word_tb_padding}
                id={`page-${storyPage.page_number}-word_tb_padding`}
                onBlur={() => {
                  updateCSS(storyPage.page_number, "word_tb_padding");
                }}
              />
            </span>
            <span>
              <label>Word right-left padding (specify units)</label>
              <input
                key={generateID()}
                type="text"
                placeholder={`${storyPage.word_rl_padding}`}
                defaultValue={storyPage.word_rl_padding}
                id={`page-${storyPage.page_number}-word_rl_padding`}
                onBlur={() => {
                  updateCSS(storyPage.page_number, "word_rl_padding");
                }}
              />
            </span>
            <span>
              <label>Word container width (specify units)</label>
              <input
                key={generateID()}
                type="text"
                placeholder={`${storyPage.word_container_width}`}
                defaultValue={storyPage.word_container_width}
                id={`page-${storyPage.page_number}-word_container_width`}
                onBlur={() => {
                  updateCSS(storyPage.page_number, "word_container_width");
                }}
              />
            </span>
            {/* <button
          style={{ marginTop: "2rem", maxWidth: "10rem" }}
          onClick={() => {
            let mutablePages = newStoryDetails.pages;
            mutablePages = mutablePages.filter(
              (pageToRemove) => pageToRemove.page_number !== currentPage
            );
            for (const pageIndex in mutablePages) {
              if (mutablePages[pageIndex].page_number > currentPage) {
                mutablePages[pageIndex].page_number -= 1;
              }
            }
            setNewStoryDetails((prev: Story) => ({
              ...prev,
              pages: mutablePages,
            }));
          }}
        >
          Remove page
        </button> */}
            <label>{`Page ${storyPage.page_number} text (separate lines by two semicolons or newline character (Enter key))`}</label>
            <textarea
              key={generateID()}
              placeholder={storyPage.page_text}
              defaultValue={
                storyDetails.pages[storyPage.page_number - 1].page_text
              }
              id={`page-${storyPage.page_number}-text`}
              onBlur={() => {
                const currentText = (
                  document.getElementById(
                    `page-${storyPage.page_number}-text`
                  ) as HTMLTextAreaElement
                ).value.trim();

                let mutablePages = newStoryDetails.pages;
                mutablePages[storyPage.page_number - 1].page_text =
                  currentText !== ""
                    ? currentText.trim()
                    : mutablePages[storyPage.page_number - 1].page_text;
                mutablePages[storyPage.page_number - 1].lines =
                  currentText !== ""
                    ? currentText
                        .trim()
                        .replace(/\r/g, "")
                        .split(/\n/)
                        .join(";;")
                        .split(";;")
                        .map((line) => line.trim())
                    : mutablePages[storyPage.page_number - 1].lines;
                setNewStoryDetails((prev: Story) => ({
                  ...prev,
                  pages: mutablePages,
                }));
                updateStory();
              }}
            />
            <label>{`Page ${storyPage.page_number} image`}</label>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="edit-story__image-upload-container">
                <span>WEBP</span>
                <input
                  type="file"
                  id={`page-${storyPage.page_number}-image-webp`}
                  style={{ width: "auto" }}
                />
                <img
                  style={{ width: "100px" }}
                  src={`${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${storyDetails.title.split(" ").join("")}${
                    storyPage.page_number === 0 ? "" : storyPage.page_number
                  }.webp`}
                  alt="No WEBP"
                />
                <div
                  className="edit-story__submit"
                  onClick={() =>
                    submitFile(
                      document.getElementById(
                        `page-${storyPage.page_number}-image-webp`
                      ) as HTMLInputElement
                    )
                  }
                >
                  Submit WEBP
                </div>
              </div>
              <div className="edit-story__image-upload-container">
                <span>JPG</span>
                <input
                  type="file"
                  id={`page-${storyPage.page_number}-image-jpg`}
                  style={{ width: "auto" }}
                />
                <img
                  style={{ width: "100px" }}
                  src={`${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${storyDetails.title.split(" ").join("")}${
                    storyPage.page_number === 0 ? "" : storyPage.page_number
                  }.jpg`}
                  alt="No JPG"
                />
                <div
                  className="edit-story__submit"
                  onClick={() =>
                    submitFile(
                      document.getElementById(
                        `page-${storyPage.page_number}-image-jpg`
                      ) as HTMLInputElement
                    )
                  }
                >
                  Submit JPG
                </div>
              </div>
              <div className="edit-story__image-upload-container">
                <span>PNG</span>
                <input
                  type="file"
                  id={`page-${storyPage.page_number}-image-png`}
                  style={{ width: "auto" }}
                />
                <img
                  style={{ width: "100px" }}
                  src={`${
                    process.env.REACT_APP_AWS_BUCKET_URL
                  }/${storyDetails.title.split(" ").join("")}${
                    storyPage.page_number === 0 ? "" : storyPage.page_number
                  }.png`}
                  alt="No PNG"
                />
                <div
                  className="edit-story__submit"
                  onClick={() =>
                    submitFile(
                      document.getElementById(
                        `page-${storyPage.page_number}-image-png`
                      ) as HTMLInputElement
                    )
                  }
                >
                  Submit PNG
                </div>
              </div>
            </div>
          </>
        ))}

        {/* <button
          style={{ maxWidth: "10rem", margin: "2rem 0" }}
          onClick={() => {
            setNewStoryDetails((prev: Story) => ({
              ...prev,
              pages: [
                ...prev.pages,
                {
                  page_number: prev.pages.length + 1,
                  page_text: "",
                  lines: [],
                  page_image: `${newStoryDetails.title.split(" ").join("")}${
                    prev.pages.length + 1
                  }.webp`,
                },
              ],
            }));
          }}
        >
          Add Page
        </button> */}
        {/* <button onClick={() => updateStory()}>Update Page {currentPage}</button> */}
        {error.isActive && <div>{error.message}</div>}
      </div>
    ) : (
      <div style={{ color: "white", display: "flex", flexDirection: "column" }}>
        <span>Unable to locate the story to edit.</span>
      </div>
    )
  ) : (
    <div style={{ color: "white", display: "flex", flexDirection: "column" }}>
      <span>Unauthorized access.</span>
    </div>
  );
};

export default EditStory;
