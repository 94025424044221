import { useContext } from "react";
import Modal from "../Components/Partials/Modal";
import { StudentAnswers } from "../Components/Partials/Questions";
import { Display, DisplayContext } from "../Context/DisplayContext";
import { Story, StudentData, TrackedWords, Users } from "../Models";

export const useDisplayData = () => {
  const { displayData, setDisplayData } = useContext(DisplayContext);

  const showModal = () => {
    setDisplayData((prev: Display) => ({ ...prev, showModal: true }));
  };

  const showLoginModal = (redirectToHome?: boolean) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      login: true,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      manageSubscription: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      redirectToHome: redirectToHome ? redirectToHome : false,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showRegisterModal = (redirectToHome?: boolean) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      register: true,
      login: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      manageSubscription: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      redirectToHome: redirectToHome ? redirectToHome : false,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showAddChildProfileModal = () => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      addChildProfile: true,
      addClassroom: false,
      login: false,
      register: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      manageSubscription: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showAddClassroomModal = () => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      addChildProfile: false,
      addClassroom: true,
      login: false,
      register: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      manageSubscription: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showInviteTeacherModal = () => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      inviteTeacher: true,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      stripe: false,
      signIntoClass: false,
      manageSubscription: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showStripeModal = ({
    fromBanner,
    fromLimit,
    story_details,
  }: {
    fromBanner: boolean;
    fromLimit: boolean;
    story_details?: Story;
  }) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      stripe: true,
      fromBanner,
      fromLimit,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      signIntoClass: false,
      manageSubscription: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      story_details,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showSignIntoClassModal = () => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      signIntoClass: true,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      manageSubscription: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showManageSubscriptionModal = () => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: true,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showChildProfilesModal = (childProfileType: "play" | "read") => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: true,
      childProfileType,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showNextStoryModal = (
    randomStory: Story,
    score: string,
    studentAnswers: StudentAnswers
  ) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: true,
      randomStory,
      score,
      studentAnswers,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showTrackedWordsModal = (childData: StudentData, child_id: string) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: true,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      childData,
      child_id,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showStoriesReadModal = (childData: StudentData, child_id: string) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: true,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      childData,
      child_id,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showRecentlyLearnedModal = (childData: StudentData) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: true,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      childData,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showChangeReadingLevelModal = (
    childData: StudentData,
    child_id: string
  ) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: true,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      childData,
      child_id,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showChangeGradeModal = (childData: StudentData, child_id: string) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: true,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      childData,
      child_id,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showLinkAccountModal = (child_id: string) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: true,
      deleteProfile: false,
      previewStory: false,
      child_id,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showDeleteProfileDialog = (
    child_id?: string,
    childData?: StudentData
  ) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: true,
      previewStory: false,
      child_id,
      childData,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showPreviewStoryModal = (
    story_details?: Story,
    fromLimit?: boolean,
    fromBanner?: boolean
  ) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: true,
      story_details,
      fromLimit: fromLimit ? fromLimit : false,
      fromBanner: fromBanner ? fromBanner : false,
      games: false,
      comingSoon: false,
      share: false,
    }));
  };

  const showGamesModal = () => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      games: true,
      comingSoon: false,
      share: false,
    }));
  };

  const showComingSoonModal = (comingSoonType: string) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      games: false,
      comingSoon: true,
      share: false,
      comingSoonType,
    }));
  };

  const showShareModal = (title: string) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
      linkAccount: false,
      deleteProfile: false,
      previewStory: false,
      games: false,
      comingSoon: false,
      share: true,
      title,
    }));
  };

  // const redirectToStory = (redirect?: boolean, storyID?: string) => {
  //   setDisplayData((prev: Display) => {
  //     return {
  //       ...prev,
  //       redirectToStory: redirect,
  //       storyID: storyID ? storyID : prev.storyID,
  //       redirectToHome: false,
  //     };
  //   });
  // };

  const setHomePageScrollHeight = (scrollY?: number) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      homepageScrollHeight: scrollY,
    }));
  };

  const setInitialReload = (bool: boolean) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      initialReload: bool,
    }));
  };

  const setScrollToStories = (bool: boolean) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      scrollToStories: bool,
    }));
  };

  const hideModal = () => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: false,
    }));
  };

  return {
    displayData,
    showModal,
    showLoginModal,
    showRegisterModal,
    showAddChildProfileModal,
    showAddClassroomModal,
    showInviteTeacherModal,
    showStripeModal,
    showSignIntoClassModal,
    showManageSubscriptionModal,
    showChildProfilesModal,
    showNextStoryModal,
    showTrackedWordsModal,
    showStoriesReadModal,
    showRecentlyLearnedModal,
    showChangeReadingLevelModal,
    showChangeGradeModal,
    showLinkAccountModal,
    showDeleteProfileDialog,
    showPreviewStoryModal,
    showShareModal,
    // redirectToStory,
    setHomePageScrollHeight,
    setInitialReload,
    setScrollToStories,
    hideModal,
    showGamesModal,
    showComingSoonModal,
  };
};
