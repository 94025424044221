import $ from "jquery";

export function diceRoll(randNum) {
  $("#dice-color").val("#000000");
  $("#dot-color").val("#fff");
  let rnd = randNum;
  let x, y;
  $("#spin").click(function (e) {
    e.preventDefault();
    // rnd = Math.floor(Math.random() * 6 + 1);

    switch (rnd) {
      case 1:
        x = 720;
        y = 810;
        break;
      case 6:
        x = 720;
        y = 990;
        break;
      default:
        x = 720 + (6 - rnd) * 90;
        y = 900;
        break;
    }
    $(".dice").css(
      "transform",
      "translateZ(-50px) rotateY(" + x + "deg) rotateX(" + y + "deg)"
    );
  });
}
