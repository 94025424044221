import React, { useState } from "react";
import { useEffect } from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { useStories } from "../Hooks/useStories";
import { useUserData } from "../Hooks/useUserData";
import { Phonics, Words } from "../Models";
import { generateID } from "../Utils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

interface MatchParams {
  word: string;
}

interface ComponentProps extends RouteComponentProps<MatchParams> {}

const AssociatedWords: React.FC<ComponentProps> = ({ match }) => {
  const { storyData } = useStories();
  const { userData } = useUserData();
  const [wordData, setWordData] = useState<Words | undefined>(undefined);
  const [phonicData, setPhonicData] = useState<Phonics[] | undefined>(
    undefined
  );
  const [slicedPhonicData, setSlicedPhonicData] = useState<Phonics[]>([]);
  const [similarWords, setSimilarWords] = useState<Words[]>();
  // const [listNumber, setListNumber] = useState(0);
  // const [partNumber, setPartNumber] = useState(1);
  // const [multiplePhonemes, setMultiplePhonemes] = useState(false);
  // const [multiplePhonemePosition, setMultiplePhonemePosition] = useState(0);
  const [filteredWords, setFilteredWords] = useState<Words[]>();
  const [slicedWords, setSlicedWords] = useState<Words[]>([]);
  const [tab, setTab] = useState({
    beginning: true,
    middle: false,
    end: false,
  });

  useEffect(() => {
    setWordData(
      storyData.words.find((word) => word.text === match.params.word)
    );
  }, [storyData]);

  useEffect(() => {
    if (phonicData) {
      setSlicedPhonicData(
        phonicData.slice(
          wordData!.phonics[0].phonemes.length > 1 ? 2 : 1,
          wordData!.phonics[wordData!.phonics.length - 1].phonemes.length > 1
            ? phonicData.length - 2
            : phonicData.length - 1
        )
      );
    }
  }, [phonicData]);

  useEffect(() => {
    if (wordData) {
      for (const phonic of wordData.phonics) {
        setSimilarWords((prev: any) =>
          prev === undefined
            ? storyData.words.filter(
                (word) =>
                  word.text !== wordData.text &&
                  word.phonics.some((wordPhonic) =>
                    phonic.phonemes.length > 1
                      ? wordPhonic.phonemes.includes(phonic.phonemes[0]) ||
                        wordPhonic.phonemes.includes(phonic.phonemes[1])
                      : wordPhonic.phonemes.includes(phonic.phonemes[0])
                  )
              )
            : [
                ...prev,
                ...storyData.words.filter(
                  (word) =>
                    word.text !== wordData.text &&
                    word.phonics.some((wordPhonic) =>
                      phonic.phonemes.length > 1
                        ? wordPhonic.phonemes.includes(phonic.phonemes[0]) ||
                          wordPhonic.phonemes.includes(phonic.phonemes[1])
                        : wordPhonic.phonemes.includes(phonic.phonemes[0])
                    )
                ),
              ]
        );
        if (!phonicData || phonicData.length < wordData.phonics.length) {
          if (phonic.phonemes.length > 1) {
            let foundPhonic1 = storyData.phonics.find(
              (fullPhonic) => fullPhonic.phoneme === phonic.phonemes[0]
            );
            let foundPhonic2 = storyData.phonics.find(
              (fullPhonic) => fullPhonic.phoneme === phonic.phonemes[1]
            );
            setPhonicData((prev: any) =>
              prev === undefined
                ? [foundPhonic1, foundPhonic2]
                : [...prev, foundPhonic1, foundPhonic2]
            );
          } else {
            let foundPhonic = storyData.phonics.find(
              (fullPhonic) => fullPhonic.phoneme === phonic.phonemes[0]
            );
            setPhonicData((prev: any) =>
              prev === undefined ? [foundPhonic] : [...prev, foundPhonic]
            );
          }
        }
      }
    }
  }, [wordData, phonicData]);

  useEffect(() => {
    let tempPhonicData = phonicData;
    if (wordData && tempPhonicData && similarWords) {
      if (tab.beginning) {
        if (wordData.phonics.length > 1) {
          setFilteredWords(
            similarWords.filter(
              (word) =>
                (word.phonics.length > 1 &&
                  (wordData.phonics[0].phonemes.length > 1
                    ? (word.phonics[0].phonemes[0] ===
                        wordData.phonics[0].phonemes[0] &&
                        word.phonics[0].phonemes[1] ===
                          wordData.phonics[0].phonemes[1]) ||
                      (word.phonics.length > 2 &&
                        word.phonics[0].phonemes[0] +
                          word.phonics[1].phonemes[0] +
                          word.phonics[2].phonemes[0] ===
                          wordData.phonics[0].phonemes[0] +
                            wordData.phonics[0].phonemes[1] +
                            wordData.phonics[1].phonemes[0])
                    : word.phonics[0].phonemes[0] ===
                      wordData.phonics[0].phonemes[0]) &&
                  (wordData.phonics[1].phonemes.length > 1
                    ? word.phonics[1].phonemes[0] ===
                        wordData.phonics[1].phonemes[0] &&
                      word.phonics[1].phonemes[1] ===
                        wordData.phonics[1].phonemes[1]
                    : word.phonics[1].phonemes[0] ===
                        wordData.phonics[1].phonemes[0] ||
                      (word.phonics.length > 2 &&
                        word.phonics[0].phonemes[0] +
                          word.phonics[1].phonemes[0] +
                          word.phonics[2].phonemes[0] ===
                          wordData.phonics[0].phonemes[0] +
                            wordData.phonics[0].phonemes[1] +
                            wordData.phonics[1].phonemes[0]))) ||
                (word.phonics[0].phonemes.length > 1
                  ? word.phonics[0].phonemes[0] ===
                      wordData.phonics[0].phonemes[0] &&
                    word.phonics[0].phonemes[1] ===
                      wordData.phonics[1].phonemes[0]
                  : false)
            )
          );
        } else {
          for (const phonic of tempPhonicData) {
            setFilteredWords((prev: any) =>
              filteredWords === undefined
                ? similarWords.filter((word) =>
                    word.phonics.some(
                      (wordPhonic) =>
                        wordPhonic.phonemes[0] === phonic.phoneme &&
                        word.text.charAt(0) ===
                          word.text.charAt(0).toLowerCase() &&
                        wordPhonic.part.charAt(0) ===
                          wordPhonic.part.charAt(0).toLowerCase()
                    )
                  )
                : [
                    ...prev,
                    ...similarWords.filter((word) =>
                      word.phonics.some(
                        (wordPhonic) =>
                          wordPhonic.phonemes[0] === phonic.phoneme &&
                          word.text.charAt(0) ===
                            word.text.charAt(0).toLowerCase() &&
                          wordPhonic.part.charAt(0) ===
                            wordPhonic.part.charAt(0).toLowerCase()
                      )
                    ),
                  ]
            );
          }
        }
      } else if (tab.end) {
        setFilteredWords(
          similarWords.filter((word) => {
            return (
              (word.phonics.length > 1 &&
                (wordData.phonics[wordData.phonics.length - 1].phonemes.length >
                1
                  ? (word.phonics[word.phonics.length - 1].phonemes[0] ===
                      wordData.phonics[wordData.phonics.length - 1]
                        .phonemes[0] &&
                      word.phonics[word.phonics.length - 1].phonemes[1] ===
                        wordData.phonics[wordData.phonics.length - 1]
                          .phonemes[1]) ||
                    (word.phonics.length > 2 &&
                      word.phonics[word.phonics.length - 1].phonemes[0] +
                        word.phonics[word.phonics.length - 2].phonemes[0] +
                        word.phonics[word.phonics.length - 3].phonemes[0] ===
                        wordData.phonics[wordData.phonics.length - 1]
                          .phonemes[1] +
                          wordData.phonics[wordData.phonics.length - 1]
                            .phonemes[0] +
                          wordData.phonics[wordData.phonics.length - 2]
                            .phonemes[0])
                  : word.phonics[word.phonics.length - 1].phonemes.length > 1
                  ? word.phonics[word.phonics.length - 1].phonemes[1] ===
                      wordData.phonics[wordData.phonics.length - 1]
                        .phonemes[0] &&
                    word.phonics[word.phonics.length - 1].phonemes[0] ===
                      wordData.phonics[wordData.phonics.length - 2].phonemes[0]
                  : word.phonics[word.phonics.length - 1].phonemes[0] ===
                    wordData.phonics[wordData.phonics.length - 1]
                      .phonemes[0]) &&
                (wordData.phonics[wordData.phonics.length - 2].phonemes.length >
                1
                  ? word.phonics[word.phonics.length - 2].phonemes[0] ===
                      wordData.phonics[wordData.phonics.length - 2]
                        .phonemes[0] &&
                    word.phonics[word.phonics.length - 2].phonemes[1] ===
                      wordData.phonics[wordData.phonics.length - 2].phonemes[1]
                  : word.phonics[word.phonics.length - 2].phonemes[0] ===
                      wordData.phonics[wordData.phonics.length - 2]
                        .phonemes[0] ||
                    (word.phonics.length > 2 &&
                      word.phonics[word.phonics.length - 1].phonemes[0] +
                        word.phonics[word.phonics.length - 2].phonemes[0] +
                        word.phonics[word.phonics.length - 3].phonemes[0] ===
                        wordData.phonics[wordData.phonics.length - 1]
                          .phonemes[1] +
                          wordData.phonics[wordData.phonics.length - 1]
                            .phonemes[0] +
                          wordData.phonics[wordData.phonics.length - 2]
                            .phonemes[0]))) ||
              (word.phonics.length > 1 &&
                (word.phonics[word.phonics.length - 1].phonemes.length > 1
                  ? word.phonics[word.phonics.length - 1].phonemes[1] ===
                      wordData.phonics[wordData.phonics.length - 1]
                        .phonemes[0] &&
                    word.phonics[word.phonics.length - 1].phonemes[0] ===
                      wordData.phonics[wordData.phonics.length - 2].phonemes[0]
                  : false))
            );
          })
        );
      } else if (tab.middle) {
        tempPhonicData = tempPhonicData.slice(
          wordData.phonics[0].phonemes.length > 1 ? 2 : 1,
          wordData.phonics[wordData.phonics.length - 1].phonemes.length > 1
            ? tempPhonicData.length - 2
            : tempPhonicData.length - 1
        );
        if (tempPhonicData) {
          for (const phonic of tempPhonicData) {
            setFilteredWords((prev: any) =>
              filteredWords === undefined
                ? similarWords.filter((word) =>
                    word.phonics.some(
                      (wordPhonic) =>
                        ((word.phonics[word.phonics.indexOf(wordPhonic) + 1] &&
                          tempPhonicData![
                            tempPhonicData!.indexOf(phonic) + 1
                          ] &&
                          wordPhonic.phonemes[0] === phonic.phoneme &&
                          word.phonics[word.phonics.indexOf(wordPhonic) + 1]
                            .phonemes[0] ===
                            tempPhonicData![tempPhonicData!.indexOf(phonic) + 1]
                              .phoneme) ||
                          (word.phonics[word.phonics.indexOf(wordPhonic) - 1] &&
                            tempPhonicData![
                              tempPhonicData!.indexOf(phonic) - 1
                            ] &&
                            wordPhonic.phonemes[0] === phonic.phoneme &&
                            word.phonics[word.phonics.indexOf(wordPhonic) - 1]
                              .phonemes[0] ===
                              tempPhonicData![
                                tempPhonicData!.indexOf(phonic) - 1
                              ].phoneme)) &&
                        word.text.charAt(0) ===
                          word.text.charAt(0).toLowerCase() &&
                        wordPhonic.part.charAt(0) ===
                          wordPhonic.part.charAt(0).toLowerCase()
                    )
                  )
                : [
                    ...prev,
                    ...similarWords.filter((word) =>
                      word.phonics.some(
                        (wordPhonic) =>
                          ((word.phonics[
                            word.phonics.indexOf(wordPhonic) + 1
                          ] &&
                            tempPhonicData![
                              tempPhonicData!.indexOf(phonic) + 1
                            ] &&
                            wordPhonic.phonemes[0] === phonic.phoneme &&
                            word.phonics[word.phonics.indexOf(wordPhonic) + 1]
                              .phonemes[0] ===
                              tempPhonicData![
                                tempPhonicData!.indexOf(phonic) + 1
                              ].phoneme) ||
                            (word.phonics[
                              word.phonics.indexOf(wordPhonic) - 1
                            ] &&
                              tempPhonicData![
                                tempPhonicData!.indexOf(phonic) - 1
                              ] &&
                              wordPhonic.phonemes[0] === phonic.phoneme &&
                              word.phonics[word.phonics.indexOf(wordPhonic) - 1]
                                .phonemes[0] ===
                                tempPhonicData![
                                  tempPhonicData!.indexOf(phonic) - 1
                                ].phoneme)) &&
                          word.text.charAt(0) ===
                            word.text.charAt(0).toLowerCase() &&
                          wordPhonic.part.charAt(0) ===
                            wordPhonic.part.charAt(0).toLowerCase()
                      )
                    ),
                  ]
            );
          }
        }
      }
    }
  }, [wordData, phonicData, similarWords, tab]);

  useEffect(() => {
    setSlicedWords(
      Array.from(new Set(filteredWords?.sort(() => 0.5 - Math.random())))
        .slice(0, 30)
        .filter((word) => isNaN(Number(word.text)))
    );
  }, [filteredWords]);

  const downloadPDF = (id: string) => {
    const input = document.getElementById(id);
    if (input && wordData) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 0, 0, 210, 0, undefined, "FAST");
        pdf.save(
          `${wordData.text}-${
            tab.beginning ? "beginning" : tab.middle ? "middle" : "end"
          }-phonics`
        );
      });
    } else {
      console.log("Could not find id of root element");
    }
  };

  const MAPPED_PHONICS = phonicData?.map((phonic) => (
    <React.Fragment key={generateID()}>
      <div className="associated-words__phoneme-container">
        <div>
          <span className="associated-words__bold">Phoneme</span> (Speech Sound)
        </div>
        <div className="associated-words__phoneme-name">
          {tab.beginning
            ? wordData!.phonics[0].phonemes.length > 1 ||
              (wordData!.phonics.length > 1 &&
                wordData!.phonics[1].phonemes.length > 1)
              ? phonicData[0].name +
                "; " +
                phonicData[1].name +
                "; " +
                phonicData[2].name
              : wordData!.phonics.length > 1
              ? phonicData[0].name + "; " + phonicData[1].name
              : phonicData[0].name
            : tab.end
            ? wordData!.phonics[wordData!.phonics.length - 1].phonemes.length >
                1 ||
              wordData!.phonics[wordData!.phonics.length - 2].phonemes.length >
                1
              ? phonicData[phonicData.length - 3].name +
                "; " +
                phonicData[phonicData.length - 2].name +
                "; " +
                phonicData[phonicData.length - 1].name
              : phonicData[phonicData.length - 2].name +
                "; " +
                phonicData[phonicData.length - 1].name
            : wordData!.phonics[wordData!.phonics.length - 1].phonemes.length >
              1
            ? phonicData
                .slice(1, phonicData.length - 2)
                .map((phonicDataPhonic) => phonicDataPhonic.name)
                .join("; ")
            : wordData!.phonics[0].phonemes.length > 1
            ? phonicData
                .slice(2, phonicData.length - 1)
                .map((phonicDataPhonic) => phonicDataPhonic.name)
                .join("; ")
            : phonicData
                .slice(1, phonicData.length - 1)
                .map((phonicDataPhonic) => phonicDataPhonic.name)
                .join("; ")}
        </div>
      </div>
      <div className="associated-words__grapheme-container">
        <div className="associated-words__grapheme-text">
          <span className="associated-words__bold">Grapheme</span> (Common
          letter combinations making up this sound)
        </div>
        <div className="associated-words__grapheme-letters">
          {tab.beginning
            ? wordData!.phonics[0].phonemes.length > 1 ||
              (wordData!.phonics.length > 1 &&
                wordData!.phonics[1].phonemes.length > 1)
              ? phonicData[0].grapheme.join(", ") +
                "; " +
                phonicData[1].grapheme.join(", ") +
                "; " +
                phonicData[2].grapheme.join(", ")
              : wordData!.phonics.length > 1
              ? phonicData[0].grapheme.join(", ") +
                "; " +
                phonicData[1].grapheme.join(", ")
              : phonicData[0].grapheme.join(", ")
            : tab.end
            ? wordData!.phonics[wordData!.phonics.length - 1].phonemes.length >
                1 ||
              wordData!.phonics[wordData!.phonics.length - 2].phonemes.length >
                1
              ? phonicData[phonicData.length - 3].grapheme.join(", ") +
                "; " +
                phonicData[phonicData.length - 2].grapheme.join(", ") +
                "; " +
                phonicData[phonicData.length - 1].grapheme.join(", ")
              : phonicData[phonicData.length - 2].grapheme.join(", ") +
                "; " +
                phonicData[phonicData.length - 1].grapheme.join(", ")
            : wordData!.phonics[wordData!.phonics.length - 1].phonemes.length >
              1
            ? phonicData
                .slice(1, phonicData.length - 2)
                .map((phonicDataPhonic) => phonicDataPhonic.grapheme.join(", "))
                .join("; ")
            : wordData!.phonics[0].phonemes.length > 1
            ? phonicData
                .slice(2, phonicData.length - 1)
                .map((phonicDataPhonic) => phonicDataPhonic.grapheme.join(", "))
                .join("; ")
            : phonicData
                .slice(1, phonicData.length - 1)
                .map((phonicDataPhonic) => phonicDataPhonic.grapheme.join(", "))
                .join("; ")}
        </div>
      </div>
      <div className="associated-words__words-container">
        <div className="associated-words__words-left">
          {slicedWords.slice(0, 10).map((word) => (
            <React.Fragment key={generateID()}>
              <div className="associated-words__mapped-word">
                <Link to={`/associatedWords/${word.text}`} target="_blank">
                  {word.phonics.map((wordPhonic) => (
                    <React.Fragment key={generateID()}>
                      <span
                        className={
                          (tab.beginning &&
                            (wordData!.phonics.length > 1
                              ? word.phonics[0].phonemes.length > 1 &&
                                word.phonics[0].phonemes[1] ===
                                  phonicData[1].phoneme &&
                                word.phonics[1].phonemes[0] !==
                                  phonicData[2].phoneme
                                ? wordPhonic.position === 1
                                : word.phonics[0].part !==
                                    wordData!.phonics[0].part &&
                                  wordData!.phonics[0].phonemes.length > 1
                                ? wordPhonic.position === 1 ||
                                  wordPhonic.position === 2 ||
                                  wordPhonic.position === 3
                                : wordPhonic.position === 1 ||
                                  wordPhonic.position === 2
                              : phonicData.some((phonicDataPhonic) =>
                                  wordPhonic.phonemes.includes(
                                    phonicDataPhonic.phoneme
                                  )
                                ))) ||
                          (tab.middle &&
                            slicedPhonicData.some(
                              (phonicDataPhonic) =>
                                (word.phonics[
                                  word.phonics.indexOf(wordPhonic) + 1
                                ] &&
                                  slicedPhonicData[
                                    slicedPhonicData.indexOf(phonicDataPhonic) +
                                      1
                                  ] &&
                                  wordPhonic.phonemes[0] ===
                                    slicedPhonicData[
                                      slicedPhonicData.indexOf(phonicDataPhonic)
                                    ].phoneme &&
                                  word.phonics[
                                    word.phonics.indexOf(wordPhonic) + 1
                                  ].phonemes[0] ===
                                    slicedPhonicData[
                                      slicedPhonicData.indexOf(
                                        phonicDataPhonic
                                      ) + 1
                                    ].phoneme) ||
                                (word.phonics[
                                  word.phonics.indexOf(wordPhonic) - 1
                                ] &&
                                  slicedPhonicData[
                                    slicedPhonicData.indexOf(phonicDataPhonic) -
                                      1
                                  ] &&
                                  wordPhonic.phonemes[0] ===
                                    slicedPhonicData[
                                      slicedPhonicData.indexOf(phonicDataPhonic)
                                    ].phoneme &&
                                  word.phonics[
                                    word.phonics.indexOf(wordPhonic) - 1
                                  ].phonemes[0] ===
                                    slicedPhonicData[
                                      slicedPhonicData.indexOf(
                                        phonicDataPhonic
                                      ) - 1
                                    ].phoneme)
                            )) ||
                          (tab.end &&
                            (word.phonics[word.phonics.length - 1].phonemes
                              .length > 1 &&
                            word.phonics[word.phonics.length - 1]
                              .phonemes[0] ===
                              phonicData[phonicData.length - 2].phoneme &&
                            word.phonics[word.phonics.length - 2]
                              .phonemes[0] !==
                              phonicData[phonicData.length - 3].phoneme
                              ? wordPhonic.position === word.phonics.length
                              : word.phonics[word.phonics.length - 1].part !==
                                  wordData!.phonics[
                                    wordData!.phonics.length - 1
                                  ].part &&
                                wordData!.phonics[wordData!.phonics.length - 1]
                                  .phonemes.length > 1
                              ? wordPhonic.position === word.phonics.length ||
                                wordPhonic.position ===
                                  word.phonics.length - 1 ||
                                wordPhonic.position === word.phonics.length - 2
                              : wordPhonic.position === word.phonics.length ||
                                wordPhonic.position ===
                                  word.phonics.length - 1))
                            ? "associated-words__phoneme--relevant"
                            : "associated-words__phoneme"
                        }
                      >
                        {wordPhonic.part}
                      </span>
                    </React.Fragment>
                  ))}
                </Link>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="associated-words__words-middle">
          {slicedWords.slice(10, 20).map((word) => (
            <React.Fragment key={generateID()}>
              <div className="associated-words__mapped-word">
                <Link to={`/associatedWords/${word.text}`} target="_blank">
                  {word.phonics.map((wordPhonic) => (
                    <React.Fragment key={generateID()}>
                      <span
                        className={
                          (tab.beginning &&
                            (wordData!.phonics.length > 1
                              ? word.phonics[0].phonemes.length > 1 &&
                                word.phonics[0].phonemes[1] ===
                                  phonicData[1].phoneme &&
                                word.phonics[1].phonemes[0] !==
                                  phonicData[2].phoneme
                                ? wordPhonic.position === 1
                                : word.phonics[0].part !==
                                    wordData!.phonics[0].part &&
                                  wordData!.phonics[0].phonemes.length > 1
                                ? wordPhonic.position === 1 ||
                                  wordPhonic.position === 2 ||
                                  wordPhonic.position === 3
                                : wordPhonic.position === 1 ||
                                  wordPhonic.position === 2
                              : phonicData.some((phonicDataPhonic) =>
                                  wordPhonic.phonemes.includes(
                                    phonicDataPhonic.phoneme
                                  )
                                ))) ||
                          (tab.middle &&
                            slicedPhonicData.some(
                              (phonicDataPhonic) =>
                                (word.phonics[
                                  word.phonics.indexOf(wordPhonic) + 1
                                ] &&
                                  slicedPhonicData[
                                    slicedPhonicData.indexOf(phonicDataPhonic) +
                                      1
                                  ] &&
                                  wordPhonic.phonemes[0] ===
                                    slicedPhonicData[
                                      slicedPhonicData.indexOf(phonicDataPhonic)
                                    ].phoneme &&
                                  word.phonics[
                                    word.phonics.indexOf(wordPhonic) + 1
                                  ].phonemes[0] ===
                                    slicedPhonicData[
                                      slicedPhonicData.indexOf(
                                        phonicDataPhonic
                                      ) + 1
                                    ].phoneme) ||
                                (word.phonics[
                                  word.phonics.indexOf(wordPhonic) - 1
                                ] &&
                                  slicedPhonicData[
                                    slicedPhonicData.indexOf(phonicDataPhonic) -
                                      1
                                  ] &&
                                  wordPhonic.phonemes[0] ===
                                    slicedPhonicData[
                                      slicedPhonicData.indexOf(phonicDataPhonic)
                                    ].phoneme &&
                                  word.phonics[
                                    word.phonics.indexOf(wordPhonic) - 1
                                  ].phonemes[0] ===
                                    slicedPhonicData[
                                      slicedPhonicData.indexOf(
                                        phonicDataPhonic
                                      ) - 1
                                    ].phoneme)
                            )) ||
                          (tab.end &&
                            (word.phonics[word.phonics.length - 1].phonemes
                              .length > 1 &&
                            word.phonics[word.phonics.length - 1]
                              .phonemes[0] ===
                              phonicData[phonicData.length - 2].phoneme &&
                            word.phonics[word.phonics.length - 2]
                              .phonemes[0] !==
                              phonicData[phonicData.length - 3].phoneme
                              ? wordPhonic.position === word.phonics.length
                              : word.phonics[word.phonics.length - 1].part !==
                                  wordData!.phonics[
                                    wordData!.phonics.length - 1
                                  ].part &&
                                wordData!.phonics[wordData!.phonics.length - 1]
                                  .phonemes.length > 1
                              ? wordPhonic.position === word.phonics.length ||
                                wordPhonic.position ===
                                  word.phonics.length - 1 ||
                                wordPhonic.position === word.phonics.length - 2
                              : wordPhonic.position === word.phonics.length ||
                                wordPhonic.position ===
                                  word.phonics.length - 1))
                            ? "associated-words__phoneme--relevant"
                            : "associated-words__phoneme"
                        }
                      >
                        {wordPhonic.part}
                      </span>
                    </React.Fragment>
                  ))}
                </Link>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="associated-words__words-right">
          {slicedWords.slice(20, 30).map((word) => (
            <React.Fragment key={generateID()}>
              <div className="associated-words__mapped-word">
                <Link to={`/associatedWords/${word.text}`} target="_blank">
                  {word.phonics.map((wordPhonic) => (
                    <React.Fragment key={generateID()}>
                      <span
                        className={
                          (tab.beginning &&
                            (wordData!.phonics.length > 1
                              ? word.phonics[0].phonemes.length > 1 &&
                                word.phonics[0].phonemes[1] ===
                                  phonicData[1].phoneme &&
                                word.phonics[1].phonemes[0] !==
                                  phonicData[2].phoneme
                                ? wordPhonic.position === 1
                                : word.phonics[0].part !==
                                    wordData!.phonics[0].part &&
                                  wordData!.phonics[0].phonemes.length > 1
                                ? wordPhonic.position === 1 ||
                                  wordPhonic.position === 2 ||
                                  wordPhonic.position === 3
                                : wordPhonic.position === 1 ||
                                  wordPhonic.position === 2
                              : phonicData.some((phonicDataPhonic) =>
                                  wordPhonic.phonemes.includes(
                                    phonicDataPhonic.phoneme
                                  )
                                ))) ||
                          (tab.middle &&
                            slicedPhonicData.some(
                              (phonicDataPhonic) =>
                                (word.phonics[
                                  word.phonics.indexOf(wordPhonic) + 1
                                ] &&
                                  slicedPhonicData[
                                    slicedPhonicData.indexOf(phonicDataPhonic) +
                                      1
                                  ] &&
                                  wordPhonic.phonemes[0] ===
                                    slicedPhonicData[
                                      slicedPhonicData.indexOf(phonicDataPhonic)
                                    ].phoneme &&
                                  word.phonics[
                                    word.phonics.indexOf(wordPhonic) + 1
                                  ].phonemes[0] ===
                                    slicedPhonicData[
                                      slicedPhonicData.indexOf(
                                        phonicDataPhonic
                                      ) + 1
                                    ].phoneme) ||
                                (word.phonics[
                                  word.phonics.indexOf(wordPhonic) - 1
                                ] &&
                                  slicedPhonicData[
                                    slicedPhonicData.indexOf(phonicDataPhonic) -
                                      1
                                  ] &&
                                  wordPhonic.phonemes[0] ===
                                    slicedPhonicData[
                                      slicedPhonicData.indexOf(phonicDataPhonic)
                                    ].phoneme &&
                                  word.phonics[
                                    word.phonics.indexOf(wordPhonic) - 1
                                  ].phonemes[0] ===
                                    slicedPhonicData[
                                      slicedPhonicData.indexOf(
                                        phonicDataPhonic
                                      ) - 1
                                    ].phoneme)
                            )) ||
                          (tab.end &&
                            (word.phonics[word.phonics.length - 1].phonemes
                              .length > 1 &&
                            word.phonics[word.phonics.length - 1]
                              .phonemes[0] ===
                              phonicData[phonicData.length - 2].phoneme &&
                            word.phonics[word.phonics.length - 2]
                              .phonemes[0] !==
                              phonicData[phonicData.length - 3].phoneme
                              ? wordPhonic.position === word.phonics.length
                              : word.phonics[word.phonics.length - 1].part !==
                                  wordData!.phonics[
                                    wordData!.phonics.length - 1
                                  ].part &&
                                wordData!.phonics[wordData!.phonics.length - 1]
                                  .phonemes.length > 1
                              ? wordPhonic.position === word.phonics.length ||
                                wordPhonic.position ===
                                  word.phonics.length - 1 ||
                                wordPhonic.position === word.phonics.length - 2
                              : wordPhonic.position === word.phonics.length ||
                                wordPhonic.position ===
                                  word.phonics.length - 1))
                            ? "associated-words__phoneme--relevant"
                            : "associated-words__phoneme"
                        }
                      >
                        {wordPhonic.part}
                      </span>
                    </React.Fragment>
                  ))}
                </Link>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <br></br>
    </React.Fragment>
  ));

  return localStorage.getItem("id") || (userData._id && userData._id !== "") ? (
    <section id="associated-words" className="associated-words">
      <div className="associated-words__new-tab">
        To go back, click the website tab above.
      </div>
      <div className="associated-words__new-tab">
        Similar Sounding Words to{" "}
        <span className="associated-words__bold">{wordData?.text}</span>
      </div>
      <div className="associated-words__similar-words">
        <div
          style={
            wordData && wordData.phonics.length < 3
              ? {
                  width: "100%",
                  textAlign: "center",
                  borderBottom: "1px solid #333",
                }
              : undefined
          }
          className={
            tab.beginning
              ? "associated-words__similar-words--options associated-words__similar-words--options--selected"
              : "associated-words__similar-words--options"
          }
          onClick={() => {
            setFilteredWords(undefined);
            setTab({ beginning: true, middle: false, end: false });
          }}
        >
          {wordData && wordData.phonics.length > 2
            ? "Same Beginning Sound"
            : "Phonics"}
        </div>
        {wordData && wordData.phonics.length > 2 && (
          <div
            className={
              tab.middle
                ? "associated-words__similar-words--options associated-words__similar-words--options--selected"
                : "associated-words__similar-words--options"
            }
            onClick={() => {
              setFilteredWords(undefined);
              setTab({ beginning: false, middle: true, end: false });
            }}
          >
            Same Middle Sound
          </div>
        )}
        {wordData && wordData.phonics.length > 2 && (
          <div
            className={
              tab.end
                ? "associated-words__similar-words--options associated-words__similar-words--options--selected"
                : "associated-words__similar-words--options"
            }
            onClick={() => {
              setFilteredWords(undefined);
              setTab({ beginning: false, middle: false, end: true });
            }}
          >
            Same Ending Sound
          </div>
        )}
      </div>
      <div className="associated-words__pdf-wrapper">
        <div className="associated-words__website">
          <i
            className="fa fa-copyright associated-words__copyright"
            aria-hidden="true"
          ></i>
          <span className="associated-words__copyright">Copyright</span>
          <span className="associated-words__website-text">
            www.nofussreaders.com
          </span>
        </div>
        <div
          className="associated-words__download"
          onClick={() => downloadPDF("associated-words")}
        >
          <img
            className="associated-words__download--img"
            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/download-doc.png`}
            alt={"No Image"}
          />
        </div>
        <div className="associated-words__main-word">
          {wordData?.phonics.map((phonic) => (
            <React.Fragment key={generateID()}>
              <span
                className={
                  (tab.beginning &&
                    (phonic.position === 1 || phonic.position === 2)) ||
                  (tab.middle &&
                    phonic.position !== 1 &&
                    phonic.position !== wordData.phonics.length) ||
                  (tab.end &&
                    (phonic.position === wordData.phonics.length ||
                      phonic.position === wordData.phonics.length - 1))
                    ? "associated-words__grapheme--clicked"
                    : "associated-words__grapheme"
                }
              >
                {phonic.part}
              </span>
            </React.Fragment>
          ))}
        </div>

        <div className="associated-words__mapped-words-container">
          {
            MAPPED_PHONICS && MAPPED_PHONICS[0]
            // ? (
            //   multiplePhonemes ? (
            //     <div className="associated-words__multiple-phoneme-container">
            //       {MAPPED_PHONICS[partNumber - 1]}
            //       {wordData?.phonics[partNumber - 1].multiple ? "OR" : "AND"}
            //       {MAPPED_PHONICS[partNumber]}
            //     </div>
            //   ) : multiplePhonemePosition !== 0 &&
            //     multiplePhonemePosition < partNumber ? (
            //     MAPPED_PHONICS[partNumber]
            //   ) : (
            //     MAPPED_PHONICS[partNumber - 1]
            //   )
            // ) : null
          }
        </div>
      </div>
    </section>
  ) : (
    <Redirect to="/" />
  );
};

export default AssociatedWords;
