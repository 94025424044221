import React, { useEffect, useState, useRef } from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { decrypt, encrypt, GetBaseURL } from "../Utils";
import NewEncryption from "../Utils/NewEncryption";

interface MatchParams {
  encryptedEmail: string;
}

interface ComponentProps extends RouteComponentProps<MatchParams> {}

const ResetPassword: React.FC<ComponentProps> = ({ match }) => {
  const [email, setEmail] = useState("");
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({
    type: "error",
    isActive: false,
    message: "",
  });

  useEffect(() => {
    if (
      match.params.encryptedEmail &&
      match.params.encryptedEmail.trim() !== ""
    ) {
      setEmail(decrypt(match.params.encryptedEmail));
    }
  }, [match]);
  return success ? (
    <Redirect to="/" />
  ) : (
    <section className="reset-password">
      {email ? (
        <div className="reset-password__input-container">
          <div className="reset-password__prompt">
            Password reset for {email}
          </div>
          <input
            className="modal__input"
            type="password"
            ref={passwordRef}
            placeholder="New Password"
          />
          <input
            className="modal__input"
            type="password"
            ref={confirmPasswordRef}
            placeholder="Confirm Password"
          />
          {error.isActive && (
            <div className={`modal__${error.type}`}>{error.message}</div>
          )}
          <div
            className="modal__submit"
            onClick={async () => {
              if (
                passwordRef.current &&
                passwordRef.current.value.trim() !== "" &&
                passwordRef.current.value.length > 5
              ) {
                if (
                  confirmPasswordRef.current &&
                  passwordRef.current.value === confirmPasswordRef.current.value
                ) {
                  const request = await fetch(
                    `${GetBaseURL()}/api/users/resetPassword?key=${
                      process.env.REACT_APP_PERSONAL_AUTH_KEY
                    }`,
                    {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        email,
                        password: NewEncryption.encrypt(
                          passwordRef.current.value
                        ),
                      }),
                    }
                  );
                  const response = await request.json();
                  if (request.status === 200) {
                    setError({
                      type: "success",
                      isActive: true,
                      message: `Successfully changed password! Redirecting to home page in a few seconds...`,
                    });
                    setTimeout(() => {
                      setSuccess(true);
                    }, 3000);
                  } else {
                    setError({
                      type: "error",
                      isActive: true,
                      message: "There was an error. Please try again later.",
                    });
                  }
                } else {
                  setError({
                    type: "error",
                    isActive: true,
                    message: "Passwords do not match.",
                  });
                }
              } else {
                setError({
                  type: "error",
                  isActive: true,
                  message: "Password must contain at least 6 characters.",
                });
              }
            }}
          >
            Reset Password
          </div>
          <div className="reset-password__logo">
            <span className="reset-password__logo--upper">NO FUSS</span>
            <span className="reset-password__logo--lower">READERS</span>
          </div>
        </div>
      ) : (
        <div className="reset-password__fetching">Fetching user data...</div>
      )}
    </section>
  );
};

export default ResetPassword;
