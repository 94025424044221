import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useStories } from "../Hooks/useStories";
import { useUserData } from "../Hooks/useUserData";
import { Phonics, StudentData, TrackedWords, WordPhonics } from "../Models";
import { generateID } from "../Utils/GenerateID";
import { ModalProps } from "./StoryComponent";

const Tracker: React.FC = () => {
  const { userData, updateTrackedWords, addWordAsRecentlyLearned } =
    useUserData();
  const { storyData } = useStories();
  const [trackedWords, setTrackedWords] = useState<TrackedWords[]>([]);
  const [tracker, setTracker] = useState<ModalProps[]>([]);
  const [successfulSave, setSuccessfulSave] = useState({
    display: false,
    message: "",
  });
  useEffect(() => {
    if (userData.account_type === "student") {
      setTrackedWords((userData.user_data as StudentData).perm_tracked_words);
    }
  }, [userData]);

  const clickPhonic = (
    word: string,
    definitions: string[],
    phonics: WordPhonics[],
    phonicNames: string,
    position: number
  ) => {
    let newTrackedWords: TrackedWords[] = trackedWords;
    const indexOfWord = trackedWords.findIndex((val) => val.word === word);
    if (
      indexOfWord !== -1 &&
      !trackedWords[indexOfWord].positions.includes(position)
    ) {
      newTrackedWords[indexOfWord].positions.push(position);
      newTrackedWords[indexOfWord].displayPhonics.push(phonicNames);
    } else if (indexOfWord === -1) {
      newTrackedWords.push({
        word,
        definitions,
        phonics,
        positions: [],
        displayPhonics: [],
        times_clicked: 1,
      });
    } else {
      newTrackedWords[indexOfWord].positions.splice(
        newTrackedWords[indexOfWord].positions.indexOf(position),
        1
      );
      trackedWords[indexOfWord].displayPhonics.splice(
        newTrackedWords[indexOfWord].displayPhonics.indexOf(phonicNames),
        1
      );
    }
    setTrackedWords(newTrackedWords);
    const newTracker = tracker.filter((val) => val.word !== word);
    setTracker([
      ...newTracker,
      {
        word,
        definitions: [],
        phonics: [],
        images: [],
        displayPhonics: newTrackedWords[indexOfWord].displayPhonics,
        imageNumber: 0,
      },
    ]);
  };

  const removeWord = (word: string) => {
    setTrackedWords((prev: TrackedWords[]) =>
      prev.filter((val) => val.word !== word)
    );
    addWordAsRecentlyLearned(word);
  };

  useEffect(() => {
    const beforeUnloadEvent = async (e: any) => {
      if (userData._id) {
        await updateTrackedWords(trackedWords);
      }
    };
    window.addEventListener("beforeunload", beforeUnloadEvent);
    return () => window.removeEventListener("beforeunload", beforeUnloadEvent);
  }, [trackedWords]);

  return localStorage.getItem("id") || (userData._id && userData._id !== "") ? (
    <div className="tracker">
      <div
        className="tracker__back-home-arrow"
        onClick={async () => {
          if (userData._id) {
            await updateTrackedWords(trackedWords);
          }
        }}
      >
        <Link to="/">
          <div>
            <img
              className="tracker__back-home-arrow--img"
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/back-arrow.svg`}
              alt={"No Image"}
            />
          </div>
        </Link>
        <div className="tracker__back-home-arrow--total-tracked">
          {trackedWords.length} words on {userData.user_data.name}'s tracked
          list
        </div>
        <div className="tracker__back-home-arrow--right-flex"></div>
      </div>

      {userData._id && userData._id !== "" ? (
        <div className="tracker__tracking-container">
          {/* <div className="tracker__tracking-video-container">
            <iframe
              src="https://player.vimeo.com/video/588282126"
              width="640"
              height="564"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </div> */}
          {trackedWords.length === 0 && (
            <div className="tracker__tracking-section">
              <div className="tracker__tracking-section--empty">
                Click words in stories to start tracking!
              </div>
            </div>
          )}
          {trackedWords.map((wordProperties) => {
            const timesClicked =
              trackedWords[
                trackedWords.findIndex(
                  (val) => val.word === wordProperties.word
                )
              ].times_clicked;
            return (
              <React.Fragment key={generateID()}>
                {timesClicked && timesClicked > 0 && (
                  <div className="tracker__tracking-section">
                    <div className="tracker__tracking-section--flex-row">
                      <div className="tracker__tracking-section--top">
                        <div className="tracker__tracking-section--word-wrapper">
                          <span className="tracker--word">
                            {wordProperties.word}
                          </span>
                        </div>
                        <span className="tracker__tracking-section--times-clicked">
                          {timesClicked} times clicked
                        </span>
                      </div>

                      <div className="tracker__tracking-section--phonic-word-container">
                        {wordProperties.phonics.map((phonic, phonicIndex) => {
                          let currentPhonics: Phonics[] = [];
                          for (const phoneme of phonic.phonemes) {
                            const foundPhonic = storyData.phonics.find(
                              (fullPhonic) => fullPhonic.phoneme === phoneme
                            );
                            if (foundPhonic) {
                              currentPhonics.push(foundPhonic);
                            }
                          }

                          return (
                            <React.Fragment key={generateID()}>
                              <div className="tracker__tracking-section--phonic-part-container">
                                <span
                                  className={`${
                                    trackedWords[
                                      trackedWords.findIndex(
                                        (val) =>
                                          val.word === wordProperties.word
                                      )
                                    ].positions.includes(phonic.position)
                                      ? phonic.position === 1
                                        ? "tracker__phonic-part--first "
                                        : phonic.position ===
                                          wordProperties.phonics.length
                                        ? "tracker__phonic-part--last "
                                        : phonic.position === 2
                                        ? "tracker__phonic-part--second "
                                        : phonic.position ===
                                          wordProperties.phonics.length - 1
                                        ? "tracker__phonic-part--penultimate "
                                        : "tracker__phonic-part--middle "
                                      : ""
                                  }tracker__tracking-section--phonic-part`}
                                >
                                  {`${phonic.part}`}
                                </span>
                                <span
                                  className={`${
                                    trackedWords[
                                      trackedWords.findIndex(
                                        (val) =>
                                          val.word === wordProperties.word
                                      )
                                    ].positions.includes(phonic.position)
                                      ? phonic.position === 1
                                        ? "tracker__phonic-click--first "
                                        : phonic.position ===
                                          wordProperties.phonics.length
                                        ? "tracker__phonic-click--last "
                                        : phonic.position === 2
                                        ? "tracker__phonic-click--second "
                                        : phonic.position ===
                                          wordProperties.phonics.length - 1
                                        ? "tracker__phonic-click--penultimate "
                                        : "tracker__phonic-click--middle "
                                      : ""
                                  }tracker__tracking-section--phonic-click`}
                                  onClick={() =>
                                    clickPhonic(
                                      wordProperties.word,
                                      wordProperties.definitions,
                                      wordProperties.phonics,
                                      phonic.phonemes.length > 1 &&
                                        phonic.multiple
                                        ? currentPhonics
                                            .map(
                                              (fullPhonic) => fullPhonic.name
                                            )
                                            .join(" AND ")
                                        : !phonic.multiple
                                        ? currentPhonics
                                            .map(
                                              (fullPhonic) => fullPhonic.name
                                            )
                                            .join(" OR ")
                                        : currentPhonics.map(
                                            (fullPhonic) => fullPhonic.name
                                          )[0],
                                      phonic.position
                                    )
                                  }
                                ></span>
                                {currentPhonics.map(
                                  (fullPhonic, fullPhonicIndex) => (
                                    <React.Fragment key={generateID()}>
                                      <div
                                        onClick={() => {
                                          let audioToPlay: any =
                                            document.getElementById(
                                              `phonic-audio-${fullPhonic.audio}`
                                            );
                                          audioToPlay.play();
                                        }}
                                      >
                                        <audio
                                          id={`phonic-audio-${fullPhonic.audio}`}
                                        >
                                          <source
                                            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${fullPhonic.audio}`}
                                          ></source>
                                        </audio>
                                        <i className="fas fa-volume-up story__modal--phonic-sound-icon"></i>
                                      </div>
                                    </React.Fragment>
                                  )
                                )}
                              </div>
                            </React.Fragment>
                          );
                        })}

                        <div
                          onClick={() => {
                            let audioToPlay: any = document.getElementById(
                              `word-audio-${wordProperties.word}`
                            );
                            audioToPlay.play();
                          }}
                        >
                          <audio id={`word-audio-${wordProperties.word}`}>
                            <source
                              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${wordProperties.word}.mp3`}
                            ></source>
                          </audio>
                          <i className="fas fa-volume-up story__modal--sound-icon"></i>
                        </div>
                      </div>
                    </div>
                    <button
                      className="tracker__tracking-section--remove-word"
                      onClick={() => removeWord(wordProperties.word)}
                    >
                      <i className="tracker__tracking-section--minus-icon">
                        Learned?
                      </i>
                    </button>

                    <div className="tracker__tracking-section--display-phonics">
                      {trackedWords[
                        trackedWords.findIndex(
                          (val) => val.word === wordProperties.word
                        )
                      ].displayPhonics.length > 0 && (
                        <span className="">
                          {trackedWords[
                            trackedWords.findIndex(
                              (val) => val.word === wordProperties.word
                            )
                          ].displayPhonics.join(" | ")}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <div className="tracker__tracking-section--login-prompt">
          Unlock my tracking!
        </div>
      )}
    </div>
  ) : (
    <Redirect to="/" />
  );
};

export default Tracker;
