import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDisplayData } from "../../Hooks/useDisplayData";
import { useStories } from "../../Hooks/useStories";
import { useUserData } from "../../Hooks/useUserData";
import { ParentData, ReadingLevels, StudentData } from "../../Models";

const MiniGames: React.FC = ({}) => {
  const {
    displayData,
    showModal,
    hideModal,
    showStripeModal,
    // redirectToStory,
    showAddChildProfileModal,
    setHomePageScrollHeight,
    showChildProfilesModal,
    setScrollToStories,
    showGamesModal,
    showComingSoonModal,
    showTrackedWordsModal,
  } = useDisplayData();

  const { userData, setUser } = useUserData();

  useEffect(() => {
    if (
      userData.account_type === "parent" &&
      (userData.user_data as ParentData).linked_accounts.length === 0
    ) {
      showAddChildProfileModal();
    }
  }, [userData]);

  return (
    <div className="user-details__minigames">
      <div className="user-details__minigames--title">Mini Games (2)</div>

      <div className="user-details__minigames--minigame-container">
        {/* <Link to="/rollandread" target="_blank">
          <div className="user-details__minigames--minigame--card">
            <div className="user-details__minigames--minigame--title">
              Roll &amp; Read
            </div>
            <div className="user-details__minigames--minigame--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/dice.png"
                alt="Book Icon"
                className="user-details__minigames--minigame--img"
              />
            </div>
          </div>
        </Link> */}

        <Link to="/tictactoe" target="_blank">
          <div className="user-details__minigames--minigame--card">
            <div className="user-details__minigames--minigame--title">
              Tic Tac Toe
            </div>
            <div className="user-details__minigames--minigame--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/tic-tac-toe.svg"
                alt="Book Icon"
                className="user-details__minigames--minigame--img"
              />
            </div>
          </div>
        </Link>

        <Link to="/memorygame" target="_blank">
          <div className="user-details__minigames--minigame--card">
            <div className="user-details__minigames--minigame--title">
              Memory Game
            </div>
            <div className="user-details__minigames--minigame--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/Wisdom.svg"
                alt="Book Icon"
                className="user-details__minigames--minigame--img"
              />
            </div>
          </div>
        </Link>
        <div className="user-details__minigames--minigame--card">
          <div className="user-details__minigames--minigame--title">
            More games coming soon!
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniGames;
