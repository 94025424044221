import React, { useState, useEffect, ChangeEvent } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { GetBaseURL } from "../../Utils";
import { useUserData } from "../../Hooks/useUserData";
import { useDisplayData } from "../../Hooks/useDisplayData";
import { Redirect } from "react-router-dom";

type PaymentFormProps = {
  planType: "annual" | "monthly" | null;
  setChangeCard?: (value: React.SetStateAction<boolean>) => void;
  setPaymentMethod?: (value: React.SetStateAction<any>) => void;
};

const PaymentForm: React.FC<PaymentFormProps> = ({
  planType,
  setChangeCard,
  setPaymentMethod,
}) => {
  const { userData, updateUserWithSubscription } = useUserData();
  const { hideModal } = useDisplayData();
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [error, setError] = useState<string | null | undefined>(null);
  const [processing, setProcessing] = useState<boolean>(false); //double check type
  const [disabled, setDisabled] = useState<boolean>(true);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [redirect, setRedirect] = useState(false);

  const stripe = useStripe()!;
  const elements = useElements()!;

  const createSubscriptionRequest = async () => {
    const request = await fetch(
      `${GetBaseURL()}/api/payments/create-subscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId:
            planType === "annual"
              ? `${process.env.REACT_APP_STRIPE_ANNUAL_PRICE}`
              : `${process.env.REACT_APP_STRIPE_MONTHLY_PRICE}`,
          customerId: userData.subscription_data.stripe_customer_id,
        }),
      }
    );
    const response = await request.json();
    if (request.status === 200) {
      setClientSecret(response.clientSecret);
    } else {
      console.log(response.message);
    }
  };

  useEffect(() => {
    if (
      userData.subscription_data.stripe_customer_id &&
      userData.subscription_data.stripe_customer_id !== ""
    ) {
      if (planType) {
        createSubscriptionRequest();
      }
    }
  }, [planType, userData.subscription_data.stripe_customer_id]);

  const cardStyle = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: "#fff",

        fontFamily: "Fellix-SemiBold, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        color: "white",

        "::placeholder": {
          color: "#c4f0ff",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();
    setProcessing(true);

    if (planType) {
      fetch(
        `${GetBaseURL()}/api/users/trackProductView?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: userData.user_data.email,
            content_name: "Added payment information",
            event_name: "AddPaymentInfo",
          }),
        }
      );
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement)!,
          },
          setup_future_usage: "off_session",
        })
        .then((result) => {
          if (result.error) {
            setError(result.error.message);
          } else {
            setSucceeded(true);
            updateUserWithSubscription(
              planType,
              result.paymentIntent.payment_method
            );
            setRedirect(true);
            hideModal();
          }
        });
    } else {
      stripe
        .createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement)!,
        })
        .then((result) => {
          if (result.error) {
            setError("Error reading card information.");
          } else {
            setSucceeded(true);
            updateUserWithSubscription("card", result.paymentMethod.id);
            setPaymentMethod && setPaymentMethod(result.paymentMethod);
            setChangeCard && setChangeCard(false);
          }
        });
    }
  };

  return redirect ? (
    <Redirect to="/success" />
  ) : (
    <form
      id="payment-form"
      className="modal__stripe--payment-form"
      onSubmit={handleSubmit}
    >
      {/* <fieldset>
        <div className="row modal__stripe--payment-row">
          <label
            htmlFor="example1-name"
            data-tid="elements_examples.form.name_label"
          >
            Name
          </label>
          <input
            id="example1-name"
            data-tid="elements_examples.form.name_placeholder"
            type="text"
            placeholder="Jane Doe"
            required
            autoComplete="name"
          ></input>
        </div>
        <div className="row">
          <label
            htmlFor="example1-email"
            data-tid="elements_examples.form.email_label"
          >
            Email
          </label>
          <input
            id="example1-email"
            data-tid="elements_examples.form.email_placeholder"
            type="email"
            placeholder="janedoe@gmail.com"
            required
            autoComplete="email"
          ></input>
        </div>
        <div className="row">
          <label
            htmlFor="example1-phone"
            data-tid="elements_examples.form.phone_label"
          >
            Phone
          </label>
          <input
            id="example1-phone"
            data-tid="elements_examples.form.phone_placeholder"
            type="tel"
            placeholder="(941) 555-0123"
            required
            autoComplete="tel"
          ></input>
        </div>
      </fieldset> */}
      {setChangeCard && (
        <div
          className="modal__manage-card--back"
          onClick={() => setChangeCard(false)}
        >
          Go back
        </div>
      )}

      <fieldset className="modal__stripe--form-fieldset">
        <div className="row modal__stripe--payment-row">
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
        </div>
      </fieldset>

      <button
        disabled={processing || disabled || succeeded}
        id="submit"
        className="modal__stripe--payment-button"
      >
        <span id="button-text">
          {processing
            ? `Processing...`
            : planType
            ? `Pay ${planType === "annual" ? "$83.88" : "$9.99"} USD`
            : "Change Card"}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p>{succeeded ? "Payment succeeded" : null}</p>
    </form>
  );
};

export default PaymentForm;
