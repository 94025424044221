import { Story } from "../Models";

export const TopCategories = [
  "Family",
  "Structured Phonics",
  "Animals",
  "Space",
  "Friendship",
  "Planes, Trains and Automobiles",
  "Superhero",
  "Holiday",
];

export const PriortyStoryTitles = [
  "George the Giraffe",
  "Button Bunny Learns Colors",
  "Brooklyn Bear Plays Simon Says",
  "Button Bunny Learns Shapes",
  "Sam and Pam",
  "Mud Puddle",
  "Lizzy Won't Dance",
  "Elves Take a Day Off",
  "Santa's Helper",
  "Lilly Saves Christmas",
  "Hanukkah in Texas",
  "The Boy and The Snowman",
  "Christmas Tree",
  "Hunhu",
  "Ciara and her Baby Sister",
  "Route 66",
  "Looking Around",
  "Backyard Treasure",
  "Cup of Tea",
  "The Sandal",
  "Space Race",
  "Space House",
  "Base the Ball",
  "The Squirrel and the Tree",
  "The Big Race",
  "My Pet Dino Is Missing!",
  "My Voice",
  "That Mermaid’s Hair Can Sing!",
  "Drake the Dragon",
  "Drake Bakes Cakes",
  "Top Secret Stripes",
  "The Clock Shop",
  "Ari's Flying Bike",
  "Mermaid's Tail",
  "Winter",
  "Forest of Love",
  "Chuck the Truck",
  "Trucklandia 1",
  "Trucklandia 2",
  "Trucklandia 3",
  "I Like Trucks",
  "Suhail and the Red Ferrari",
  "The Dog Bone Mystery",
  "Inside Outside",
  "Aisha and her Dollhouse",
  "Buster's Toppings",
  "The Sweet and Salty Party",
  "Silly Ice Cream",
  "Rainbow Cupcakes",
  "Green Cloak",
  "Blue Cloak",
  "Superhero Machine",
  "Clumsy Clem",
  "The Five Superhero Rules",
];

export const ModifyStoryOrder = (
  storyArray: string[],
  foundStories: Story[]
) => {
  const priorityStories = storyArray.map(
    (storyTitle) => foundStories.find((story) => story.title === storyTitle)!
  );
  return [
    ...priorityStories,
    ...foundStories.filter((story) => !storyArray.includes(story.title)),
  ];
};
