import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDisplayData } from "../../Hooks/useDisplayData";
import { Question, Story } from "../../Models";
import { generateID } from "../../Utils";
import StoryCard from "./StoryCard";
import RedX from "../../assets/RedX.png";
import GreenCheck from "../../assets/GreenCheck.png";
import { useStories } from "../../Hooks/useStories";

export type StudentAnswers = {
  correct1: boolean;
  correct2: boolean;
  correct3: boolean;
};

type SelectedAnswers = {
  q1: string;
  q2: string;
  q3: string;
};

export type QuestionsProps = {
  questions: Question[];
  storyDetails: Story;
  onSubmit: (
    studentAnswers: StudentAnswers,
    selectedAnswers: SelectedAnswers,
    score: string
  ) => void;
  alreadySubmitted: boolean;
  previousAnswers: {
    studentAnswers: StudentAnswers;
    selectedAnswers: SelectedAnswers;
  };
  setShowQuestions?: (value: React.SetStateAction<boolean>) => void;
  hideCorrectAnswers: boolean;
  recordingSubmitted: boolean;
};

const Questions: React.FC<QuestionsProps> = ({
  questions,
  storyDetails,
  onSubmit,
  alreadySubmitted,
  previousAnswers,
  setShowQuestions,
  hideCorrectAnswers,
  recordingSubmitted,
}) => {
  const {
    displayData,
    //  redirectToStory
  } = useDisplayData();
  const { storyData } = useStories();
  const [error, setError] = useState({
    isActive: false,
    type: "error",
    message: "",
  });

  const [xDown, setXDown] = useState<number | null>(null);
  const [studentAnswers, setStudentAnswers] = useState(
    previousAnswers.studentAnswers
  );
  const [selectedAnswers, setSelectedAnswers] = useState(
    previousAnswers.selectedAnswers
  );
  const [isAnimationActive, setIsAnimationActive] = useState(false);
  const [score, setScore] = useState("");
  const [randomStory, setRandomStory] = useState<Story>(
    storyData.stories.filter(
      (story) =>
        story._id !== storyData.currentStoryID &&
        story.level === storyDetails.level
    ).length > 0
      ? storyData.stories.filter(
          (story) =>
            story._id !== storyData.currentStoryID &&
            story.level === storyDetails.level
        )[
          Math.floor(
            Math.random() *
              storyData.stories.filter(
                (story) =>
                  story._id !== storyData.currentStoryID &&
                  story.level === storyDetails.level
              ).length
          )
        ]
      : storyData.stories.filter(
          (story) => story._id !== storyData.currentStoryID
        )[Math.floor(Math.random() * storyData.stories.length)]
  );

  useEffect(() => {
    setRandomStory(
      storyData.stories.filter(
        (story) =>
          story._id !== storyData.currentStoryID &&
          story.level === storyDetails.level
      ).length > 0
        ? storyData.stories.filter(
            (story) =>
              story._id !== storyData.currentStoryID &&
              story.level === storyDetails.level
          )[
            Math.floor(
              Math.random() *
                storyData.stories.filter(
                  (story) =>
                    story._id !== storyData.currentStoryID &&
                    story.level === storyDetails.level
                ).length
            )
          ]
        : storyData.stories.filter(
            (story) => story._id !== storyData.currentStoryID
          )[Math.floor(Math.random() * storyData.stories.length)]
    );
  }, [alreadySubmitted]);

  const animate = () => {
    setIsAnimationActive(true);
    setTimeout(() => {
      setIsAnimationActive(false);
    }, 700);
  };

  // const handleTouchStart = (evt: any) => {
  //   evt.stopPropagation();
  //   const firstTouch = evt.touches[0];
  //   setXDown(firstTouch.clientX);
  // };

  // const handleTouchEnd = (evt: any) => {
  //   evt.stopPropagation();
  //   if (!xDown) {
  //     return;
  //   }

  //   const xUp = evt.changedTouches[0].clientX;

  //   const xDiff = xDown - xUp;

  //   if (xDiff < -30 && setShowQuestions) {
  //     flipPageBack();
  //   }

  //   /* reset values */
  //   setXDown(null);
  // };

  // const flipPageBack = () => {
  //   document.getElementsByClassName("story__page-container")[0].className =
  //     "story__page-container story__page--prev";
  //   setTimeout(() => {
  //     setShowQuestions && setShowQuestions(false);
  //     document.getElementsByClassName("story__page-container")[0].className =
  //       "story__page-container story__page--active";
  //   }, 200);
  //   setTimeout(() => {
  //     document.getElementsByClassName("story__page-container")[0].className =
  //       "story__page-container";
  //   }, 250);
  // };

  return (
    <div
      className="story__page-container"
      // onTouchStart={(e) => handleTouchStart(e)}
      // onTouchEnd={(e) => handleTouchEnd(e)}
    >
      {/* <div
        className="story__prev-page"
        onClick={(e) => {
          e.stopPropagation();

          flipPageBack();
        }}
      ></div> */}
      <div className="story__page" style={{ height: "656px" }}>
        <div className="questions__heading">Questions</div>

        {questions && questions.length > 0 ? (
          <>
            {questions.map((question, index) => (
              <React.Fragment key={generateID()}>
                <div className="questions__question">{question.question}</div>
                <div
                  className="questions__answers-container"
                  style={
                    alreadySubmitted ? { pointerEvents: "none" } : undefined
                  }
                >
                  <span
                    className={`questions__answer ${
                      (index === 0 && selectedAnswers.q1 === "a1") ||
                      (index === 1 && selectedAnswers.q2 === "a1") ||
                      (index === 2 && selectedAnswers.q3 === "a1")
                        ? "questions__answer--selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedAnswers((prev: any) => ({
                        q1: index === 0 ? "a1" : prev.q1,
                        q2: index === 1 ? "a1" : prev.q2,
                        q3: index === 2 ? "a1" : prev.q3,
                      }));
                      question.answer1.correct
                        ? setStudentAnswers((prev: any) => ({
                            correct1: index === 0 ? true : prev.correct1,
                            correct2: index === 1 ? true : prev.correct2,
                            correct3: index === 2 ? true : prev.correct3,
                          }))
                        : setStudentAnswers((prev: any) => ({
                            correct1: index === 0 ? false : prev.correct1,
                            correct2: index === 1 ? false : prev.correct2,
                            correct3: index === 2 ? false : prev.correct3,
                          }));
                    }}
                  >
                    {question.answer1.answer}
                  </span>
                  <span
                    className={`questions__answer ${
                      (index === 0 && selectedAnswers.q1 === "a2") ||
                      (index === 1 && selectedAnswers.q2 === "a2") ||
                      (index === 2 && selectedAnswers.q3 === "a2")
                        ? "questions__answer--selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedAnswers((prev: any) => ({
                        q1: index === 0 ? "a2" : prev.q1,
                        q2: index === 1 ? "a2" : prev.q2,
                        q3: index === 2 ? "a2" : prev.q3,
                      }));
                      question.answer2.correct
                        ? setStudentAnswers((prev: any) => ({
                            correct1: index === 0 ? true : prev.correct1,
                            correct2: index === 1 ? true : prev.correct2,
                            correct3: index === 2 ? true : prev.correct3,
                          }))
                        : setStudentAnswers((prev: any) => ({
                            correct1: index === 0 ? false : prev.correct1,
                            correct2: index === 1 ? false : prev.correct2,
                            correct3: index === 2 ? false : prev.correct3,
                          }));
                    }}
                  >
                    {question.answer2.answer}
                  </span>
                  <span
                    className={`questions__answer ${
                      (index === 0 && selectedAnswers.q1 === "a3") ||
                      (index === 1 && selectedAnswers.q2 === "a3") ||
                      (index === 2 && selectedAnswers.q3 === "a3")
                        ? "questions__answer--selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedAnswers((prev: any) => ({
                        q1: index === 0 ? "a3" : prev.q1,
                        q2: index === 1 ? "a3" : prev.q2,
                        q3: index === 2 ? "a3" : prev.q3,
                      }));
                      question.answer3.correct
                        ? setStudentAnswers((prev: any) => ({
                            correct1: index === 0 ? true : prev.correct1,
                            correct2: index === 1 ? true : prev.correct2,
                            correct3: index === 2 ? true : prev.correct3,
                          }))
                        : setStudentAnswers((prev: any) => ({
                            correct1: index === 0 ? false : prev.correct1,
                            correct2: index === 1 ? false : prev.correct2,
                            correct3: index === 2 ? false : prev.correct3,
                          }));
                    }}
                  >
                    {question.answer3.answer}
                  </span>
                  {alreadySubmitted && !hideCorrectAnswers && (
                    <i
                      className={`fa fa-${
                        index === 0
                          ? studentAnswers.correct1
                            ? "check questions__check"
                            : "times questions__times"
                          : index === 1
                          ? studentAnswers.correct2
                            ? "check questions__check"
                            : "times questions__times"
                          : studentAnswers.correct3
                          ? "check questions__check"
                          : "times questions__times"
                      }`}
                      aria-hidden="true"
                    ></i>
                  )}
                </div>
              </React.Fragment>
            ))}
            <div
              className={
                isAnimationActive
                  ? "story__finish story__animate"
                  : "story__finish"
              }
              style={
                hideCorrectAnswers && !recordingSubmitted
                  ? { pointerEvents: "none", background: "lightgray" }
                  : undefined
              }
              onClick={() => {
                animate();
                setScore(
                  `${
                    Object.values(studentAnswers).filter((result) => result)
                      .length
                  }/3`
                );
                if (hideCorrectAnswers) {
                  if (recordingSubmitted) {
                    onSubmit(
                      studentAnswers,
                      selectedAnswers,
                      `${
                        Object.values(studentAnswers).filter((result) => result)
                          .length
                      }/3`
                    );
                  } else {
                    setError({
                      isActive: true,
                      type: "error",
                      message:
                        "You have not submitted an audio recording and your instructor requires one.",
                    });
                  }
                } else {
                  onSubmit(
                    studentAnswers,
                    selectedAnswers,
                    `${
                      Object.values(studentAnswers).filter((result) => result)
                        .length
                    }/3`
                  );
                }
              }}
            >
              {hideCorrectAnswers
                ? recordingSubmitted
                  ? "Submit Assignment"
                  : "Record Audio First"
                : "Check My Answers"}
            </div>
            {alreadySubmitted && (
              <div>
                <div className="questions__score">
                  Score: {hideCorrectAnswers ? "Hidden" : score}
                </div>
                <div className="modal__prompt">
                  {hideCorrectAnswers
                    ? "Your assignment has been submitted!"
                    : score === "2/3" || score === "3/3"
                    ? "Congratulations! Want to read another story?"
                    : "Try practicing this story more before moving to another one."}
                </div>
                <Link to="/" className="questions__back-home">
                  <div>Back to Stories</div>
                </Link>
                {/* <StoryCard
                  id={randomStory._id}
                  title={randomStory.title}
                  level={randomStory.level}
                  tags={randomStory.tags}
                  cover={randomStory.cover}
                  wordCount={
                    Array.from(
                      new Set(
                        randomStory.pages
                          .map((page) => {
                            return page.page_text
                              .replace(/\r/g, "")
                              .split(/\n/)
                              .join(";;")
                              .split(";;")
                              .join(" ")
                              .split(" ");
                          })
                          .flat()
                      )
                    ).length
                  }
                  priority_tag={randomStory.priority_tag}
                  // onclick={() => redirectToStory(true, randomStory._id)}
                ></StoryCard> */}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="questions__coming-soon">
              Questions for this story are coming soon!
            </div>
            <button
              className={
                isAnimationActive
                  ? "story__finish story__animate"
                  : "story__finish"
              }
              onClick={() => {
                animate();
                setScore(`None`);
                onSubmit(studentAnswers, selectedAnswers, `0/0`);
              }}
            >
              Finish
            </button>

            {alreadySubmitted && (
              <Link to="/" className="questions__back-home">
                <div>Back to Stories</div>
              </Link>
              // <StoryCard
              //   id={randomStory._id}
              //   title={randomStory.title}
              //   level={randomStory.level}
              //   tags={randomStory.tags}
              //   cover={randomStory.cover}
              //   wordCount={
              //     Array.from(
              //       new Set(
              //         randomStory.pages
              //           .map((page) => {
              //             return page.page_text
              //               .replace(/\r/g, "")
              //               .split(/\n/)
              //               .join(";;")
              //               .split(";;")
              //               .join(" ")
              //               .split(" ");
              //           })
              //           .flat()
              //       )
              //     ).length
              //   }
              //   priority_tag={randomStory.priority_tag}
              //   // onclick={() => redirectToStory(true, randomStory._id)}
              // ></StoryCard>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Questions;
