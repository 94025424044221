import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useStories } from "../../Hooks/useStories";

import { useUserData } from "../../Hooks/useUserData";
import {
  Phonics,
  Story,
  StoryPage,
  TrackedWords,
  WordPhonics,
  Words,
} from "../../Models";
import { ModalProps } from "../../Pages/StoryComponent";
import { generateID } from "../../Utils";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { contractions } from "../../Utils/WordHelpers";

export type MappedWordDetails =
  | false
  | {
      word: string;
      wordIndex: number;
      lineIndex: number;
      wordContent: Words | undefined;
    };

type StoryProgressBarProps = {
  permTrackedWords: TrackedWords[];
  // setShowHeader: (value: React.SetStateAction<boolean>) => void;
  scrollDistance?: number;
  wordContainerRef: React.RefObject<HTMLDivElement>;
  storyWrapperRef: React.RefObject<HTMLDivElement>;
  setOverrideFontSize: (value: React.SetStateAction<number>) => void;
  modalProperties: ModalProps;
  trackedWords: TrackedWords[];
  clickPhonic?: (
    word: string,
    definitions: string[],
    phonics: WordPhonics[],
    phonicNames: string,
    position: number,
    stayClicked?: boolean
  ) => void;
  storyPages: StoryPage[];
  onClick: (
    word: string,
    definitions: string[],
    phonics: WordPhonics[],
    pageNumber: number,
    images?: string[] | undefined,
    audio?: string | undefined
  ) => void;
  setOverrideWordHighlight: (
    value: React.SetStateAction<{
      overrideWordToHighlight: string;
      overrideWordToHighlightIndex: number;
      overrideWordToHighlightLineIndex: number;
    }>
  ) => void;
  lineDetails: { line: string; lineIndex: number };
  setLineDetails: (
    value: React.SetStateAction<{ line: string; lineIndex: number }>
  ) => void;
  setLine: (line: string, lineIndex: number, pageNumber: number) => void;
  setModalProperties: (value: React.SetStateAction<ModalProps>) => void;
  voiceWordProperties: { wordIndex: number; pageNumber: number };
};

const StoryProgressBar: React.FC<StoryProgressBarProps> = ({
  permTrackedWords,
  // setShowHeader,
  scrollDistance,
  wordContainerRef,
  storyWrapperRef,
  setOverrideFontSize,
  modalProperties,
  trackedWords,
  clickPhonic,
  storyPages,
  onClick,
  setOverrideWordHighlight,
  lineDetails,
  setLineDetails,
  setLine,
  setModalProperties,
  voiceWordProperties,
}) => {
  const { userData, updateTrackedWords } = useUserData();
  const { storyData } = useStories();
  const {
    transcript,
    listening,
    resetTranscript,
    // browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const [currentPage, setCurrentPage] = useState(1);
  const [mappedWords, setMappedWords] = useState<MappedWordDetails[]>([]);
  const [mappedWordIndex, setMappedWordIndex] = useState(0);

  const [showVoiceDropdown, setShowVoiceDropdown] = useState(false);
  const [showProgressBarDropdown, setShowProgressBarDropdown] = useState(false);
  const [fullTranscript, setFullTranscript] = useState("");
  const [finished, setFinished] = useState(false);
  const [speechMode, setSpeechMode] = useState({
    wordByWord: true,
    lineByLine: false,
  });

  // useEffect(() => {
  //   if (listening) {
  //     const splitTranscript = transcript.split(" ");
  //     if (splitTranscript.includes(modalProperties.word)) {
  //       resetTranscript();
  //     }
  //     // highlight next word function goes here and cofetti animation
  //   }
  // }, [modalProperties]);

  useLayoutEffect(() => {
    setMappedWordIndex(voiceWordProperties.wordIndex);

    setCurrentPage(voiceWordProperties.pageNumber);
  }, [voiceWordProperties]);

  // useLayoutEffect(() => {
  if (listening) {
    if (finished) {
      SpeechRecognition.stopListening();
      setCurrentPage(1);
      setLine("", 0, 1);
    }
    if (speechMode.wordByWord) {
      if (
        transcript
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(modalProperties.word)
      ) {
        setFullTranscript((prev: string) => prev + transcript + " ");
        // SpeechRecognition.stopListening();
        resetTranscript();
        if (
          currentPage >= storyPages.length &&
          mappedWordIndex === mappedWords.length - 1
        ) {
          setFinished(true);
          SpeechRecognition.stopListening();
        }

        const mappedWord = mappedWords[mappedWordIndex];
        if (mappedWord && mappedWord.wordContent) {
          setOverrideWordHighlight({
            overrideWordToHighlight: mappedWord.word,
            overrideWordToHighlightIndex: mappedWord.wordIndex,
            overrideWordToHighlightLineIndex: mappedWord.lineIndex,
          });
          onClick(
            mappedWord.wordContent.text,
            mappedWord.wordContent.definitions,
            mappedWord.wordContent.phonics,
            currentPage,
            mappedWord.wordContent.images,
            mappedWord.wordContent.audio
          );
        } else if (mappedWord) {
          onClick(mappedWord.word, [""], [], currentPage);
        }

        if (mappedWords[mappedWordIndex + 1] === false) {
          setMappedWordIndex(mappedWordIndex + 2);
        } else {
          setMappedWordIndex(mappedWordIndex + 1);
        }

        if (mappedWordIndex >= mappedWords.length - 1) {
          setCurrentPage(currentPage + 1);
          setMappedWordIndex(0);
        }
      }
    }
    if (speechMode.lineByLine) {
      if (transcript !== "") {
        let arrayOfTruths = lineDetails.line
          .toLowerCase()
          .replace(/\[|\]|\"|\”|”|\“|“|\:|\;|\(|\)|\.|\,|\?|\!/gi, "")
          .split(" ")
          .map((word: string) =>
            transcript
              .toLowerCase()
              .replace(/\[|\]|\"|\”|”|\“|“|\:|\;|\(|\)|\.|\,|\?|\!/gi, "")
              .includes(word)
          );

        if (!arrayOfTruths.includes(false)) {
          if (finished) {
            setLine("", 0, 1);
            setCurrentPage(1);
          } else {
            if (
              storyPages[currentPage - 1].lines[lineDetails.lineIndex + 1] !==
                undefined &&
              storyPages[currentPage - 1].lines[
                lineDetails.lineIndex + 1
              ].trim() === "" &&
              lineDetails.lineIndex + 2 <
                storyPages[currentPage - 1].lines.length
            ) {
              setLine(
                storyPages[currentPage - 1].lines[lineDetails.lineIndex + 2],
                lineDetails.lineIndex + 2,
                currentPage
              );
            } else if (
              lineDetails.lineIndex + 1 <
              storyPages[currentPage - 1].lines.length
            ) {
              setLine(
                storyPages[currentPage - 1].lines[lineDetails.lineIndex + 1],
                lineDetails.lineIndex + 1,
                currentPage
              );
            }

            if (
              lineDetails.lineIndex >=
              storyPages[currentPage - 1].lines.length - 1
            ) {
              if (currentPage < storyPages.length) {
                setLine(storyPages[currentPage].lines[0], 0, currentPage + 1);
                onClick("", [], [], currentPage + 1);
                setCurrentPage(currentPage + 1);
              } else {
                setFinished(true);
              }
            } else {
              onClick("", [], [], currentPage);
            }
          }
          resetTranscript();
        }
      }
    }
    // highlight next word function goes here and cofetti animation
  }
  // }, [listening]);

  useLayoutEffect(() => {
    if (currentPage - 1 < storyPages.length) {
      setMappedWords(
        storyPages[currentPage - 1].lines
          .map(
            (line, lineIndex) =>
              line.trim() !== "" &&
              line.split(" ").map((word, wordIndex) => {
                let tempWord = word
                  .replace(/\[|\]|\"|\”|”|\“|“|\:|\;|\(|\)|\.|\,|\?|\!/gi, "")
                  .replace(/\’|’/gi, "'");
                if (!contractions.includes(tempWord.toLowerCase())) {
                  tempWord = tempWord.replaceAll(/\'s|'s|\'|'/g, "");
                }
                const wordContent = storyData.words.find((item) => {
                  return (
                    item.text.localeCompare(tempWord, "en", {
                      sensitivity: "base",
                    }) === 0
                  );
                });
                return { word, wordIndex, lineIndex, wordContent };
              })
          )
          .flat()
      );
    }
  }, [storyData, currentPage, speechMode]);

  return (
    <div className="story__page-number--modalize-wrapper">
      <div className="story__page-number--modalize">
        <Link
          to="/"
          className="story__back-home-link"
          style={{ left: "0", top: "0" }}
        >
          <div
            className="story__back-home-arrow story__back-home-arrow--modalize"
            onClick={async () => {
              if (userData._id) {
                await updateTrackedWords(permTrackedWords);
              }
            }}
          >
            <i
              className="fa fa-chevron-left story__back-home-arrow"
              style={{ color: "#3b96ff", top: "-10px", left: "10px" }}
            />
          </div>
        </Link>

        {/* <div className="story__page-number--label-wrapper">
          <span className="story__page-number--label">Page</span>
          <span className="story__page-number--label-2"> {page}</span>
          <span className="story__page-number--label-2">/</span>
          <span className="story__page-number--label-2">
            {storyDetails.pages.length}
          </span>
        </div> */}
      </div>
      <div>
        <progress
          className="story__progress-bar story__progress-bar--modalize"
          value={scrollDistance}
          max={
            storyWrapperRef.current?.scrollHeight
              ? storyWrapperRef.current.scrollHeight - 900
              : undefined
          }
        ></progress>
      </div>
      <span className="story__progress-bar--options">
        {/* {SpeechRecognition.browserSupportsSpeechRecognition() && (
          <div
            className="story__progress-bar--voice"
            onClick={() => {
              setShowVoiceDropdown(!showVoiceDropdown);
              setShowProgressBarDropdown(false);
            }}
          >
            <i className="fas fa-microphone-alt"></i>
          </div>
        )} */}
        <div
          onClick={() => {
            setShowProgressBarDropdown(!showProgressBarDropdown);
            setShowVoiceDropdown(false);
          }}
        >
          <i className="fa fa-text">T</i>
          <i className="fa fa-text" style={{ fontSize: "1.8rem" }}>
            T
          </i>
        </div>
      </span>

      {/* <span
        className="story__progress-bar--close"
        // onTouchStart={() => setShowHeader(false)}
        onClick={() => setShowHeader(false)}
      >
        X
      </span> */}
      {showVoiceDropdown && (
        <div className="story__progress-bar--voice-dropdown">
          <div className="story__progress-bar--voice-dropdown-arrow"></div>

          <div>
            {modalProperties.phonics.length === 0 &&
            currentPage === 1 &&
            mappedWordIndex === 0 &&
            !listening ? (
              !(speechMode.lineByLine || speechMode.wordByWord) ? (
                <div>
                  <span>Click one of the following options</span>
                </div>
              ) : (
                <div>
                  <span>Click the microphone to start recording</span>
                </div>
              )
            ) : modalProperties.phonics.length === 0 && !listening ? (
              <div>
                <span className="story__modal--word">
                  Word not available yet
                </span>
              </div>
            ) : null}

            {finished ? (
              <div>Finished!</div>
            ) : (
              <>
                {modalProperties.phonics.length !== 0 && (
                  <div className="story__modal--word-sound-wrapper">
                    <div className="story__modal--phonics">
                      {modalProperties.phonics.map((phonic, phonicIndex) => {
                        let currentPhonics: Phonics[] = [];
                        for (const phoneme of phonic.phonemes) {
                          const foundPhonic = storyData.phonics.find(
                            (fullPhonic) => fullPhonic.phoneme === phoneme
                          );
                          if (foundPhonic) {
                            currentPhonics.push(foundPhonic);
                          }
                        }
                        const indexOfWord = trackedWords!.findIndex(
                          (val) => val.word === modalProperties.word
                        );

                        return (
                          <React.Fragment key={generateID()}>
                            <div className="story__modal--phonic-part-container">
                              <span
                                className={`${
                                  trackedWords![indexOfWord].positions.includes(
                                    phonic.position
                                  )
                                    ? phonic.position === 1
                                      ? "story__phonic-part--first "
                                      : phonic.position ===
                                        modalProperties.phonics.length
                                      ? "story__phonic-part--last "
                                      : phonic.position === 2
                                      ? "story__phonic-part--second "
                                      : phonic.position ===
                                        modalProperties.phonics.length - 1
                                      ? "story__phonic-part--penultimate "
                                      : "story__phonic-part--middle "
                                    : ""
                                }${
                                  modalProperties.phonics.length > 5
                                    ? "story__modal--phonic-part-small"
                                    : "story__modal--phonic-part"
                                }`}
                              >
                                {`${phonic.part}`}
                              </span>
                              <span
                                className={`${
                                  trackedWords![indexOfWord].positions.includes(
                                    phonic.position
                                  )
                                    ? phonic.position === 1
                                      ? "story__phonic-click--first "
                                      : phonic.position ===
                                        modalProperties.phonics.length
                                      ? "story__phonic-click--last "
                                      : phonic.position === 2
                                      ? "story__phonic-click--second "
                                      : phonic.position ===
                                        modalProperties.phonics.length - 1
                                      ? "story__phonic-click--penultimate "
                                      : "story__phonic-click--middle "
                                    : ""
                                }${
                                  modalProperties.phonics.length > 5
                                    ? "story__modal--phonic-click-small"
                                    : "story__modal--phonic-click"
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();

                                  clickPhonic &&
                                    clickPhonic(
                                      modalProperties.word,
                                      modalProperties.definitions,
                                      modalProperties.phonics,
                                      phonic.phonemes.length > 1 &&
                                        phonic.multiple
                                        ? currentPhonics
                                            .map(
                                              (fullPhonic) => fullPhonic.name
                                            )
                                            .join(" AND ")
                                        : !phonic.multiple
                                        ? currentPhonics
                                            .map(
                                              (fullPhonic) => fullPhonic.name
                                            )
                                            .join(" OR ")
                                        : currentPhonics.map(
                                            (fullPhonic) => fullPhonic.name
                                          )[0],
                                      phonic.position
                                    );
                                }}
                              ></span>

                              {currentPhonics.map(
                                (fullPhonic, fullPhonicIndex) => {
                                  return (
                                    <React.Fragment key={generateID()}>
                                      <div
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // if (width > 1024) {
                                          let audioToPlay: any =
                                            document.getElementById(
                                              `phonic-audio-${fullPhonic.audio}`
                                            );
                                          audioToPlay.play();
                                          // }
                                        }}
                                      >
                                        <audio
                                          id={`phonic-audio-${fullPhonic.audio}`}
                                        >
                                          <source
                                            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${fullPhonic.audio}`}
                                          ></source>
                                        </audio>
                                        <i className="fas fa-volume-up story__modal--phonic-sound-icon"></i>
                                      </div>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                    {modalProperties.show && (
                      <React.Fragment key={generateID()}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();

                            let audioToPlay: any = document.getElementById(
                              `word-audio-${modalProperties.word}`
                            );
                            audioToPlay.play();
                          }}
                        >
                          <audio id={`word-audio-${modalProperties.word}`}>
                            <source
                              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${modalProperties.audio}`}
                            ></source>
                          </audio>
                          <i className="fas fa-volume-up story__modal--sound-icon"></i>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </>
            )}
            {listening &&
              speechMode.lineByLine &&
              storyPages[currentPage - 1].lines[lineDetails.lineIndex] && (
                <div>
                  {storyPages[currentPage - 1].lines[lineDetails.lineIndex]
                    .split(" ")
                    .map((word: string) => (
                      <span
                        className={
                          transcript
                            .toLowerCase()
                            .replace(/\s+/g, "")
                            .replace(
                              /\[|\]|\"|\”|”|\“|“|\:|\;|\(|\)|\.|\,|\?|\!/gi,
                              ""
                            )
                            .includes(
                              word
                                .toLowerCase()
                                .replace(/\s+/g, "")
                                .replace(
                                  /\[|\]|\"|\”|”|\“|“|\:|\;|\(|\)|\.|\,|\?|\!/gi,
                                  ""
                                )
                            )
                            ? "story__line--word-highlight"
                            : "story__line--word"
                        }
                        onClick={() => {
                          const mappedWord = mappedWords.find(
                            (value) => value && value.word === word
                          );
                          onClick(
                            word,
                            mappedWord && mappedWord.wordContent
                              ? mappedWord.wordContent.definitions
                              : [],
                            mappedWord && mappedWord.wordContent
                              ? mappedWord.wordContent.phonics
                              : [],
                            currentPage
                          );
                        }}
                      >
                        {word + " "}
                      </span>
                    ))}
                </div>
              )}
          </div>
          <div className="story__progress-bar--voice-container">
            {/* {listening && (speechMode.lineByLine || speechMode.wordByWord) && (
              <div
                onClick={() => {
                  setLine("", 0, 1);
                  setCurrentPage(1);
                  setMappedWordIndex(0);
                  SpeechRecognition.stopListening();

                  onClick("", [], [], currentPage);
                  setOverrideWordHighlight({
                    overrideWordToHighlight: "",
                    overrideWordToHighlightIndex: 0,
                    overrideWordToHighlightLineIndex: 0,
                  });
                  setFinished(false);
                  setSpeechMode({ wordByWord: false, lineByLine: false });
                }}
              >
                X
              </div>
            )} */}

            {
              <>
                <div
                  className="story__progress-bar--icon-wrapper"
                  onClick={() => {
                    setFinished(false);
                    if (listening) {
                      setLine("", 0, 1);
                      setCurrentPage(1);
                      setMappedWordIndex(0);
                      SpeechRecognition.stopListening();

                      onClick("", [], [], currentPage);
                      setOverrideWordHighlight({
                        overrideWordToHighlight: "",
                        overrideWordToHighlightIndex: 0,
                        overrideWordToHighlightLineIndex: 0,
                      });
                      setSpeechMode({ wordByWord: true, lineByLine: false });
                    } else {
                      if (speechMode.wordByWord) {
                        const mappedWord = mappedWords[mappedWordIndex];

                        if (mappedWord && mappedWord.wordContent) {
                          SpeechRecognition.startListening({
                            continuous: true,
                          });

                          setOverrideWordHighlight({
                            overrideWordToHighlight: mappedWord.word,
                            overrideWordToHighlightIndex: mappedWord.wordIndex,
                            overrideWordToHighlightLineIndex:
                              mappedWord.lineIndex,
                          });
                          onClick(
                            mappedWord.wordContent.text,
                            mappedWord.wordContent.definitions,
                            mappedWord.wordContent.phonics,
                            currentPage,
                            mappedWord.wordContent.images,
                            mappedWord.wordContent.audio
                          );
                        }
                      }
                      if (speechMode.lineByLine) {
                        setCurrentPage(1);
                        setLine(storyPages[0].lines[0], 0, 1);
                        SpeechRecognition.startListening({
                          continuous: true,
                        });
                      }
                    }
                  }}
                >
                  <i
                    className={
                      listening
                        ? "fas fa-microphone-alt story__progress-bar--icon-outline--listening"
                        : "fas fa-microphone-alt story__progress-bar--icon-outline"
                    }
                  ></i>
                </div>
              </>
            }
            {
              <div className="story__progress-bar--voice-type-wrapper">
                <div
                  onClick={() =>
                    setSpeechMode({ wordByWord: true, lineByLine: false })
                  }
                  className={
                    speechMode.wordByWord
                      ? "story__progress-bar--voice-type story__progress-bar--voice-type--selected"
                      : "story__progress-bar--voice-type"
                  }
                >
                  Word-By-Word
                </div>
                <div
                  onClick={() =>
                    setSpeechMode({ lineByLine: true, wordByWord: false })
                  }
                  className={
                    speechMode.lineByLine
                      ? "story__progress-bar--voice-type story__progress-bar--voice-type--selected"
                      : "story__progress-bar--voice-type"
                  }
                >
                  Line-By-Line
                </div>
              </div>
            }
          </div>
          {listening && (
            <div className="story__progress-bar--loader">
              <span className="story__progress-bar--audio-line"></span>
              <span className="story__progress-bar--audio-line"></span>
              <span className="story__progress-bar--audio-line"></span>
              <span className="story__progress-bar--audio-line"></span>
              <span className="story__progress-bar--audio-line"></span>
              <span className="story__progress-bar--audio-line"></span>
              <span className="story__progress-bar--audio-line"></span>
            </div>
          )}
          {(speechMode.lineByLine || speechMode.wordByWord) && (
            <>
              <div>{transcript}</div>
              {speechMode.wordByWord && listening && (
                <div
                  onClick={() => {
                    if (
                      currentPage >= storyPages.length &&
                      mappedWordIndex === 0
                    ) {
                      SpeechRecognition.stopListening();
                    }

                    const mappedWord = mappedWords[mappedWordIndex];
                    if (mappedWord && mappedWord.wordContent) {
                      setOverrideWordHighlight({
                        overrideWordToHighlight: mappedWord.word,
                        overrideWordToHighlightIndex: mappedWord.wordIndex,
                        overrideWordToHighlightLineIndex: mappedWord.lineIndex,
                      });
                      onClick(
                        mappedWord.wordContent.text,
                        mappedWord.wordContent.definitions,
                        mappedWord.wordContent.phonics,
                        currentPage,
                        mappedWord.wordContent.images,
                        mappedWord.wordContent.audio
                      );
                    } else if (mappedWord) {
                      onClick(mappedWord.word, [""], [], currentPage);
                    }

                    if (mappedWords[mappedWordIndex + 1] === false) {
                      setMappedWordIndex(mappedWordIndex + 2);
                    } else {
                      setMappedWordIndex(mappedWordIndex + 1);
                    }

                    if (mappedWordIndex >= mappedWords.length - 1) {
                      setCurrentPage(currentPage + 1);
                      setMappedWordIndex(0);
                    }
                  }}
                >
                  Next Word
                </div>
              )}
              {speechMode.lineByLine && listening && (
                <div
                  onClick={() => {
                    if (finished) {
                      setLine("", 0, 1);
                      setCurrentPage(1);
                    } else {
                      if (
                        storyPages[currentPage - 1].lines[
                          lineDetails.lineIndex + 1
                        ] !== undefined &&
                        storyPages[currentPage - 1].lines[
                          lineDetails.lineIndex + 1
                        ].trim() === "" &&
                        lineDetails.lineIndex + 2 <
                          storyPages[currentPage - 1].lines.length
                      ) {
                        setLine(
                          storyPages[currentPage - 1].lines[
                            lineDetails.lineIndex + 2
                          ],
                          lineDetails.lineIndex + 2,
                          currentPage
                        );
                      } else if (
                        lineDetails.lineIndex + 1 <
                        storyPages[currentPage - 1].lines.length
                      ) {
                        setLine(
                          storyPages[currentPage - 1].lines[
                            lineDetails.lineIndex + 1
                          ],
                          lineDetails.lineIndex + 1,
                          currentPage
                        );
                      }

                      if (
                        lineDetails.lineIndex >=
                        storyPages[currentPage - 1].lines.length - 1
                      ) {
                        if (currentPage < storyPages.length) {
                          setLine(
                            storyPages[currentPage].lines[0],
                            0,
                            currentPage + 1
                          );
                          onClick("", [], [], currentPage + 1);
                          setCurrentPage(currentPage + 1);
                        } else {
                          setFinished(true);
                        }
                      } else {
                        onClick("", [], [], currentPage);
                      }
                    }
                  }}
                >
                  Next Line
                </div>
              )}
              {/* {speechMode.wordByWord && (
                <div
                  onClick={() => {
                    if (
                      currentPage >= storyPages.length &&
                      mappedWordIndex === 0
                    ) {
                      SpeechRecognition.stopListening();
                    }

                    const mappedWord = mappedWords[mappedWordIndex];
                    if (mappedWord && mappedWord.wordContent) {
                      setOverrideWordHighlight({
                        overrideWordToHighlight: mappedWord.word,
                        overrideWordToHighlightIndex: mappedWord.wordIndex,
                        overrideWordToHighlightLineIndex: mappedWord.lineIndex,
                      });
                      onClick(
                        mappedWord.wordContent.text,
                        mappedWord.wordContent.definitions,
                        mappedWord.wordContent.phonics,
                        currentPage,
                        mappedWord.wordContent.images,
                        mappedWord.wordContent.audio
                      );
                    } else if (mappedWord) {
                      onClick(mappedWord.word, [""], [], currentPage);
                    }

                    if (mappedWords[mappedWordIndex + 1] === false) {
                      setMappedWordIndex(mappedWordIndex + 2);
                    } else {
                      setMappedWordIndex(mappedWordIndex + 1);
                    }

                    if (mappedWordIndex >= mappedWords.length - 1) {
                      setCurrentPage(currentPage + 1);
                      setMappedWordIndex(0);
                    }
                  }}
                >
                  Previous
                </div> 
                )} */}
            </>
          )}
        </div>
      )}
      {showProgressBarDropdown && (
        <div className="story__progress-bar--dropdown">
          <div className="story__progress-bar--dropdown-arrow"></div>
          <span
            onClick={() => {
              setOverrideFontSize((prev: number) => prev - 0.2);
            }}
            className="story__progress-bar--font-size"
          >
            <i className="fa fa-text">A</i>
          </span>
          <span
            onClick={() => {
              setOverrideFontSize((prev: number) => prev + 0.2);
            }}
            className="story__progress-bar--font-size"
          >
            <i className="fa fa-text" style={{ fontSize: "1.8rem" }}>
              A
            </i>
          </span>
        </div>
      )}
    </div>
  );
};

export default StoryProgressBar;
