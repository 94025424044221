import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useUserData } from "../../Hooks/useUserData";
import {
  ConvertGradeToNumberOrString,
  generateID,
  generateRandomNumberString,
  GetBaseURL,
  SendEmail,
  ValidateEmail,
  decrypt,
  SendHTMLEmail,
} from "../../Utils";
import { encrypt } from "../../Utils/Encryption";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { useDisplayData } from "../../Hooks/useDisplayData";
import {
  Grades,
  ParentData,
  Phonics,
  Story,
  StudentData,
  TrackedWords,
  Users,
} from "../../Models";
import StripeModal from "../StripeComponents/StripeModal";
import ManageSubscription from "../StripeComponents/ManageSubscription";
import StoryCard from "./StoryCard";
import { useStories } from "../../Hooks/useStories";
import { ReadingLevels } from "../../Models/ReadingLevels";
import StoryPageText from "./StoryPageText";
import StoryPreview from "./StoryPreview";
import NewEncryption from "../../Utils/NewEncryption";

type ModalProps = {
  login?: boolean;
  register?: boolean;
  addChildProfile?: boolean;
  inviteTeacher?: boolean;
  stripe?: boolean;
  signIntoClass?: boolean;
  fromBanner?: boolean;
  fromLimit?: boolean;
  manageSubscription?: boolean;
  childProfiles?: boolean;
  nextStory?: boolean;
  trackedWords?: boolean;
  childData?: StudentData;
  hideModal: () => void;
};

const Modal: React.FC<ModalProps> = ({
  login,
  register,
  addChildProfile,
  inviteTeacher,
  stripe,
  signIntoClass,
  fromBanner,
  fromLimit,
  manageSubscription,
  childProfiles,
  nextStory,
  trackedWords,
  childData,
  hideModal,
}) => {
  const {
    setUser,
    userData,
    setUserData,
    updateTrackedWords,
    addWordAsRecentlyLearned,
    updateReadingLevel,
    updateGrade,
    updateSubscriptionData,
  } = useUserData();
  const {
    displayData,
    showLoginModal,
    showRegisterModal,
    showAddChildProfileModal,
    // redirectToStory,
    setScrollToStories,
    setHomePageScrollHeight,
    setInitialReload,
    showStripeModal,
    showChildProfilesModal,
  } = useDisplayData();
  const { storyData } = useStories();
  const [randomStory, setRandomStory] = useState<Story>({
    _id: "",
    title: "",
    cover: "",
    pages: [],
    level: "",
    tags: [],
    priority_tag: "",
    questions: [],
    associated_words: [],
  });
  const [storiesRead, setStoriesRead] = useState<Story[]>([]);
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const teacherEmailRef = useRef<HTMLInputElement>(null);
  const emailTextRef = useRef<HTMLTextAreaElement>(null);
  const linkAccountRef = useRef<HTMLInputElement>(null);
  const sendEmailRef = useRef<HTMLInputElement>(null);
  const [userRole, setUserRole] = useState<"parent" | "teacher" | null>(null);
  const [emailLogin, setEmailLogin] = useState(false);
  const [studentLogin, setStudentLogin] = useState(false);
  const [showReadingLevelAnswer, setShowReadingLevelAnswer] = useState(false);
  const [classroomCode, setClassroomCode] = useState("");
  const [isAnimationActive, setIsAnimationActive] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [error, setError] = useState({
    isActive: false,
    type: "error",
    message: "",
  });

  useEffect(() => {
    if (displayData.storiesRead) {
      setStoriesRead(
        storyData.stories
          .filter((story) =>
            childData?.stories_read.some(
              (storyRead) => storyRead._id === story._id!
            )
          )
          .sort(
            (a, b) =>
              new Date(
                childData!.stories_read[
                  childData!.stories_read.findIndex(
                    (storyRead) => storyRead._id === b._id
                  )
                ].date_read
              ).getTime() -
              new Date(
                childData!.stories_read[
                  childData!.stories_read.findIndex(
                    (storyRead) => storyRead._id === a._id
                  )
                ].date_read
              ).getTime()
          )
      );
    }
  }, []);

  const animate = () => {
    setIsAnimationActive(true);
    setTimeout(() => {
      setIsAnimationActive(false);
    }, 700);
  };

  const googleRegister = async (response: any) => {
    if (response.profileObj) {
      const request = await fetch(
        `${GetBaseURL()}/api/users/register?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            account_type: userRole,
            username: response.profileObj.email,
            email: response.profileObj.email,
            name: response.profileObj.name,
            device:
              window.innerWidth <= 600
                ? "mobile"
                : window.innerWidth <= 1024
                ? "tablet"
                : "desktop",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const user = await request.json();
      if (request.status === 200) {
        setUser(user._id);

        showAddChildProfileModal();
        if (window.location.pathname.includes("ref")) {
          fetch(
            `${GetBaseURL()}/api/referrals/add?key=${
              process.env.REACT_APP_PERSONAL_AUTH_KEY
            }`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                referral_id: user._id,
                referer_id:
                  window.location.pathname.split("/")[
                    window.location.pathname.split("/").length - 1
                  ],
              }),
            }
          );
        }
        fetch(
          `${process.env.REACT_APP_MAIN_SERVER}/api/users/addUser?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
          {
            method: "POST",
            body: JSON.stringify({
              email: response.profileObj.email,
              tutorInterest: "Maybe Later",
              name: response.profileObj.name,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else if (request.status === 500) {
        googleLogin(response);
      } else {
        setError({
          isActive: true,
          message: user.message,
          type: "error",
        });
      }
    } else {
      console.log(response);
      setError({
        isActive: true,
        message: "Google authorization error. Please try again.",
        type: "error",
      });
    }
  };
  const facebookRegister = async (response: any) => {
    if (response.email) {
      const request = await fetch(
        `${GetBaseURL()}/api/users/register?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            account_type: userRole,
            username: response.email,
            email: response.email,
            name: response.name,
            device:
              window.innerWidth <= 600
                ? "mobile"
                : window.innerWidth <= 1024
                ? "tablet"
                : "desktop",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const user = await request.json();
      if (request.status === 200) {
        setUser(user._id);

        showAddChildProfileModal();
        if (window.location.pathname.includes("ref")) {
          fetch(
            `${GetBaseURL()}/api/referrals/add?key=${
              process.env.REACT_APP_PERSONAL_AUTH_KEY
            }`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                referral_id: user._id,

                referer_id:
                  window.location.pathname.split("/")[
                    window.location.pathname.split("/").length - 1
                  ],
              }),
            }
          );
        }
        fetch(
          `${process.env.REACT_APP_MAIN_SERVER}/api/users/addUser?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
          {
            method: "POST",
            body: JSON.stringify({
              email: response.email,
              tutorInterest: "Maybe Later",
              name: response.name,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else if (request.status === 500) {
        facebookLogin(response);
      } else {
        setError({
          isActive: true,
          message: user.errorMessage,
          type: "error",
        });
      }
    } else {
      setError({
        isActive: true,
        message: "Facebook authorization error. Please try again.",
        type: "error",
      });
    }
  };

  const handleRegister = async (e: FormEvent) => {
    e.preventDefault();
    if (
      usernameRef.current &&
      passwordRef.current &&
      usernameRef.current.value !== "" &&
      passwordRef.current.value !== ""
    ) {
      if (passwordRef.current.value.length < 6) {
        setError({
          type: "error",
          isActive: true,
          message: "Please enter at least 6 characters for your password.",
        });
      } else {
        if (!ValidateEmail(usernameRef.current.value)) {
          setError({
            type: "error",
            isActive: true,
            message: "Please enter a valid email.",
          });
        } else {
          const request = await fetch(
            `${GetBaseURL()}/api/users/register?key=${
              process.env.REACT_APP_PERSONAL_AUTH_KEY
            }`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                account_type: userRole,
                username: usernameRef.current.value,
                password: NewEncryption.encrypt(passwordRef.current.value),
                device:
                  window.innerWidth <= 600
                    ? "mobile"
                    : window.innerWidth <= 1024
                    ? "tablet"
                    : "desktop",
              }),
            }
          );
          const response = await request.json();
          if (request.status === 200) {
            setUser(response._id);

            if (window.location.pathname.includes("ref")) {
              fetch(
                `${GetBaseURL()}/api/referrals/add?key=${
                  process.env.REACT_APP_PERSONAL_AUTH_KEY
                }`,
                {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    referral_id: response._id,
                    referer_id:
                      window.location.pathname.split("/")[
                        window.location.pathname.split("/").length - 1
                      ],
                  }),
                }
              );
            }
            fetch(
              `${process.env.REACT_APP_MAIN_SERVER}/api/users/addUser?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
              {
                method: "POST",
                body: JSON.stringify({
                  email: usernameRef.current.value,
                  tutorInterest: "Maybe Later",
                  userRole: "Incomplete",
                  password: NewEncryption.encrypt(passwordRef.current!.value),
                }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            hideModal();
          } else if (request.status === 500) {
            setUser(response._id);
            hideModal();
          } else {
            setError({
              isActive: true,
              type: "error",
              message: response.message,
            });
          }
        }
      }
    } else {
      setError({
        isActive: true,
        type: "error",
        message: "Please enter a username AND a password",
      });
    }
  };

  const googleLogin = async (response: any) => {
    if (response.profileObj) {
      const request = await fetch(
        `${GetBaseURL()}/api/users/login?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            username: response.profileObj.email,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (request.status === 200) {
        const foundUser = await request.json();
        setUser(foundUser.user._id);

        hideModal();
        // !displayData.redirectToHome &&
        //   foundUser.user.user_data.linked_accounts.length !== 0 &&
        //   displayData.storyID &&
        //   redirectToStory(true);
      } else {
        setError({
          isActive: true,
          message:
            "You do not have an account. Please use the registration form instead.",
          type: "error",
        });
      }
    } else {
      setError({
        isActive: true,
        message: "Google authorization error. Please try again.",
        type: "error",
      });
    }
  };

  const facebookLogin = async (response: any) => {
    console.log(response);
    if (response.email) {
      const request = await fetch(
        `${GetBaseURL()}/api/users/login?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            username: response.email,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (request.status === 200) {
        const foundUser = await request.json();
        setUser(foundUser.user._id);

        hideModal();
        // !displayData.redirectToHome &&
        //   foundUser.user.user_data.linked_accounts.length !== 0 &&
        //   displayData.storyID &&
        //   redirectToStory(true);
      } else {
        setError({
          isActive: true,
          message:
            "You do not have an account. Please use the registration form instead.",
          type: "error",
        });
      }
    } else {
      setError({
        isActive: true,
        message: "Facebook authorization error. Please try again.",
        type: "error",
      });
    }
  };

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    if (usernameRef.current && passwordRef.current) {
      if (
        usernameRef.current.value === "" ||
        passwordRef.current.value === ""
      ) {
        setError({
          isActive: true,
          type: "error",
          message: "Please enter a username AND a password.",
        });
      } else if (emailLogin && !ValidateEmail(usernameRef.current.value)) {
        setError({
          isActive: true,
          type: "error",
          message: "Please enter a valid email.",
        });
      } else {
        const request = await fetch(
          `${GetBaseURL()}/api/users/login?key=${
            process.env.REACT_APP_PERSONAL_AUTH_KEY
          }`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              username: usernameRef.current.value,
              password: NewEncryption.encrypt(passwordRef.current.value),
            }),
          }
        );
        const response = await request.json();
        if (request.status === 200) {
          // if (response.user.user_data.verified) {
          setUser(response.user._id);

          setError({
            isActive: true,
            type: "success",
            message: response.message,
          });
          hideModal();
          // !displayData.redirectToHome &&
          //   response.user.user_data.linked_accounts.length !== 0 &&
          //   displayData.storyID &&
          //   redirectToStory(true);
          // } else {
          //   setError({
          //     isActive: true,
          //     type: "error",
          //     message:
          //       "You need to verify your email to continue. Please check your email.",
          //   });
          // }
        } else {
          setError({
            isActive: true,
            type: "error",
            message: response.message,
          });
        }
      }
    }
  };

  const handleAddChild = async (e: any) => {
    e.preventDefault();
    if (e.target.name.value !== "" && e.target.grade.value !== 0) {
      let accounts_to_link = [
        userData.account_type === "parent"
          ? userData._id
          : (userData.user_data as StudentData).linked_accounts[0]._id,
      ];
      if (userData.account_type === "parent") {
        for (const linked_account of (userData.user_data as ParentData)
          .linked_accounts) {
          accounts_to_link.push(linked_account._id);
        }
      } else {
        accounts_to_link.push(userData._id);
        for (const linked_account of (userData.user_data as StudentData)
          .linked_accounts) {
          if (linked_account.account_type !== "parent") {
            accounts_to_link.push(linked_account._id);
          }
        }
      }
      const username = `${e.target.name.value
        .trim()
        .split(" ")
        .join("")}${generateRandomNumberString(4)}`.toLowerCase();
      const password = `${storyData.words[
        Math.floor(Math.random() * storyData.words.length)
      ].text.toLowerCase()}${generateRandomNumberString(4)}`;
      const request = await fetch(
        `${GetBaseURL()}/api/users/register?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            accounts_to_link,
            account_type: "student",
            name: e.target.name.value,
            username,
            password: NewEncryption.encrypt(password),
            grade: Number(e.target.grade.value),
            plan_type: userData.subscription_data.plan_type,
          }),
        }
      );
      const response = await request.json();
      if (request.status === 200) {
        const addProfileRequest = await fetch(
          `${GetBaseURL()}/api/users/addProfile?key=${
            process.env.REACT_APP_PERSONAL_AUTH_KEY
          }`,
          {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              ids: accounts_to_link,
              account_to_link: response._id,
            }),
          }
        );
        const addProfileResponse = await addProfileRequest.json();
        if (addProfileRequest.status === 200) {
          setUser(response._id);
          setError({
            isActive: true,
            type: "success",
            message: response.message,
          });
          hideModal();
          // !displayData.redirectToHome &&
          //   displayData.storyID &&
          //   redirectToStory(true);
          displayData.childProfileType === "play"
            ? window.open(
                `${process.env.REACT_APP_SITE_URL}/tictactoe`,
                "_blank"
              )
            : setScrollToStories(true);
        }
      } else {
        setError({ isActive: true, type: "error", message: response.message });
      }
    } else {
      setError({
        isActive: true,
        type: "error",
        message: "Please fill out the required fields.",
      });
    }
  };

  const handleAddClassroom = async (e: any) => {
    e.preventDefault();
    if (e.target.name.value !== "") {
      const request = await fetch(
        `${GetBaseURL()}/api/classrooms/create?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            teacher_id: userData._id,
            name: e.target.name.value,
          }),
          headers: { "Content-Type": "application/json" },
        }
      );
      const response = await request.json();
      if (request.status === 200) {
        setUser(userData._id);
        hideModal();
      } else {
        setError({ type: "error", isActive: true, message: response.message });
      }
    } else {
      setError({
        isActive: true,
        type: "error",
        message: "Please create a name for your class.",
      });
    }
  };

  const handleInviteTeacher = async (e: FormEvent) => {
    e.preventDefault();
    if (
      teacherEmailRef.current &&
      ValidateEmail(teacherEmailRef.current.value)
    ) {
      const successfulEmail = await SendEmail(
        !emailTextRef.current || emailTextRef.current!.value === ""
          ? `Your student, ${userData.user_data.name}, has invited you to teach with No Fuss Readers! Sign up at ${process.env.REACT_APP_SITE_URL} today!`
          : !emailTextRef.current!.value.includes("nofussreaders.com")
          ? `${
              emailTextRef.current!.value
            }\nJoin now at https://nofussreaders.com!`
          : emailTextRef.current!.value,
        teacherEmailRef.current!.value,
        "Invitation to join No Fuss Readers"
      );
      if (successfulEmail) {
        setError({
          type: "success",
          isActive: true,
          message: "Successfully sent invite!",
        });
      } else {
        setError({
          type: "error",
          isActive: true,
          message: "Unable to send email. Please try again.",
        });
      }
    } else {
      setError({
        type: "error",
        isActive: true,
        message: "Please enter a valid email.",
      });
    }
  };

  const resetError = () =>
    setError((prev: any) => ({ ...prev, isActive: false }));

  return (
    <section className="modal">
      {
        <span
          className={
            (register && userRole && !emailLogin) || stripe
              ? "modal__close  modal__close--white"
              : "modal__close"
          }
          onClick={() => {
            addChildProfile &&
            userData.account_type === "parent" &&
            (userData.user_data as ParentData).linked_accounts.length === 0
              ? setError({
                  isActive: true,
                  type: "error",
                  message: "You must add a child to continue.",
                })
              : hideModal();
          }}
        >
          x
        </span>
      }
      {error.isActive && error.type === "error" && (
        <span className="modal__error">{error.message}</span>
      )}
      {error.isActive && error.type === "success" && (
        <span className="modal__success">{error.message}</span>
      )}
      {login && !emailLogin && !studentLogin && (
        <div className="modal__login">
          <div className="modal__prompt">Log in for access to stories</div>
          <div className="modal__social-sign-in-wrapper">
            <div className="modal__facebook-button-fix">
              <FacebookLogin
                appId={`${process.env.REACT_APP_FB_ID}`}
                autoLoad={false}
                fields="name,email,picture"
                scope="email"
                callback={facebookLogin}
                cssClass="modal__facebook-login"
                textButton="Sign in with Facebook"
                icon="fa-facebook"
                disableMobileRedirect={true}
              />
            </div>
            <GoogleLogin
              clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="modal__google-login"
                >
                  <i className="fab fa-google"></i>
                  Sign in with Google
                </button>
              )}
              buttonText="Login"
              onSuccess={googleLogin}
              onFailure={googleLogin}
              cookiePolicy={"single_host_origin"}
            />
            <button
              className="modal__email-button"
              onClick={() => setEmailLogin(true)}
            >
              Sign in with email
            </button>
            <button
              className="modal__email-button"
              onClick={() => setStudentLogin(true)}
            >
              Sign in as a student
            </button>
          </div>
          <div className="modal__instead">
            Don't have an account?{" "}
            <span
              className="modal__instead--link"
              onClick={() => {
                showRegisterModal();
              }}
            >
              Register
            </span>{" "}
            instead!
          </div>
        </div>
      )}
      {login && studentLogin && (
        <form onSubmit={handleLogin} className="modal__form">
          <div className="modal__heading">
            Enter the username and password provided to you by your parent or
            teacher
          </div>
          <span
            className="modal__go-back"
            style={{ cursor: "pointer", color: "#e3522a" }}
            onClick={() => setStudentLogin(false)}
          >
            {"Go back"}
          </span>
          <input
            className="modal__input"
            type="text"
            placeholder="Username"
            ref={usernameRef}
          />
          <input
            className="modal__input"
            type="password"
            placeholder="Password"
            ref={passwordRef}
          />
          <input className="modal__submit" type="submit" value="Login"></input>
          <div className="modal__instead">
            Don't have an account?{" "}
            <span
              className="modal__instead--link"
              onClick={() => {
                showRegisterModal();
              }}
            >
              Register
            </span>{" "}
            instead!
          </div>
        </form>
      )}
      {login && emailLogin && (
        <form onSubmit={handleLogin} className="modal__form">
          {!forgotPassword && (
            <span
              className="modal__go-back"
              style={{ cursor: "pointer", color: "#e3522a" }}
              onClick={() => setEmailLogin(false)}
            >
              {"Go back"}
            </span>
          )}
          {forgotPassword && (
            <div
              className="modal__go-back"
              style={{
                cursor: "pointer",
                color: "#e3522a",
                marginBottom: "10px",
              }}
              onClick={() => {
                setForgotPassword(!forgotPassword);
                resetError();
              }}
            >
              {"Go back"}
            </div>
          )}
          {forgotPassword && (
            <div className="modal__forgot-prompt">
              Enter your email and we'll send you a link to reset your password.
            </div>
          )}
          <input
            className="modal__input"
            type="text"
            placeholder="Email"
            ref={usernameRef}
          />
          {!forgotPassword && (
            <input
              className="modal__input"
              type="password"
              placeholder="Password"
              ref={passwordRef}
              disabled={forgotPassword}
            />
          )}
          {!forgotPassword && (
            <div
              className="modal__password--link"
              onClick={() => setForgotPassword(!forgotPassword)}
            >
              {"Forgot Password?"}
            </div>
          )}

          {forgotPassword ? (
            <input
              className="modal__submit"
              value="Send Email"
              onClick={async () => {
                if (
                  usernameRef.current &&
                  ValidateEmail(usernameRef.current.value)
                ) {
                  const verifyUser = await fetch(
                    `${GetBaseURL()}/api/users/findUser?key=${
                      process.env.REACT_APP_PERSONAL_AUTH_KEY
                    }`,
                    {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        email: usernameRef.current.value,
                      }),
                    }
                  );
                  const response = await verifyUser.json();
                  if (verifyUser.status === 200) {
                    const sendEmailSuccess = await SendHTMLEmail(
                      `<div style="height: 230px;"><div>Tap the button below to reset your password for No Fuss Readers.</div><br /><br /><a style="
                      text-align: center;
                      text-decoration: none;
                      color: white;
                      font-weight: 700;
                      background: #3b96ff;
                      border-radius: 8px;
                      padding: 20px 30px;
                      margin-top: 30px;
                  " href=${process.env.REACT_APP_SITE_URL}/reset/${encrypt(
                        usernameRef.current.value
                      )}>Reset Password</a></div>`,
                      usernameRef.current.value,
                      "No Fuss Readers -- Password Reset Request"
                    );
                    if (sendEmailSuccess) {
                      setError({
                        type: "success",
                        isActive: true,
                        message: "Email sent successfully.",
                      });
                    } else {
                      setError({
                        type: "error",
                        isActive: true,
                        message: "There was an error. Please try again later.",
                      });
                    }
                  } else {
                    setError({
                      type: "error",
                      isActive: true,
                      message: response.message,
                    });
                  }
                } else {
                  setError({
                    type: "error",
                    isActive: true,
                    message: "Please enter a valid email.",
                  });
                }
              }}
            />
          ) : (
            <input
              className="modal__submit"
              type="submit"
              value="Login"
            ></input>
          )}

          {!forgotPassword && (
            <div className="modal__instead">
              Don't have an account?{" "}
              <span
                className="modal__instead--link"
                onClick={() => {
                  showRegisterModal();
                }}
              >
                Register
              </span>{" "}
              instead!
            </div>
          )}
        </form>
      )}
      {register && !userRole && (
        <div className="modal__register">
          <div className="modal__register-title">I am a ...</div>

          <div className="modal__button-wrapper">
            <button
              className="modal__button modal__button--parent"
              onClick={() => setUserRole("parent")}
            >
              {/* Parent */}
            </button>
            <button
              className="modal__button modal__button--educator"
              onClick={() => setUserRole("teacher")}
              // style={{ color: "gray", pointerEvents: "none" }}
            >
              {/* Educator (Coming Soon!) */}
            </button>
          </div>
        </div>
      )}
      {register && userRole && !emailLogin && (
        <div className="modal__register">
          <img
            className="modal__modal-img"
            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/modal-img.png`}
            alt={"No Image"}
          />
          {userRole === "parent" ? (
            <>
              <div className="modal__user-role">
                <span>Have your child reading in record time!</span>
              </div>

              <div className="modal__social-sign-in-wrapper">
                {window.innerWidth > 1024 && (
                  <>
                    <div className="modal__facebook-button-fix">
                      <FacebookLogin
                        appId={`${process.env.REACT_APP_FB_ID}`}
                        autoLoad={false}
                        fields="name,email,picture"
                        scope="email"
                        callback={facebookRegister}
                        cssClass="modal__facebook-login"
                        textButton="Sign up with Facebook"
                        icon="fa-facebook"
                        disableMobileRedirect={true}
                      />
                    </div>
                    <GoogleLogin
                      clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`}
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          className="modal__google-login"
                        >
                          <i className="fab fa-google"></i>
                          Sign up with Google
                        </button>
                      )}
                      buttonText="Login"
                      onSuccess={googleRegister}
                      onFailure={googleRegister}
                      cookiePolicy={"single_host_origin"}
                    />
                  </>
                )}
                <button
                  className="modal__email-button"
                  onClick={() => setEmailLogin(true)}
                >
                  Sign up with email
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="modal__user-role">
                {/* <span>Have your students reading in record time!</span> */}
                <div
                  className="modal__go-back"
                  style={{ cursor: "pointer", color: "#e3522a" }}
                  onClick={() => setUserRole(null)}
                >
                  Go back
                </div>
                <span>
                  Please sign up as an educator through our{" "}
                  <a
                  // style={{ color: "#5854a1" }}
                  // href="https://nofussclassrooms.com"
                  // target="_blank"
                  >
                    teacher's portal
                  </a>{" "}
                  (coming soon)
                </span>
              </div>
              {/* <div className="modal__social-sign-in-wrapper">
                <div className="modal__facebook-button-fix">
                  <FacebookLogin
                    appId={`${process.env.REACT_APP_FB_ID}`}
                    autoLoad={false}
                    fields="name,email,picture"
                    scope="email"
                    callback={facebookRegister}
                    cssClass="modal__facebook-login"
                    textButton="Sign up with Facebook"
                    icon="fa-facebook"
                    disableMobileRedirect={true}
                  />
                </div>
                <GoogleLogin
                  clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`}
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className="modal__google-login"
                    >
                      <i className="fab fa-google"></i>
                      Sign up with Google
                    </button>
                  )}
                  buttonText="Login"
                  onSuccess={googleRegister}
                  onFailure={googleRegister}
                  cookiePolicy={"single_host_origin"}
                />
                <button
                  className="modal__email-button"
                  onClick={() => setEmailLogin(true)}
                >
                  Sign up with email instead
                </button>
              </div> */}
            </>
          )}
          <div className="modal__instead">
            Already have an account?{" "}
            <span
              className="modal__instead--link"
              onClick={() => {
                showLoginModal();
              }}
            >
              Log in
            </span>{" "}
            instead!
          </div>
        </div>
      )}
      {register && userRole && emailLogin && (
        <form onSubmit={handleRegister} className="modal__form">
          <span
            className="modal__go-back"
            style={{ cursor: "pointer", color: "#e3522a" }}
            onClick={() => setEmailLogin(false)}
          >
            {"Go back"}
          </span>
          <input
            className="modal__input"
            type="text"
            placeholder="Email"
            ref={usernameRef}
          />
          <input
            className="modal__input"
            type="password"
            placeholder="Password"
            ref={passwordRef}
          />
          <input
            className="modal__submit"
            type="submit"
            value="Start Reading"
          ></input>
          <div className="modal__instead">
            Already have an account?{" "}
            <span
              className="modal__instead--link"
              onClick={() => {
                showLoginModal();
              }}
            >
              Log in
            </span>{" "}
            instead!
          </div>
        </form>
      )}
      {addChildProfile && (
        <div className="modal__add-child-profile">
          <div className="modal__prompt">Add child</div>
          <form onSubmit={handleAddChild} className="modal__form">
            <input
              className="modal__input"
              type="text"
              placeholder="First Name"
              name="name"
            />
            <select
              className="modal__input"
              defaultValue={0}
              placeholder="Grade"
              name="grade"
            >
              <option value={-1}>Pre-K</option>
              <option value={0}>KG</option>
              <option value={1}>Gr 1</option>
              <option value={2}>Gr 2</option>
              <option value={3}>Gr 3</option>
              <option value={4}>Gr 4</option>
              <option value={5}>Gr 5</option>
              <option value={6}>Gr 6</option>
              <option value={7}>Gr 7</option>
              <option value={8}>Gr 8</option>
            </select>
            <input
              className="modal__submit"
              type="submit"
              value="Submit"
            ></input>
          </form>
        </div>
      )}
      {displayData.addClassroom && (
        <div className="modal__add-classroom">
          <div className="modal__prompt">Add a new classroom</div>
          <form onSubmit={handleAddClassroom} className="modal__form">
            <input
              className="modal__input"
              type="text"
              placeholder="Class Name"
              name="name"
            />
            <input
              className="modal__submit"
              type="submit"
              value="Submit"
            ></input>
          </form>
        </div>
      )}
      {inviteTeacher && (
        <div className="modal__invite-teacher">
          <div className="modal__prompt">
            Enter your teacher's email to invite them!
          </div>
          <form onSubmit={handleInviteTeacher} className="modal__form">
            <input
              className="modal__input"
              type="email"
              placeholder="Teacher's Email"
              name="name"
              required
              ref={teacherEmailRef}
            />
            <textarea
              className="modal__input"
              placeholder={`A student, ${userData.user_data.name}, has invited you to teach with No Fuss Readers! Sign up at ${process.env.REACT_APP_SITE_URL} today!`}
              defaultValue={`A student, ${userData.user_data.name}, has invited you to teach with No Fuss Readers! Sign up at ${process.env.REACT_APP_SITE_URL} today!`}
              ref={emailTextRef}
            />
            <input
              className="modal__submit"
              type="submit"
              value="Send Invite"
            ></input>
          </form>
        </div>
      )}
      {signIntoClass && (
        <div className="modal__sign-into-class">
          <div className="modal__prompt">
            Enter your classroom code (ask your teacher for the code):
          </div>
          <input
            className="modal__input"
            onChange={(e) => setClassroomCode(e.target.value)}
          />
          <div
            className="modal__submit"
            onClick={async () => {
              const request = await fetch(
                `${GetBaseURL()}/api/classrooms/addStudent?key=${
                  process.env.REACT_APP_PERSONAL_AUTH_KEY
                }`,
                {
                  method: "POST",
                  body: JSON.stringify({
                    classroom_id: classroomCode,
                    student_id: userData._id,
                  }),
                  headers: { "Content-Type": "application/json" },
                }
              );
              const response = await request.json();
              if (request.status === 200) {
                setUser(response.user._id);
              } else {
                setError({
                  isActive: true,
                  type: "error",
                  message: response.message,
                });
              }
            }}
          >
            Submit
          </div>
        </div>
      )}
      {stripe && <StripeModal fromBanner={fromBanner} fromLimit={fromLimit} />}
      {manageSubscription && <ManageSubscription />}
      {displayData.childProfiles && (
        <div className="modal__child-profiles">
          <div className="modal__prompt">
            Who's {displayData.childProfileType}ing?
          </div>
          {(userData.account_type === "parent"
            ? (userData.user_data as ParentData)
            : (userData.user_data as StudentData)
          ).linked_accounts.map((linked_account) => {
            return (
              <React.Fragment key={generateID()}>
                {displayData.childProfileType === "read" ? (
                  <span
                    className="modal__option-child"
                    onClick={() => {
                      setUser(linked_account._id);
                      hideModal();
                    }}
                  >
                    {linked_account.user_data.name}
                  </span>
                ) : (
                  <span
                    className="modal__option-child"
                    onClick={() => {
                      setUser(linked_account._id);
                      hideModal();
                      window.open(
                        `${process.env.REACT_APP_SITE_URL}/tictactoe`,
                        "_blank"
                      );
                    }}
                  >
                    {linked_account.user_data.name}
                  </span>
                )}
              </React.Fragment>
            );
          })}
          {/* {(userData.user_data as ParentData).linked_accounts.length < 4 && ( */}
          <span
            className="modal__option-child"
            onClick={() => showAddChildProfileModal()}
          >
            Add Child
          </span>
          {/* )} */}
        </div>
      )}
      {nextStory && (
        <div className="modal__next-story">
          <div>Your results:</div>
          <div>
            <span>
              Question 1:{" "}
              <span>
                {displayData.studentAnswers!.correct1 ? "Correct" : "Incorrect"}
              </span>
            </span>
            <span>
              Question 2:{" "}
              <span>
                {displayData.studentAnswers!.correct2 ? "Correct" : "Incorrect"}
              </span>
            </span>
            <span>
              Question 3:{" "}
              <span>
                {displayData.studentAnswers!.correct3 ? "Correct" : "Incorrect"}
              </span>
            </span>
          </div>
          <div>Score: {displayData.score}</div>
          <div className="modal__prompt">
            {displayData.score === "2/3" || displayData.score === "3/3"
              ? "Congratulations! Want to read another story?"
              : "Try practicing this story more before moving to another one."}
          </div>
          <StoryCard
            id={displayData.randomStory!._id}
            title={displayData.randomStory!.title}
            level={displayData.randomStory!.level}
            tags={displayData.randomStory!.tags}
            cover={displayData.randomStory!.cover}
            wordCount={
              Array.from(
                new Set(
                  displayData
                    .randomStory!.pages.map((page) => {
                      return page.page_text
                        .replace(/\r/g, "")
                        .split(/\n/)
                        .join(";;")
                        .split(";;")
                        .join(" ")
                        .split(" ");
                    })
                    .flat()
                )
              ).length
            }
            priority_tag={displayData.randomStory!.priority_tag}
          ></StoryCard>

          <Link to="/">
            <div onClick={() => hideModal()}>Return to Home Page</div>
          </Link>
        </div>
      )}
      {trackedWords && (
        <div className="modal__tracked-words">
          <div className="modal__prompt">
            <span
              className="user-details--child"
              style={{ marginRight: "unset", cursor: "pointer" }}
              onClick={() => {
                setUser(displayData.child_id!);
                hideModal();
              }}
            >
              {`${childData?.name}${
                childData!.perm_tracked_words.length > 0 ? "'s" : ""
              }`}
            </span>
            {childData!.perm_tracked_words.length > 0
              ? ` Tracked Words`
              : ` has no tracked words. Click some words to start tracking!`}
          </div>
          <div className="modal__tracked-words--tracking-container">
            {childData?.perm_tracked_words.map((wordProperties) => {
              const timesClicked =
                childData.perm_tracked_words[
                  childData.perm_tracked_words.findIndex(
                    (val) => val.word === wordProperties.word
                  )
                ].times_clicked;
              return (
                <React.Fragment key={generateID()}>
                  {timesClicked && timesClicked > 0 && (
                    <div className="modal__tracked-words--tracking-section">
                      <span className="modal__tracked-words--word">
                        {wordProperties.word}
                      </span>

                      <span className="modal__tracked-words__tracking-section--times-clicked">
                        {timesClicked} times clicked
                      </span>

                      <div className="modal__tracked-words__tracking-section--phonic-word-container">
                        {wordProperties.phonics.map((phonic, phonicIndex) => {
                          let currentPhonics: Phonics[] = [];
                          for (const phoneme of phonic.phonemes) {
                            const foundPhonic = storyData.phonics.find(
                              (fullPhonic) => fullPhonic.phoneme === phoneme
                            );
                            if (foundPhonic) {
                              currentPhonics.push(foundPhonic);
                            }
                          }

                          return (
                            <React.Fragment key={generateID()}>
                              <div className="modal__tracked-words__tracking-section--phonic-part-container">
                                <span
                                  className={`${
                                    childData.perm_tracked_words[
                                      childData.perm_tracked_words.findIndex(
                                        (val) =>
                                          val.word === wordProperties.word
                                      )
                                    ].positions.includes(phonic.position)
                                      ? phonic.position === 1
                                        ? "modal__tracked-words__phonic-part--first "
                                        : phonic.position ===
                                          wordProperties.phonics.length
                                        ? "modal__tracked-words__phonic-part--last "
                                        : phonic.position === 2
                                        ? "modal__tracked-words__phonic-part--second "
                                        : phonic.position ===
                                          wordProperties.phonics.length - 1
                                        ? "modal__tracked-words__phonic-part--penultimate "
                                        : "modal__tracked-words__phonic-part--middle "
                                      : ""
                                  }modal__tracked-words__tracking-section--phonic-part`}
                                >
                                  {`${phonic.part}`}
                                </span>
                                <span
                                  className={`${
                                    childData.perm_tracked_words[
                                      childData.perm_tracked_words.findIndex(
                                        (val) =>
                                          val.word === wordProperties.word
                                      )
                                    ].positions.includes(phonic.position)
                                      ? phonic.position === 1
                                        ? "modal__tracked-words__phonic-click--first "
                                        : phonic.position ===
                                          wordProperties.phonics.length
                                        ? "modal__tracked-words__phonic-click--last "
                                        : phonic.position === 2
                                        ? "modal__tracked-words__phonic-click--second "
                                        : phonic.position ===
                                          wordProperties.phonics.length - 1
                                        ? "modal__tracked-words__phonic-click--penultimate "
                                        : "modal__tracked-words__phonic-click--middle "
                                      : ""
                                  }modal__tracked-words__tracking-section--phonic-click`}
                                ></span>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
      {/* display date read and green underline styles on name */}
      {displayData.storiesRead && (
        <div className="modal__stories-read">
          <div className="modal__prompt">
            <span
              className="user-details--child"
              style={{ marginRight: "unset", cursor: "pointer" }}
              onClick={() => {
                setUser(displayData.child_id!);
                hideModal();
              }}
            >
              {`${childData?.name}${storiesRead.length > 0 ? "'s" : ""}`}
            </span>
            {storiesRead.length > 0
              ? " Completed Stories"
              : ` has not completed any stories. Press "Finish" at the end of a story for it to count.`}
          </div>
          {storiesRead.map((story) => (
            <React.Fragment key={generateID()}>
              <div className="modal__stories-read--line">
                <span className="modal__stories-read--story">
                  <span
                    style={{ pointerEvents: "none" }}
                    className={`level-${story.level} user-details--level`}
                  >
                    {story.level}
                  </span>{" "}
                  {story.title} (Score:{" "}
                  {childData!.stories_read.find(
                    (childStoryRead) => childStoryRead._id === story._id
                  )!.scores !== undefined
                    ? childData!.stories_read.find(
                        (childStoryRead) => childStoryRead._id === story._id
                      )!.scores[
                        childData!.stories_read.find(
                          (childStoryRead) => childStoryRead._id === story._id
                        )!.scores.length - 1
                      ]
                    : "None"}
                  )
                </span>
                <span className="modal__stories-read--date">
                  Read{" "}
                  {new Date(
                    childData!.stories_read.find(
                      (childStoryRead) => childStoryRead._id === story._id
                    )!.date_read
                  ).toLocaleString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </span>
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      {displayData.recentlyLearned && (
        <div className="modal__recently-learned">
          <div className="modal__prompt">{`${
            displayData.childData?.name
          } has learned ${
            displayData.childData!.recently_learned.length
          } words in the past two weeks!`}</div>
          {displayData
            .childData!.recently_learned.filter(
              (wordData) =>
                Math.round(
                  (new Date(Date.now()).getTime() -
                    new Date(wordData.date_learned).getTime()) /
                    (1000 * 60 * 60 * 24)
                ) <= 14
            )
            .reverse()
            .map((wordData) => (
              <React.Fragment key={generateID()}>
                <div className="modal__recently-learned--word-wrapper">
                  <span className="modal__recently-learned--word">
                    {wordData.word}
                  </span>
                  <span className="modal__recently-learned--date">
                    {Math.round(
                      (new Date(Date.now()).getTime() -
                        new Date(wordData.date_learned).getTime()) /
                        (1000 * 60 * 60 * 24)
                    ) > 0
                      ? `${Math.round(
                          (new Date(Date.now()).getTime() -
                            new Date(wordData.date_learned).getTime()) /
                            (1000 * 60 * 60 * 24)
                        )} days ago`
                      : " learned today"}
                  </span>
                  <span
                    className="modal__recently-learned--unlearn"
                    onClick={() => {
                      const newUserData = userData;
                      (newUserData.user_data as StudentData).recently_learned =
                        (
                          newUserData.user_data as StudentData
                        ).recently_learned.filter(
                          (studentWordData) =>
                            wordData.word !== studentWordData.word
                        );
                      setUserData(newUserData);
                      addWordAsRecentlyLearned(wordData.word, true);
                      updateTrackedWords([
                        ...(userData.user_data as StudentData)
                          .perm_tracked_words,
                        {
                          word: wordData.word,
                          definitions: [""],
                          phonics: storyData.words.find(
                            (word) => word.text === wordData.word
                          )!.phonics,
                          positions: [],
                          displayPhonics: [],
                          times_clicked: 1,
                        },
                      ]);
                    }}
                  >
                    Unlearn
                  </span>
                </div>
              </React.Fragment>
            ))}
        </div>
      )}
      {displayData.changeReadingLevel && (
        <div className="modal__change-reading-level">
          <div className="modal__change-reading-level--prompt">{`Select a new reading level for ${displayData.childData?.name}`}</div>
          <div
            className="modal__change-reading-level--how-to-test"
            onClick={() => setShowReadingLevelAnswer(!showReadingLevelAnswer)}
          >
            How do I test what reading level my child is at?
          </div>
          {showReadingLevelAnswer && (
            <div className="modal__change-reading-level--answer">
              <div className="modal__change-reading-level--answer-step">
                Step 1) Pick a level you think your child is at.
              </div>
              <div className="modal__change-reading-level--answer-step">
                Step 2) Ask your child to read the story, do not help them with
                any of it.
              </div>
              <div className="modal__change-reading-level--answer-step">
                Step 3) If they miss more than 1 out of every 10 words, the
                story is too hard and you should try an easier level.
              </div>
              <div className="modal__change-reading-level--answer-step">
                Step 4) Keep trying easier levels until your child is at a level
                where they can read 90% of the words on their own. That is their
                current reading level.
              </div>
            </div>
          )}
          <div className="modal__change-reading-level--levels-container">
            {ReadingLevels.map((letter, index) => (
              <React.Fragment key={generateID()}>
                <div
                  className={`modal__change-reading-level--level-row ${
                    letter === displayData.childData?.reading_level
                      ? "modal__change-reading-level--selected"
                      : ""
                  }`}
                  onClick={() => {
                    updateReadingLevel(displayData.child_id!, letter);
                    hideModal();
                  }}
                >
                  <div className="modal__change-reading-level--levels-label">
                    {index < 2
                      ? "Kindergarten"
                      : index === 2
                      ? "Kindergarten/Grade 1"
                      : index < 9
                      ? "Grade 1"
                      : index < 13
                      ? "Grade 2"
                      : index < 16
                      ? "Grade 3"
                      : index < 19
                      ? "Grade 4"
                      : index < 22
                      ? "Grade 5"
                      : index < 25
                      ? "Grade 6"
                      : "Grades 7-8"}
                  </div>
                  <span
                    className={`level-${letter} modal__change-reading-level--level `}
                  >
                    {letter}
                  </span>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
      {displayData.changeGrade && (
        <div className="modal__change-reading-level">
          <div className="modal__change-reading-level--prompt">{`Set ${displayData.childData?.name}'s grade`}</div>
          <div className="modal__change-reading-level--levels-container">
            {Grades.map((grade, index) => (
              <React.Fragment key={generateID()}>
                <div
                  style={
                    displayData.childData?.grade ===
                    ConvertGradeToNumberOrString(grade)
                      ? { backgroundColor: "#e5f6e8" }
                      : undefined
                  }
                  className="modal__change-reading-level--level-row"
                  onClick={() => {
                    updateGrade(
                      displayData.child_id!,
                      ConvertGradeToNumberOrString(grade)
                    );
                    hideModal();
                  }}
                >
                  <span
                    className="modal__change-reading-level--level"
                    style={{ color: "black", width: "100%" }}
                  >
                    {grade}
                  </span>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
      {displayData.linkAccount && (
        <div className="modal__link-account">
          <div className="modal__prompt">
            Enter the code provided by your parent to link your account to
            theirs.
          </div>
          <input
            className="modal__input"
            ref={linkAccountRef}
            type="text"
            placeholder="Code"
          />
          <div
            className="modal__submit"
            onClick={async () => {
              if (
                linkAccountRef.current &&
                linkAccountRef.current.value.trim() !== ""
              ) {
                const request = await fetch(
                  `${GetBaseURL()}/api/users/linkAccounts`,
                  {
                    method: "PATCH",
                    body: JSON.stringify({
                      child_id: displayData.child_id,
                      parent_id: linkAccountRef.current?.value,
                    }),
                    headers: { "Content-Type": "application/json" },
                  }
                );
                const response = await request.json();
                if (request.status === 200) {
                  setUser(displayData.child_id!);
                  hideModal();
                } else {
                  setError({
                    type: "error",
                    isActive: true,
                    message: response.message,
                  });
                }
              } else {
                setError({
                  type: "error",
                  isActive: true,
                  message: "Please enter a code.",
                });
              }
            }}
          >
            Link Account
          </div>
        </div>
      )}
      {displayData.deleteProfile && (
        <div className="modal__delete-profile">
          <div className="modal__prompt">
            Are you sure you want to remove {displayData.childData?.name}'s
            profile?
          </div>
          <div className="modal__text">
            This will delete all of their information including tracked words
            and linked accounts.
          </div>
          <div className="modal__text">
            This action CANNOT be undone. Are you sure you wish to proceed?
          </div>
          <div
            className="modal__submit"
            onClick={async () => {
              const request = await fetch(
                `${GetBaseURL()}/api/users/deleteProfile?key=${
                  process.env.REACT_APP_PERSONAL_AUTH_KEY
                }`,
                {
                  method: "PATCH",
                  body: JSON.stringify({
                    child_id: displayData.child_id,
                    child_data: displayData.childData,
                  }),
                  headers: { "Content-Type": "application/json" },
                }
              );
              const response = await request.json();
              if (request.status === 200) {
                setUser(userData._id);
                hideModal();
              } else {
                setError({
                  type: "error",
                  isActive: true,
                  message:
                    "There was an error deleting the profile. Please try again later.",
                });
                console.log(response.message);
              }
            }}
          >
            Yes, delete it
          </div>
        </div>
      )}
      {displayData.previewStory && (
        <div className="modal__preview-story">
          {!displayData.fromLimit && !displayData.fromBanner && (
            <>
              <div className="modal__prompt">
                <span className="modal__preview-story--blue-text">
                  You get 1 free story per day.
                </span>{" "}
                <br></br>{" "}
                <span className="modal__preview-story--text-span">
                  Are you sure you want to use it on:
                </span>
                <br></br>
                <span className="modal__preview-story--blue-text-small">
                  {displayData.story_details!.title}{" "}
                </span>
                ?
              </div>
              <div className="modal__preview-story--buttons">
                <div
                  className="modal__preview-story--no"
                  onClick={() => hideModal()}
                >
                  Nope.
                </div>
                <Link to={`/story/${displayData.story_details!._id}`}>
                  <div
                    className="modal__preview-story--confirm"
                    onClick={() => {
                      updateSubscriptionData(displayData.story_details!.title);
                      setHomePageScrollHeight(window.scrollY);
                      setInitialReload(true);
                      hideModal();
                    }}
                  >
                    Yes!
                  </div>
                </Link>
              </div>{" "}
            </>
          )}
          {displayData.fromLimit && (
            <>
              <div className="modal__preview-story--free-used">
                You have used your 1 free story for today.
              </div>
              <div
                className="modal__preview-story--upgrade"
                onClick={() => {
                  showStripeModal({
                    story_details: displayData.story_details,
                    fromBanner: false,
                    fromLimit: true,
                  });
                  fetch(
                    `${GetBaseURL()}/api/users/trackProductView?key=${
                      process.env.REACT_APP_PERSONAL_AUTH_KEY
                    }`,
                    {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        email: userData.user_data.email,
                        content_name: "Clicked unlock after reaching limit",
                        event_name: "ViewContent",
                      }),
                    }
                  );
                }}
              >
                Unlock unlimited stories
              </div>
            </>
          )}
          <div style={{ textAlign: "center", marginBottom: "5px" }}>
            Preview of Page 1:
          </div>
          <div
            className="modal__preview-story--page"
            style={{
              width: "75%",
              margin: "auto",
              marginTop: "10px",
              border: "1px solid #d3d3d3",
              borderRadius: "7px",
              padding: "20px 20px",
            }}
          >
            <StoryPreview
              image={
                displayData.story_details!.pages.sort(
                  (first, second) => first.page_number - second.page_number
                )[0].page_image
              }
              pageNumber={1}
              lines={displayData.story_details!.pages[0].lines}
              onClick={() => {}}
              mobileFriendly={displayData.story_details!.mobileFriendly}
            />
          </div>
        </div>
      )}

      {displayData.games && (
        <div>
          {userData.account_type === "parent" && (
            <div className="modal__row-wrapper">
              <button
                className="modal__go-to-ttt"
                onClick={() => showChildProfilesModal("play")}
              >
                <div className="reading-portal__game-button-img-holder">
                  <img
                    src="https://study-portal.s3.us-east-2.amazonaws.com/tic-tac-toe.svg"
                    alt="Book Icon"
                    className="reading-portal__reading-button-img"
                  />
                </div>
                <div className="modal__game-button-text">Tic Tac Toe</div>
              </button>

              <button
                className="modal__go-to-memory-game"
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_SITE_URL}/memorygame`,
                    "_blank"
                  )
                }
              >
                <div className="reading-portal__game-button-img-holder">
                  <img
                    src="https://study-portal.s3.us-east-2.amazonaws.com/Wisdom.svg"
                    alt="Book Icon"
                    className="reading-portal__reading-button-img"
                  />
                </div>
                <div className="modal__game-button-text">Memory Game</div>
              </button>
            </div>
          )}
          {userData.account_type === "student" && (
            <div>
              <div className="modal__row-wrapper">
                <Link to="/tictactoe" target="_blank">
                  <button className="modal__go-to-ttt">
                    <div className="reading-portal__game-button-img-holder">
                      <img
                        src="https://study-portal.s3.us-east-2.amazonaws.com/tic-tac-toe.svg"
                        alt="Book Icon"
                        className="reading-portal__reading-button-img"
                      />
                    </div>
                    <div className="modal__game-button-text">Tic Tac Toe</div>
                  </button>
                </Link>
                <Link to="/tictactoe" target="_blank">
                  <button
                    className="modal__go-to-memory-game"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_SITE_URL}/memorygame`,
                        "_blank"
                      )
                    }
                  >
                    <div className="reading-portal__game-button-img-holder">
                      <img
                        src="https://study-portal.s3.us-east-2.amazonaws.com/Wisdom.svg"
                        alt="Book Icon"
                        className="reading-portal__reading-button-img"
                      />
                    </div>
                    <div className="modal__game-button-text">Memory Game</div>
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}

      {displayData.comingSoon && (
        <div>
          <div className="modal__moonrock-banner-holder">
            <img
              src="https://study-portal.s3.us-east-2.amazonaws.com/moonrock_banner.jpg"
              alt="Book Icon"
              className="modal__moonrock-banner-img"
            />
          </div>
          <div className="modal__moonrock-text">
            Grade 1-8 Math and English Game Launching June 2022!
          </div>
        </div>
      )}

      {displayData.share && (
        <div className="modal__share">
          {/* <div className="modal__share--text">{`No Fuss Readers | ${displayData.title}`}</div> */}
          <div className="modal__share--text">{`Share the book "${displayData.title}" with friends. If they sign up for a paid account, you earn $10 Cash!`}</div>

          <div
            className={
              isAnimationActive
                ? "modal__share--input-clone modal__share--input-clone--animate"
                : "modal__share--input-clone"
            }
            onClick={() => {
              animate();
              navigator.clipboard.writeText(
                `No Fuss Readers | ${displayData.title}\n\nWe love the book ${
                  displayData.title
                }, highly recommend!\n\n${process.env.REACT_APP_SITE_URL}/ref/${
                  userData.account_type === "parent"
                    ? userData._id
                    : (userData.user_data as StudentData).linked_accounts[0]._id
                }`
              );
            }}
          >
            <div
              className={
                isAnimationActive
                  ? "modal__share--link modal__share--link--animate"
                  : "modal__share--link"
              }
            >{`${process.env.REACT_APP_SITE_URL}/ref/${
              userData.account_type === "parent"
                ? userData._id
                : (userData.user_data as StudentData).linked_accounts[0]._id
            }`}</div>
          </div>
          <div
            className={
              isAnimationActive
                ? "modal__share--copy modal__share--copy--animate"
                : "modal__share--copy"
            }
            onClick={() => {
              animate();
              navigator.clipboard.writeText(
                `No Fuss Readers | ${displayData.title}\n\nWe love the book ${
                  displayData.title
                }, highly recommend!\n\n${process.env.REACT_APP_SITE_URL}/ref/${
                  userData.account_type === "parent"
                    ? userData._id
                    : (userData.user_data as StudentData).linked_accounts[0]._id
                }`
              );
            }}
          >
            {isAnimationActive ? "Copied!" : "Copy"}
            <i className="far fa-copy"></i>
          </div>
        </div>
      )}
    </section>
  );
};

export default Modal;
