import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useUserData } from "../Hooks/useUserData";
import { GetBaseURL } from "../Utils";

const SuccessfulSubscription: React.FC = () => {
  const { userData } = useUserData();

  return localStorage.getItem("id") || (userData._id && userData._id !== "") ? (
    <section className="successful-subscription">
      <div className="successful-subscription__container">
        <div className="successful-subscription__heading">
          Successfully Subscribed to Premium!
        </div>
        <div className="successful-subscription__subheading">
          Let's skyrocket your child's reading together!
        </div>
        <Link to="/" className="successful-subscription__button">
          Start Reading
        </Link>
      </div>
    </section>
  ) : (
    <Redirect to="/" />
  );
};

export default SuccessfulSubscription;
