export const ReadingLevels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

export const AverageReadingLevel = (
  grade: number,
  segment: 0 | 1 | 2 | 3 | 4 | 5
) =>
  grade === 0
    ? segment === 0
      ? 0
      : segment === 1
      ? 1
      : segment === 2
      ? 2
      : 3
    : grade === 1
    ? segment === 0
      ? 4
      : segment === 1
      ? 5
      : segment === 2
      ? 6
      : segment === 3
      ? 7
      : segment === 4
      ? 8
      : 9
    : grade === 2
    ? segment === 0
      ? 10
      : segment === 1
      ? 11
      : 12
    : grade === 3
    ? segment === 0
      ? 13
      : segment === 1
      ? 14
      : 15
    : grade === 4
    ? segment === 0
      ? 16
      : segment === 1
      ? 17
      : 18
    : grade === 5
    ? segment === 0
      ? 19
      : segment === 1
      ? 20
      : 21
    : grade === 6
    ? segment === 0
      ? 22
      : segment === 1
      ? 23
      : 24
    : 25;

export const AverageGrade = (readingLevel: string) => {
  switch (readingLevel) {
    case "A":
      return 0;
    case "B":
      return 0.25;
    case "C":
      return 0.5;
    case "D":
      return 0.75;
    case "E":
      return 1;
    case "F":
      return 1 + 1 / 6;
    case "G":
      return 1 + 1 / 3;
    case "H":
      return 1 + 1 / 2;
    case "I":
      return 1 + 2 / 3;
    case "J":
      return 1 + 5 / 6;
    case "K":
      return 2;
    case "L":
      return 2 + 1 / 3;
    case "M":
      return 2 + 2 / 3;
    case "N":
      return 3;
    case "O":
      return 3 + 1 / 3;
    case "P":
      return 3 + 2 / 3;
    case "Q":
      return 4;
    case "R":
      return 4 + 1 / 3;
    case "S":
      return 4 + 2 / 3;
    case "T":
      return 5;
    case "U":
      return 5 + 1 / 3;
    case "V":
      return 5 + 2 / 3;
    case "W":
      return 6;
    case "X":
      return 6 + 1 / 3;
    case "Y":
      return 6 + 2 / 3;
    case "Z":
      return 7;
  }
};
