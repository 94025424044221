import React from "react";

const Lessons: React.FC = ({}) => {
  return (
    <div className="user-details__lessons">
      <div className="user-details__lessons--scroll">
        Scroll
        <i
          className="fa fa-arrow-right user-details__lessons--arrow"
          aria-hidden="true"
        ></i>
      </div>
      <div className="user-details__lessons--title--row">
        <div className="user-details__lessons--title">Reading Lessons (10)</div>

        <a
          href="https://nofusstutors.com/full-lessons-worksheets"
          target="_blank"
        >
          <div className="user-details__lessons--title">
            <i className="fa fa-external-link" aria-hidden="true"></i>
          </div>
        </a>
      </div>

      <div className="user-details__lessons--lesson-container">
        <a
          href="https://study-portal.s3.us-east-2.amazonaws.com/PhonemicAwareness.pdf"
          target="_blank"
        >
          <div className="user-details__lessons--lesson--card">
            <div className="user-details__lessons--lesson--title">
              Phonemic Awareness
            </div>
            <div className="user-details__lessons--lesson--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/PhonemicAwareness.png"
                alt="Book Icon"
                className="user-details__lessons--lesson--img"
              />
            </div>

            <div className="user-details__lessons--lesson--bottom">
              <div className="user-details__lessons--lesson--bottom-level">
                Lesson 1
              </div>
              <div className="user-details__lessons--lesson--bottom-pages">
                13 pgs
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://study-portal.s3.us-east-2.amazonaws.com/Short+Vowels.pdf"
          target="_blank"
        >
          <div className="user-details__lessons--lesson--card">
            <div className="user-details__lessons--lesson--title">
              Short Vowel cVc Words
            </div>
            <div className="user-details__lessons--lesson--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/CVC_Words.png"
                alt="Book Icon"
                className="user-details__lessons--lesson--img"
              />
            </div>

            <div className="user-details__lessons--lesson--bottom">
              <div className="user-details__lessons--lesson--bottom-level">
                Lesson 2
              </div>
              <div className="user-details__lessons--lesson--bottom-pages">
                18 pgs
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://study-portal.s3.us-east-2.amazonaws.com/Magic+E+(1).pdf"
          target="_blank"
        >
          <div className="user-details__lessons--lesson--card">
            <div className="user-details__lessons--lesson--title">Magic e</div>
            <div className="user-details__lessons--lesson--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/magic-e.png"
                alt="Book Icon"
                className="user-details__lessons--lesson--img"
              />
            </div>

            <div className="user-details__lessons--lesson--bottom">
              <div className="user-details__lessons--lesson--bottom-level">
                Lesson 3
              </div>
              <div className="user-details__lessons--lesson--bottom-pages">
                10 pgs
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://nofusstutors.com/full-lessons-worksheets"
          target="_blank"
        >
          <div className="user-details__lessons--lesson--card">
            <div className="user-details__lessons--lesson--title">
              S and FLSZ Rule
            </div>
            <div className="user-details__lessons--lesson--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/srule.png"
                alt="Book Icon"
                className="user-details__lessons--lesson--img"
              />
            </div>

            <div className="user-details__lessons--lesson--bottom">
              <div className="user-details__lessons--lesson--bottom-level">
                Lesson 4
              </div>
              <div className="user-details__lessons--lesson--bottom-pages">
                8 pgs
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://nofusstutors.com/full-lessons-worksheets"
          target="_blank"
        >
          <div className="user-details__lessons--lesson--card">
            <div className="user-details__lessons--lesson--title">
              Consonant Blends
            </div>
            <div className="user-details__lessons--lesson--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/ConsonantBlends.png"
                alt="Book Icon"
                className="user-details__lessons--lesson--img"
              />
            </div>

            <div className="user-details__lessons--lesson--bottom">
              <div className="user-details__lessons--lesson--bottom-level">
                Lesson 5
              </div>
              <div className="user-details__lessons--lesson--bottom-pages">
                12 pgs
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://nofusstutors.com/full-lessons-worksheets"
          target="_blank"
        >
          <div className="user-details__lessons--lesson--card">
            <div className="user-details__lessons--lesson--title">Digraphs</div>
            <div className="user-details__lessons--lesson--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/squid17.png"
                alt="Book Icon"
                className="user-details__lessons--lesson--img"
              />
            </div>

            <div className="user-details__lessons--lesson--bottom">
              <div className="user-details__lessons--lesson--bottom-level">
                Lesson 6
              </div>
              <div className="user-details__lessons--lesson--bottom-pages">
                11 pgs
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://nofusstutors.com/full-lessons-worksheets"
          target="_blank"
        >
          <div className="user-details__lessons--lesson--card">
            <div className="user-details__lessons--lesson--title">
              Syllables
            </div>
            <div className="user-details__lessons--lesson--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/syllables-lion.png"
                alt="Book Icon"
                className="user-details__lessons--lesson--img"
              />
            </div>

            <div className="user-details__lessons--lesson--bottom">
              <div className="user-details__lessons--lesson--bottom-level">
                Lesson 7
              </div>
              <div className="user-details__lessons--lesson--bottom-pages">
                14 pgs
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://nofusstutors.com/full-lessons-worksheets"
          target="_blank"
        >
          <div className="user-details__lessons--lesson--card">
            <div className="user-details__lessons--lesson--title">
              Predictable Vowel Teams
            </div>
            <div className="user-details__lessons--lesson--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/predictableVowelTeams.png"
                alt="Book Icon"
                className="user-details__lessons--lesson--img"
              />
            </div>

            <div className="user-details__lessons--lesson--bottom">
              <div className="user-details__lessons--lesson--bottom-level">
                Lesson 8
              </div>
              <div className="user-details__lessons--lesson--bottom-pages">
                9 pgs
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://nofusstutors.com/full-lessons-worksheets"
          target="_blank"
        >
          <div className="user-details__lessons--lesson--card">
            <div className="user-details__lessons--lesson--title">
              Unpredictable Vowel Teams (Set 1)
            </div>
            <div className="user-details__lessons--lesson--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/unpredictableSet1.png"
                alt="Book Icon"
                className="user-details__lessons--lesson--img"
              />
            </div>

            <div className="user-details__lessons--lesson--bottom">
              <div className="user-details__lessons--lesson--bottom-level">
                Lesson 9
              </div>
              <div className="user-details__lessons--lesson--bottom-pages">
                13 pgs
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://nofusstutors.com/full-lessons-worksheets"
          target="_blank"
        >
          <div className="user-details__lessons--lesson--card">
            <div className="user-details__lessons--lesson--title">
              Unpredictable Vowel Teams (Set 2)
            </div>
            <div className="user-details__lessons--lesson--img-holder">
              <img
                src="https://study-portal.s3.us-east-2.amazonaws.com/unpredictableSet2.png"
                alt="Book Icon"
                className="user-details__lessons--lesson--img"
              />
            </div>

            <div className="user-details__lessons--lesson--bottom">
              <div className="user-details__lessons--lesson--bottom-level">
                Lesson 10
              </div>
              <div className="user-details__lessons--lesson--bottom-pages">
                12 pgs
              </div>
            </div>
          </div>
        </a>
        <div className="user-details__lessons--lesson--card">
          <div className="user-details__lessons--lesson--title">
            More Lessons Coming Soon!
          </div>
          <div className="user-details__lessons--lesson--img-holder">
            <img
              src="https://study-portal.s3.us-east-2.amazonaws.com/unicorn-img.png"
              alt="Book Icon"
              className="user-details__lessons--lesson--img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lessons;
