import React, { useState, useEffect } from "react";

type Card = { word: string; matched: boolean; id: number };

interface MemoryGridProps {
  customWords?: string[];
  setCustom: React.Dispatch<React.SetStateAction<boolean | null>>;
}

const MemoryGrid: React.FC<MemoryGridProps> = ({ customWords, setCustom }) => {
  //passed object instead of array in cards
  const [cardText, setCardText] = useState<
    { word: string; matched: boolean }[]
  >([]);
  const [cards, setCards] = useState<Card[]>([]);
  const [turns, setTurns] = useState(0);
  const [theme, setTheme] = useState("Space");
  const [choiceOne, setChoiceOne] = useState<Card | null>(null);
  const [choiceTwo, setChoiceTwo] = useState<Card | null>(null);
  const [disabled, setDisabled] = useState(false);

  const spaceTheme =
    "https://study-portal.s3.us-east-2.amazonaws.com/space-theme-1.png";

  const oceanTheme =
    "https://study-portal.s3.us-east-2.amazonaws.com/ocean-theme-1.jpg";
  const dinoTheme =
    "https://study-portal.s3.us-east-2.amazonaws.com/Bronto.png";

  useEffect(() => {
    if (customWords) {
      setCardText(
        customWords.map((word) => {
          return { word, matched: false };
        })
      );
    } else {
      setCardText([
        { word: "ch", matched: false },
        { word: "sh", matched: false },
        { word: "th", matched: false },
        { word: "pl", matched: false },
        { word: "st", matched: false },
        { word: "bl", matched: false },
      ]);
    }
  }, [customWords]);

  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);
      if (choiceOne.word === choiceTwo.word) {
        setCards((prevCards) => {
          return prevCards.map((card) => {
            if (card.word === choiceOne.word) {
              return { ...card, matched: true };
            } else {
              return card;
            }
          });
        });
        resetTurn();
      } else {
        setTimeout(() => resetTurn(), 1200);
      }
    }
  }, [choiceOne, choiceTwo]);

  //shuffle cards
  const shuffleCards = () => {
    const shuffledCards = [...cardText, ...cardText]
      .sort(() => Math.random() - 0.5)
      .map((card) => ({ ...card, id: Math.random() }));

    setChoiceOne(null);
    setChoiceTwo(null);
    setCards(shuffledCards);
    setTurns(0);
  };

  const handleChoice = (card: Card) => {
    if (!disabled) {
      choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
    }
  };

  const resetTurn = () => {
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurns((prevTurns) => prevTurns + 1);
    setDisabled(false);
  };

  // Start game automatically

  useEffect(() => {
    shuffleCards();
  }, [cardText]);

  return (
    <div
      className={
        theme === "Space"
          ? "memory-game__board memory-game__space-bg"
          : theme === "Ocean"
          ? "memory-game__board memory-game__ocean-bg"
          : theme === "Dino"
          ? "memory-game__board memory-game__dino-bg"
          : undefined
      }
    >
      {theme === "Space" && (
        <div>
          <div className="memory-game__space-stars"></div>
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/earth.png"
            className="memory-game__earth"
          ></img>
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/moon.png"
            className="memory-game__moon"
          ></img>
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/space-ship.png"
            className="memory-game__space-ship"
          ></img>

          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/satellite.png"
            className="memory-game__satellite"
          ></img>
        </div>
      )}

      {theme === "Ocean" && (
        <div>
          <div className="memory-game__wave-wrapper">
            <div className="memory-game__waves"></div>
            <div className="memory-game__waves"></div>
          </div>
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/dolphin-cartoon.png"
            className="memory-game__dolphin"
          ></img>
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/shark-cartoon.png"
            className="memory-game__shark"
          ></img>
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/fish-cartoon.png"
            className="memory-game__fish"
          ></img>
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/octopus-cartoon.png"
            className="memory-game__octopus"
          ></img>
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/marlin-cartoon.png"
            className="memory-game__marlin"
          ></img>
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/shrimp-cartoon.png"
            className="memory-game__shrimp"
          ></img>
        </div>
      )}

      {theme === "Dino" && (
        <div>
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/Rex.png"
            className="memory-game__rex"
          ></img>

          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/Terry.png"
            className="memory-game__terry"
          ></img>
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/Bronto.png"
            className="memory-game__bronto"
          ></img>
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/Cleo.png"
            className="memory-game__cleo"
          ></img>
        </div>
      )}
      <div onClick={() => setCustom(null)} className="memory-game__new-game">
        New Game
      </div>

      <div className="memory-game__row-wrapper">
        <div className="memory-game__theme" onClick={() => setTheme("Space")}>
          Space
        </div>
        <div className="memory-game__theme" onClick={() => setTheme("Ocean")}>
          Ocean
        </div>
        <div className="memory-game__theme" onClick={() => setTheme("Dino")}>
          Dino
        </div>
      </div>

      <div className="memory-game__card-grid">
        {cards.map((card) => (
          <div className="memory-game__card" key={card.id}>
            <div
              className={
                card === choiceOne || card === choiceTwo || card.matched
                  ? "memory-game__card--flipped"
                  : "memory-game__card--front"
              }
            >
              <div className="memory-game__card--word-text"> {card.word} </div>
            </div>
            <div
              className={
                card === choiceOne || card === choiceTwo || card.matched
                  ? "memory-game__card--front"
                  : "memory-game__card--flipped"
              }
            >
              <img
                src={
                  theme === "Space"
                    ? spaceTheme
                    : theme === "Ocean"
                    ? oceanTheme
                    : theme === "Dino"
                    ? dinoTheme
                    : undefined
                }
                alt="Card Back"
                className="memory-game__card--back"
                onClick={() => handleChoice(card)}
              ></img>
            </div>
          </div>
        ))}
      </div>
      <p>Turns: {turns}</p>
    </div>
  );
};
export default MemoryGrid;
