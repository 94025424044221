export const generateID = () => {
  let firstNum: string[] = [];
  let secondNum: string[] = [];
  let stringValue: string[] = [];

  for (let i = 0; i < 10; i++) {
    firstNum.push(generateRandomSingleInt());
    secondNum.push(generateRandomSingleInt());
    stringValue.push(generateRandomLetter(i));
  }

  return [...firstNum, ...stringValue, ...secondNum].join();
};

const generateRandomSingleInt = () => {
  return Math.floor(Math.random() * 10).toString();
};

const generateRandomLetter = (num: number) => {
  return num % 2 === 1
    ? letters[Math.floor(Math.random() * letters.length)].toUpperCase()
    : letters[Math.floor(Math.random() * letters.length)];
};

export const generateRandomNumberString = (num: number) => {
  return Math.random().toString().substr(2, num);
};

const letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
