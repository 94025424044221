import React, { useRef, useState } from "react";
import ReactDom from "react-dom";
import CSS from "csstype";
import { useUserData } from "../../Hooks/useUserData";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
import { useDisplayData } from "../../Hooks/useDisplayData";
import { useEffect } from "react";
import { GetBaseURL } from "../../Utils";
import { StudentData } from "../../Models";
import StoryPreview from "../Partials/StoryPreview";

// const MODAL_STYLES: CSS.Properties = {
//   margin: "auto",
//   position: "fixed",
//   top: "50%",
//   // left: "50%",
//   width: "50%",
//   // transform: "translate(-50%, -50%)",
//   backgroundColor: "#FFF",
//   padding: "50px",
//   zIndex: 1000,
// };

type StripeModalProps = {
  fromBanner?: boolean;
  fromLimit?: boolean;
};

const stripeTestPromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_STRIPE_KEY}`
    : `${process.env.REACT_APP_STRIPE_TEST_KEY}`
);

const StripeModal: React.FC<StripeModalProps> = ({ fromBanner, fromLimit }) => {
  const { userData, setUser } = useUserData();
  const { hideModal, displayData } = useDisplayData();
  const [section, setSection] = useState(1);
  const [slideInFromBack, setslideInFromBack] = useState(false);
  const [slideInFromFront, setslideInFromFront] = useState(false);
  const [planType, setPlanType] = useState<"monthly" | "annual" | null>(null);
  const [error, setError] = useState({
    type: "error",
    isActive: false,
    message: "",
  });
  const promoCodeRef = useRef<HTMLInputElement>(null);

  const previousSection = () => {
    if (!(slideInFromFront && slideInFromBack)) {
      setslideInFromBack(true);
      setSection(section - 1);
      setTimeout(() => {
        setslideInFromBack(false);
      }, 500);
    }
  };

  const nextSection = () => {
    if (!(slideInFromFront && slideInFromBack)) {
      setslideInFromFront(true);
      setSection(section + 1);
      setTimeout(() => {
        setslideInFromFront(false);
      }, 500);
    }
  };

  const createCustomerRequest = async () => {
    if (
      !userData.subscription_data.stripe_customer_id ||
      userData.subscription_data.stripe_customer_id === ""
    ) {
      const request = await fetch(
        `${GetBaseURL()}/api/payments/create-customer`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email:
              userData.account_type === "parent"
                ? userData.user_data.email
                : (userData.user_data as StudentData).linked_accounts[0]
                    .user_data.email,
            id:
              userData.account_type === "parent"
                ? userData._id
                : (userData.user_data as StudentData).linked_accounts[0]._id,
          }),
        }
      );
      const response = await request.json();
      if (request.status === 200) {
        setUser(response.user._id);
      } else {
        console.log(response.message);
      }
    }
  };

  useEffect(() => {
    createCustomerRequest();
  }, []);

  return (
    <>
      {section === 1 && (
        <div
          className={
            slideInFromBack
              ? "modal__payment-options--backslide"
              : "modal__payment-options"
          }
        >
          {error.isActive && (
            <div
              style={{
                marginTop: "220px",
                marginBottom: "-220px",
                color: "red",
              }}
            >
              {error.message}
            </div>
          )}
          <div className="modal__main-wrapper">
            {fromLimit && (
              <>
                <img
                  className="modal__modal-img"
                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/modal-img.png`}
                  alt={"No Image"}
                />
                <h2 className="modal__stripe-h2">
                  You've read your free book for today. Come back tomorrow to
                  read another.
                </h2>
                <span>
                  Or upgrade to premium to unlock unlimited books, worksheets
                  and games!
                </span>
                <div>
                  <div style={{ marginBottom: "15px" }}>
                    Have a promo code? Enter it here!
                  </div>
                  <input
                    type="text"
                    className="modal__input"
                    ref={promoCodeRef}
                    style={{ marginRight: "30px" }}
                  />
                  <input
                    className="modal__submit"
                    readOnly
                    value="Submit"
                    onClick={async () => {
                      const request = await fetch(
                        `${GetBaseURL()}/api/payments/useCode?key=${
                          process.env.REACT_APP_PERSONAL_AUTH_KEY
                        }`,
                        {
                          method: "POST",
                          body: JSON.stringify({
                            code: promoCodeRef.current?.value,
                            user_id:
                              userData.account_type === "parent"
                                ? userData._id
                                : (userData.user_data as StudentData)
                                    .linked_accounts[0]._id,
                          }),
                          headers: { "Content-Type": "application/json" },
                        }
                      );
                      const response = await request.json();
                      if (request.status === 200) {
                        setUser(userData._id);
                        hideModal();
                      } else {
                        setError({
                          type: "error",
                          isActive: true,
                          message: response.message,
                        });
                      }
                    }}
                  />
                </div>
              </>
            )}
            {fromBanner && (
              <>
                <img
                  className="modal__modal-img"
                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/modal-img.png`}
                  alt={"No Image"}
                />
                <h2 className="modal__stripe-h2">
                  Unlock Unlimited Books, Worksheets and Games.
                </h2>
                <div>
                  <div style={{ marginBottom: "15px" }}>
                    Have a promo code? Enter it here!
                  </div>
                  <input
                    type="text"
                    className="modal__input"
                    ref={promoCodeRef}
                    style={{ marginRight: "30px" }}
                  />
                  <input
                    className="modal__submit"
                    value="Submit"
                    readOnly
                    onClick={async () => {
                      const request = await fetch(
                        `${GetBaseURL()}/api/payments/useCode?key=${
                          process.env.REACT_APP_PERSONAL_AUTH_KEY
                        }`,
                        {
                          method: "POST",
                          body: JSON.stringify({
                            code: promoCodeRef.current?.value,
                            user_id:
                              userData.account_type === "parent"
                                ? userData._id
                                : (userData.user_data as StudentData)
                                    .linked_accounts[0]._id,
                          }),
                          headers: { "Content-Type": "application/json" },
                        }
                      );
                      const response = await request.json();
                      if (request.status === 200) {
                        setUser(userData._id);
                        hideModal();
                      } else {
                        setError({
                          type: "error",
                          isActive: true,
                          message: response.message,
                        });
                      }
                    }}
                  />
                </div>
              </>
            )}
            <div className="modal__premium-cards">
              <div
                className="modal__premium-card modal__premium-card--monthly"
                onClick={() => {
                  setPlanType("monthly");
                  nextSection();
                  fetch(
                    `${GetBaseURL()}/api/users/trackPlanType?key=${
                      process.env.REACT_APP_PERSONAL_AUTH_KEY
                    }`,
                    {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        content_name: "No Fuss Readers Monthly Subscription",
                        email: userData.user_data.email,
                      }),
                    }
                  );
                }}
              >
                <img
                  className="modal__ribbon-title"
                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/ribbon-title.webp`}
                  onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = `${process.env.REACT_APP_AWS_BUCKET_URL}/ribbon-title.svg`;
                  }}
                  alt={"No Image"}
                />
                <div className="modal__premium-card--heading">Monthly</div>
                <img
                  className="modal__ribbon-underline"
                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/ribbon-underline.webp`}
                  onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = `${process.env.REACT_APP_AWS_BUCKET_URL}/ribbon-underline.svg`;
                  }}
                  alt={"No Image"}
                />
                <div className="modal__premium-card--price-row">
                  <span className="modal__premium-card--price">$9.99</span>
                  /mo
                </div>

                <div className="modal__premium-card--subscription-benefit">
                  Billed Monthly
                </div>
              </div>
              <div
                className="modal__premium-card modal__premium-card--annual"
                onClick={() => {
                  setPlanType("annual");
                  nextSection();
                  fetch(
                    `${GetBaseURL()}/api/users/trackPlanType?key=${
                      process.env.REACT_APP_PERSONAL_AUTH_KEY
                    }`,
                    {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        content_name: "No Fuss Readers Annual Subscription",
                        email: userData.user_data.email,
                      }),
                    }
                  );
                }}
              >
                <img
                  className="modal__ribbon-flag"
                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/ribbon-flag.webp`}
                  onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = `${process.env.REACT_APP_AWS_BUCKET_URL}/ribbon-flag.svg`;
                  }}
                  alt={"No Image"}
                />
                {/* <div className="modal__premium-card--annual-banner">
                Most Popular!
              </div> */}
                <img
                  className="modal__ribbon-title"
                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/ribbon-title.webp`}
                  onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = `${process.env.REACT_APP_AWS_BUCKET_URL}/ribbon-title.svg`;
                  }}
                  alt={"No Image"}
                />
                <div className="modal__premium-card--heading">Annual</div>
                <img
                  className="modal__ribbon-underline"
                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/ribbon-underline.webp`}
                  onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = `${process.env.REACT_APP_AWS_BUCKET_URL}/ribbon-underline.svg`;
                  }}
                  alt={"No Image"}
                />
                <div className="modal__premium-card--price-row">
                  {" "}
                  {/* <span className="modal__premium-card--original-price">
                    $29.99
                  </span>{" "} */}
                  <span className="modal__premium-card--price">$6.99</span>
                  /mo
                </div>

                <div className="modal__premium-card--subscription-benefit">
                  Billed Annually
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {section === 2 && (
        <div
          className={
            slideInFromFront
              ? "modal__payment-options--frontslide"
              : "modal__payment-options"
          }
        >
          <div className="modal__stripe--selection-info">
            You've selected the {planType} plan.{" "}
          </div>

          <div className="modal__stripe--image-wrapper">
            <img
              alt="user image"
              src="https://study-portal.s3.us-east-2.amazonaws.com/monsterIcon.svg"
            ></img>
          </div>
          <Elements stripe={stripeTestPromise}>
            <PaymentForm planType={planType} />
          </Elements>
          <div className="modal__stripe--secure-payment">
            <span>Secure Checkout </span>
            <i
              className="fa fa-lock modal__stripe--lock-icon"
              aria-hidden="true"
            ></i>
            <img
              className="modal__stripe--powered-by"
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/PoweredByStripe.webp`}
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = `${process.env.REACT_APP_AWS_BUCKET_URL}/PoweredByStripe.png`;
              }}
              alt={"Powered By Stripe"}
            />
          </div>
          <div
            className="modal__stripe--go-back"
            onClick={() => previousSection()}
          >
            Go back
          </div>
        </div>
      )}
    </>
  );
};

export default StripeModal;
