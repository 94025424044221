import { useContext } from "react";
import { StoryContext, StoryDataType } from "../Context/StoryContext";
import { TopCategories } from "../Utils";

export const useStories = () => {
  const { storyData, setStoryData } = useContext(StoryContext);

  const SetCurrentStoryID = (id: string) => {
    setStoryData((prev: StoryDataType) => ({
      ...prev,
      currentStoryID: id,
    }));
  };

  const FetchAllCategories = () => {
    let categories: string[][] = [];
    storyData.stories.forEach((story) => {
      categories.push(story.tags);
    });
    categories.unshift(TopCategories);

    return Array.from(new Set(categories.flat()));
  };

  const UpdateStories = () => {
    setStoryData((prev: StoryDataType) => ({
      ...prev,
      update: true,
    }));
  };

  const SwitchFilter = (newFilter: "category" | "level") => {
    setStoryData((prev: StoryDataType) => ({
      ...prev,
      filter: newFilter,
    }));
  };

  return {
    storyData,
    FetchAllCategories,
    SetCurrentStoryID,
    UpdateStories,
    SwitchFilter,
  };
};
