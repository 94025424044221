import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDisplayData } from "../../Hooks/useDisplayData";
import { AllPhoneNumbers } from "../../Models";
import { ValidateEmail } from "../../Utils";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        {/* // ! left side of footer */}
        <section className="footer__left-side">
          <Link
            className="footer__nav-item"
            to="/terms-and-conditions"
            target="_blank"
          >
            Terms of Service
          </Link>
        </section>

        {/* //! middle footer section */}
        <section className="footer__nav-container">
          <Link
            className="footer__nav-item"
            to="/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </section>

        <section className="footer__nav-container">
          <div className="footer__copyright">
            Support:{" "}
            <a href="mailto: info@nofusstutors.com">info@nofusstutors.com</a>
          </div>
        </section>
        {/* //! right footer section */}
        <section className="footer__right-side">
          <div className="footer__copyright">Copyright © 2023</div>
          <div className="footer__copyright">No Fuss Readers</div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
