import React, { useLayoutEffect, useRef, useState } from "react";

import Dice from "../Components/Games/ReadAndRoll/Dice";

import WordsGrid from "../Components/Games/ReadAndRoll/WordsGrid";
import { generateID } from "../Utils";

type Card = { word: string; id: number };

const RollAndRead: React.FC = () => {
  const [custom, setCustom] = useState<boolean | null>(null);
  const [customWords, setCustomWords] = useState<
    { word: string; number: number }[]
  >([]);

  const [cards, setCards] = useState<Card[]>([]);

  return (
    <>
      <div className="roll-and-read">
        <div>
          <div className="roll-and-read__card-grid">
            <div className="roll-and-read__card-row">
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--img-holder">
                    <img
                      src="https://study-portal.s3.us-east-2.amazonaws.com/dice1.png"
                      alt="Card Back"
                      className="roll-and-read__card--img"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--img-holder">
                    <img
                      src="https://study-portal.s3.us-east-2.amazonaws.com/dice2.png"
                      alt="Card Back"
                      className="roll-and-read__card--img"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--img-holder">
                    <img
                      src="https://study-portal.s3.us-east-2.amazonaws.com/dice3.png"
                      alt="Card Back"
                      className="roll-and-read__card--img"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--img-holder">
                    <img
                      src="https://study-portal.s3.us-east-2.amazonaws.com/dice4.png"
                      alt="Card Back"
                      className="roll-and-read__card--img"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--img-holder">
                    <img
                      src="https://study-portal.s3.us-east-2.amazonaws.com/dice5.png"
                      alt="Card Back"
                      className="roll-and-read__card--img"
                    ></img>
                  </div>
                </div>
              </div>

              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--img-holder">
                    <img
                      src="https://study-portal.s3.us-east-2.amazonaws.com/dice6.png"
                      alt="Card Back"
                      className="roll-and-read__card--img"
                    ></img>
                  </div>
                </div>
              </div>
            </div>

            <div className="roll-and-read__card-row">
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>

              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
            </div>
            <div className="roll-and-read__card-row">
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>

              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
            </div>
            <div className="roll-and-read__card-row">
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>

              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
            </div>
            <div className="roll-and-read__card-row">
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>

              <div className="roll-and-read__card">
                <div>
                  <div className="roll-and-read__card--word-text">scary</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dice__dice-container">
          <Dice />
        </div>
      </div>
    </>
  );
};

export default RollAndRead;
